import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, TextField } from "@mui/material";
import Icon from "@mdi/react";
import { mdiApple, mdiGoogle, mdiIncognito } from "@mdi/js";
import { Components, Helpers, useRoutes } from "shared-components";
import { LoginScope, TextFieldEyes } from "Components";

import svgImage from "assets/image/svg/23847867675-42379827357598476.svg";

import { getAuth } from "ivipbase";
import { useUser } from "Hooks";

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

const Login: React.FC = () => {
    const navigate = useRoutes();
    const user = useUser();
    const [loading, setLoading] = useState(false);
    const [stage, setStage] = useState(0);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const validEmail = emailRegex.test(email);
    const validPassword = passwordRegex.test(password) && /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password);

    useEffect(() => {
        if (user) {
            navigate.to("/");
        }
    }, [user]);

    useEffect(() => {
        setPassword("");
    }, [stage]);

    const handleLogin = async () => {
        if (!validEmail || !validPassword) {
            setStage(0);
            setErrorMessage("Email ou senha inválido. Tente novamente.");
            return;
        }
        setLoading(true);

        getAuth()
            .signInWithEmailAndPassword(email, password)
            .then((e) => {
                navigate.to("/");
            })
            .catch((error) => {
                setStage(0);
                let message: string = "";
                switch (error.message) {
                    case "auth/invalid-email":
                        message = "Email inválido";
                        break;
                    case "auth/user-disabled":
                        message = "Conta desabilitada";
                        break;
                    case "auth/user-not-found":
                        message = "Conta não encontrada";
                        break;
                    case "auth/wrong-password":
                        message = "Senha incorreta";
                        break;
                    default:
                        message = "Erro inesperado. Tente novamente mais tarde.";
                        break;
                }
                setErrorMessage(message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const shortEmail = email
        .split("@")
        .map((s, i) => {
            if (i === 0) {
                return s.slice(0, 2) + "****";
            }
            return s;
        })
        .join("@");

    return (
        <LoginScope
            title={stage === 0 ? "Login" : stage === 1 ? "Insira a sua senha" : null}
            subtitle={stage === 1 ? shortEmail : null}
            image={
                <Components.SVGTheme
                    src={svgImage}
                    theme={{
                        primary: "var(--primary)",
                        secondary: "var(--secondary)",
                    }}
                />
            }
            footer={
                stage === 0 ? (
                    <Components.Link href="/register" color="inherit" disabled={loading} style={{ textDecoration: "none", margin: "0px auto", color: "var(--primary)" }}>
                        Criar uma Conta IVIPEX
                    </Components.Link>
                ) : stage === 1 ? (
                    <Components.Link
                        href="/forgot"
                        color="inherit"
                        style={{ textDecoration: "none", margin: "0px auto", color: "var(--primary)" }}
                        disabled={loading}
                        onClick={(e) => {
                            e.preventDefault();
                            Helpers.Toast.show("No momento não é possível redefinir senha. Por favor, tente novamente em breve.", "warning");
                        }}
                    >
                        Esqueceu a senha?
                    </Components.Link>
                ) : null
            }
        >
            {stage === 0 && (
                <>
                    <Box component="form" noValidate sx={{ width: "100%" }} autoComplete="email">
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            fullWidth
                            autoComplete="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                if (errorMessage !== null && validEmail) {
                                    setErrorMessage(null);
                                }
                            }}
                            error={(email.length > 0 && !validEmail) || errorMessage !== null}
                            disabled={loading}
                            helperText={errorMessage ? errorMessage : email.length > 0 && !validEmail && "Email inválido"}
                        />
                    </Box>
                    <br />
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        size="large"
                        loading={loading}
                        disabled={!validEmail}
                        onClick={() => setStage(1)}
                        sx={{
                            gap: "10px",
                        }}
                    >
                        Próximo
                    </LoadingButton>
                    <br />
                    <br />
                    <br />
                    <Divider style={{ width: "100%", fontSize: "12px" }}>OU</Divider>
                    <br />
                    <Button variant="outlined" fullWidth size="large" color="inherit" startIcon={<Icon path={mdiGoogle} size={1} />} sx={{ borderColor: "var(--mui-palette-divider)" }} disabled>
                        <p style={{ margin: "0px", flex: "1" }}>Conta Google</p>
                    </Button>
                    <br />
                    <br />
                    <Button variant="outlined" fullWidth size="large" color="inherit" startIcon={<Icon path={mdiApple} size={1} />} sx={{ borderColor: "var(--mui-palette-divider)" }} disabled>
                        <p style={{ margin: "0px", flex: "1" }}>Conta Apple</p>
                    </Button>
                    <br />
                    <br />
                    <Button variant="outlined" fullWidth size="large" color="inherit" startIcon={<Icon path={mdiIncognito} size={1} />} sx={{ borderColor: "var(--mui-palette-divider)" }} disabled>
                        <p style={{ margin: "0px", flex: "1" }}>Conta Anônima</p>
                    </Button>
                </>
            )}
            {stage === 1 && (
                <>
                    <Box component="form" noValidate sx={{ width: "100%" }} autoComplete="email password current-password">
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            fullWidth
                            autoComplete="email"
                            value={email}
                            sx={{
                                display: "none",
                            }}
                            disabled
                        />
                        <TextFieldEyes
                            label="Senha"
                            variant="outlined"
                            type="password"
                            fullWidth
                            autoComplete="current-password"
                            disabled={loading}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={password.length > 0 && !validPassword}
                            helperText={
                                password.length > 0 && !validPassword && "Senha inválida. Lembre-se de que deve conter pelo menos 8 caractéres, uma letra maiúscula, uma letra minúscula e um número."
                            }
                        />
                    </Box>
                    <br />
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        size="large"
                        loading={loading}
                        disabled={!validPassword}
                        onClick={() => {
                            handleLogin();
                        }}
                        sx={{
                            gap: "10px",
                        }}
                    >
                        Próximo
                    </LoadingButton>
                    <br />
                    <br />
                    <Button variant="outlined" fullWidth size="large" disabled={loading} onClick={() => setStage(0)}>
                        Cancelar
                    </Button>
                </>
            )}
        </LoginScope>
    );
};

export default Login;
