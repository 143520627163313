import React from "react";
import ReactDOM from "react-dom/client";
import Pages from "./Pages";
import * as __WEBPACK_EXTERNAL_MODULE_react__ from "react";
import { Scope } from "shared-components";
import { initializeApp } from "ivipbase";
import { useUser } from "Hooks";
import Login from "Pages/Login";
import "Styles/index.scss";
import { Dialog } from "Components";

import "shared-components/index.css";
import { createTheme, ThemeProvider } from "@mui/material";

const app = initializeApp({
    isServer: false,
    protocol: "https",
    host: "api.ivipcoin.com",
    port: 4044,
    dbname: process.env.NODE_ENV === "development" || window.location.hostname.split(".")[0] === "dev" ? "ivipex:dev" : "ivipex:prod",
});

const InitialApp: React.FC = () => {
    const user = useUser();
    return <Scope routes={Pages} isAuthenticated={user !== null} authenticationComponent={user ? null : <Login />} />;
};

const theme = createTheme({
    cssVariables: true,
});

app.ready(() => {
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
    root.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <InitialApp />
                <Dialog />
            </ThemeProvider>
        </React.StrictMode>
    );
});
