import { Socket as SocketClient, connect as connectSocket } from "socket.io-client";
import { getAuth } from "ivipbase";
import BasicEventEmitter from "basic-event-emitter";
import { HandleError, UUID } from "Utils";
import { APIUrl } from "./APIHelper";

const state = {
    CONNECTION_STATE_DISCONNECTED: "disconnected",
    CONNECTION_STATE_CONNECTING: "connecting",
    CONNECTION_STATE_CONNECTED: "connected",
    CONNECTION_STATE_DISCONNECTING: "disconnecting",
} as const;

type STATE = (typeof state)[keyof typeof state];

type AuthUser = ReturnType<typeof getAuth>["currentUser"];

const onAuthStateChanged = (callback: () => void) => {
    setTimeout(() => {
        try {
            getAuth().onAuthStateChanged(callback);
        } catch {
            onAuthStateChanged(callback);
        }
    }, 1000);
};

const getCurrentUser = (): Promise<AuthUser> => {
    return new Promise<AuthUser>((resolve, reject) => {
        setTimeout(() => {
            try {
                const user = getAuth().currentUser;
                resolve(user);
            } catch {
                getCurrentUser().then(resolve).catch(reject);
            }
        }, 1000);
    });
};

export class WebsocketHelper extends BasicEventEmitter<{
    connect: () => void;
    disconnect: () => void;
    reconnecting: () => void;
    reconnect: () => void;
    reconnect_failed: () => void;
    receive: (event: string, data: any) => void;
}> {
    private io: SocketClient | undefined;
    private _connectionState: STATE = state.CONNECTION_STATE_DISCONNECTED;
    private _callbacks: Map<Function, Function> = new Map();

    constructor(public baseURL: string) {
        super();

        onAuthStateChanged(() => {
            console.log("Auth state changed. Reconnecting websocket...");
            this.reconnect();
        });

        console.log("WebsocketHelper initialized.");
        this.reconnect();
    }

    get connectionState() {
        return this._connectionState;
    }

    set connectionState(value: STATE) {
        this._connectionState = value;
        this.prepared = value === state.CONNECTION_STATE_CONNECTED;
    }

    get isConnected() {
        return this.connectionState === state.CONNECTION_STATE_CONNECTED;
    }

    get isConnecting() {
        return this.connectionState === state.CONNECTION_STATE_CONNECTING;
    }

    async connect() {
        console.log("Connecting to websocket server...");
        await new Promise<void>(async (resolve, reject) => {
            const user = await getCurrentUser();

            if (!user || !user.accessToken || user.accessToken.trim() === "") {
                return resolve();
            }

            let resolved = false;
            if (this.connectionState === state.CONNECTION_STATE_DISCONNECTED) {
                this.connectionState = state.CONNECTION_STATE_CONNECTING;

                this.io = connectSocket(this.baseURL.replace(/^http(s?)/gi, "ws$1"), {
                    // Use default socket.io connection settings:
                    path: `/socket.io`,
                    autoConnect: true,
                    reconnectionDelay: 5000,
                    reconnectionDelayMax: 5000,
                    timeout: 20000,
                    randomizationFactor: 0.5,
                    transports: ["websocket"], // Override default setting of ['polling', 'websocket']
                    query: {
                        token: user.accessToken,
                    },
                    extraHeaders: {
                        Authorization: user ? `Bearer ${user.accessToken}` : "",
                    },
                });

                this.io.on("connect", () => {
                    this.connectionState = state.CONNECTION_STATE_CONNECTED;
                    this.emit("connect");

                    if (!resolved) {
                        resolved = true;
                        resolve();
                    }
                });

                this.io.on("disconnect", () => {
                    this.connectionState = state.CONNECTION_STATE_DISCONNECTING;
                    this.emit("disconnect");
                });

                this.io.on("connect_error", () => {
                    this.connectionState = state.CONNECTION_STATE_DISCONNECTED;
                    this.emit("disconnect");
                    setTimeout(() => {
                        this.reconnect();
                    }, 1000 * 10); // 10 seconds
                });

                this.io.on("reconnecting", () => {
                    this.connectionState = state.CONNECTION_STATE_CONNECTING;
                    this.emit("reconnecting");
                });

                this.io.on("reconnect", () => {
                    this.connectionState = state.CONNECTION_STATE_CONNECTED;
                    this.emit("reconnect");
                });

                this.io.on("reconnect_failed", () => {
                    this.connectionState = state.CONNECTION_STATE_DISCONNECTED;
                    this.emit("reconnect_failed");
                    setTimeout(() => {
                        this.reconnect();
                    }, 1000 * 10); // 10 seconds
                });

                this.io.on("receive", (receive: { event: string; data: any }) => {
                    this.emit("receive", receive.event, receive.data);
                });

                return;
            }

            resolve();
        });
    }

    async disconnect() {
        if (this.connectionState === state.CONNECTION_STATE_CONNECTED) {
            this.connectionState = state.CONNECTION_STATE_DISCONNECTED;
            this.io?.disconnect();
            this.io = undefined;
        }
    }

    async reconnect() {
        if (this.connectionState === state.CONNECTION_STATE_DISCONNECTED) {
            this.io?.disconnect();
            this.io = undefined;
            this.connect();
        }
    }

    onReceive<D = any>(event: string, callback: (data: D) => void) {
        const c = (e: any, data: any) => {
            if (e === event) {
                callback(data);
            }
        };
        this._callbacks.set(callback, c);
        return this.on("receive", c);
    }

    onceReceive<D = any>(event: string, callback: (data: D) => void) {
        const c = (e: any, data: any) => {
            if (e === event) {
                callback(data);
            }
        };
        this._callbacks.set(callback, c);
        return this.once("receive", c);
    }

    offReceive(event: string, callback: (data: any) => void) {
        const c = this._callbacks.get(callback);
        if (!c) {
            return;
        }
        this._callbacks.delete(callback);
        return this.off("receive", c as any);
    }

    offOnceReceive(event: string, callback: (data: any) => void) {
        const c = this._callbacks.get(callback);
        if (!c) {
            return;
        }
        this._callbacks.delete(callback);
        return this.offOnce("receive", c as any);
    }

    async request<R = any>(event: string, data: any = {}): Promise<R> {
        return await this.ready(
            async () =>
                await new Promise((resolve, reject) => {
                    const checkConnection = (callback: () => any) => {
                        if (!this.io?.connected) {
                            return reject(new HandleError("Connection is not established.", "CONNECTION_NOT_ESTABLISHED"));
                        }
                        callback();
                    };

                    const requestId = UUID();

                    const request = { event, request_id: requestId, data };

                    checkConnection(() => {
                        let timeout: NodeJS.Timeout;

                        const handle = (response: { status: "success" | "error"; data: any; request_id: string }) => {
                            if (response.request_id === requestId) {
                                clearTimeout(timeout);
                                this.io?.off("result", handle);
                                if (response.status === "error") {
                                    reject(new HandleError(response.data.message, response.data.name, response.data.cause));
                                } else {
                                    resolve(response.data);
                                }
                            }
                        };

                        const send = (retry = 0) => {
                            checkConnection(() => {
                                this.io?.emit("request", request);
                                timeout = setTimeout(() => {
                                    if (retry < 2) {
                                        return send(retry + 1);
                                    }
                                    this.io?.off("result", handle);
                                    const err = new HandleError(`Server did not respond to "${event}" request after ${retry + 1} tries`, "REQUEST_TIMEOUT");
                                    reject(err);
                                }, 5000);
                            });
                        };

                        this.io?.on("result", handle);
                        send();
                    });
                })
        );
    }

    async sendData<D = any>(event: string, data: D): Promise<void> {
        return this.ready(async () => {
            this.io?.emit("receive", { event, data });
        });
    }
}

export const MainWebsocket = new WebsocketHelper(APIUrl);
