import React, { useId, useLayoutEffect } from "react";
import styles from "./style.module.scss";
import { clsx } from "Utils";

export const OrderInput: React.FC<{
    name?: string;
    label?: string;
    value?: number;
    symbol?: string;
    onChange?: (value: number) => void;
    disabled?: boolean;
}> = ({ name, label, value, symbol, onChange, disabled }) => {
    const id = useId();
    const [inputValue, setInputValue] = React.useState(value ?? 0);

    useLayoutEffect(() => {
        setInputValue((p) => value ?? p);
    }, [value]);

    const handleControls = (type: number) => () => {
        if (disabled) return;
        setInputValue((p) => {
            const v = parseFloat(Math.max(0.000001, p + type * 0.000001).toFixed(6));
            onChange?.(v);
            return v;
        });
    };

    return (
        <div className={clsx(styles["order-input"], disabled && styles["disabled"])}>
            {label && (
                <div className={styles["textField-prefix"]}>
                    <label htmlFor={id}>{label}</label>
                </div>
            )}
            <input
                lang="en"
                id={id}
                name={name}
                placeholder=""
                type="number"
                step={0.000001}
                min={0.000001}
                className={styles["textField-input"]}
                spellCheck={false}
                autoComplete="off"
                value={inputValue}
                onChange={(e) => {
                    if (disabled) return;
                    try {
                        setInputValue(parseFloat(e.target.value));
                        onChange?.(parseFloat(e.target.value));
                    } catch (e) {}
                }}
                onKeyDown={(e) => {
                    if (disabled) return;
                    if (e.key === "ArrowUp") {
                        handleControls(1)();
                    } else if (e.key === "ArrowDown") {
                        handleControls(-1)();
                    }
                }}
                disabled={disabled}
            />
            {symbol && <div className={styles["textField-suffix"]}>{symbol.toUpperCase()}</div>}
            <div className={styles["textField-numeric-controls-panel"]}>
                <div className={styles["up"]} onClick={handleControls(1)}>
                    <svg fill="IconNormal" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="bn-svg">
                        <path d="M16.5 15.51v-2.25L12 8.49l-4.5 4.77v2.25h9z" fill="currentColor"></path>
                    </svg>
                </div>
                <div className={styles["down"]} onClick={handleControls(-1)}>
                    <svg fill="IconNormal" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="bn-svg">
                        <path d="M16.5 8.49v2.25L12 15.51l-4.5-4.77V8.49h9z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
        </div>
    );
};
