import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import Icon from "@mdi/react";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { Skeleton } from "@mui/material";
import { Portal } from "Components";
import { MainAPI } from "Helpers";
import { formatCurrency } from "Utils";

interface Props {
    loading?: boolean;
    balance?: {
        balance: {
            quantity: number;
            price: number;
            value: number;
        };
        tokens: {
            [token: string]: {
                quantity: number;
                price: number;
                value: number;
            };
        };
    };
}

const colors = ["#ff9800", "#f44336", "#2196f3", "#4caf50", "#9c27b0", "#ffeb3b", "#ff5722", "#795548", "#607d8b"];

const IndicesItem: React.FC<{
    color?: string;
    percentual?: number;
    show?: boolean;
    tokens: Array<{
        index: number;
        name: string;
        quantity: number;
        price: number;
        value: number;
    }>;
}> = ({ color, percentual, show = true, tokens }) => {
    const [showPop, setShowPop] = useState(false);

    return (
        <>
            <div onClick={() => setShowPop(!showPop)} style={{ "--color": color, width: typeof percentual === "number" ? `${Math.round(percentual * 100)}%` : undefined } as React.CSSProperties}>
                <Portal anchorOrigin="bottom" transformOrigin="top" show={showPop} onClosed={() => setShowPop(!showPop)} className={styles.portal_indicador}>
                    {tokens.map(({ index, name, quantity, value }, i) => {
                        return (
                            <div key={i}>
                                <div
                                    className={styles.icon}
                                    style={{ "--color": colors[index], backgroundImage: `url(${MainAPI.resolveUrl(`/assets/img/coins/${name.toLowerCase()}`)})` } as React.CSSProperties}
                                ></div>
                                <div className={styles.name}>{name.toUpperCase()}</div>
                                <div className={styles.quantity}>{formatCurrency(quantity, { show })}</div>
                                <div className={styles.value}>{formatCurrency(value, { symbol: "$", show })}</div>
                            </div>
                        );
                    })}
                </Portal>
            </div>
        </>
    );
};

export const Balance: React.FC<Props> = ({
    loading = false,
    balance: _balance = {
        balance: {
            quantity: 0,
            price: 0,
            value: 0,
        },
        tokens: {},
    },
}) => {
    const [show, setShow] = React.useState(false);
    const { balance, tokens } = _balance;

    const { primaryTokens, otherTokens } = ((tokens) => {
        const t = Object.entries(tokens).sort(([, a], [, b]) => b.value - a.value);
        return {
            primaryTokens: t.slice(0, 5),
            otherTokens: t.slice(5),
        };
    })(tokens);

    return (
        <div className={styles.main}>
            <div className={styles.title}>Saldo</div>
            {loading && (
                <Skeleton
                    variant="rounded"
                    width={"200px"}
                    height={"40px"}
                    style={{
                        backgroundColor: "var(--skeleton-background)",
                    }}
                />
            )}
            {!loading && (
                <div className={styles.value}>
                    <p>{formatCurrency(balance.value, { symbol: "$", show })}</p>
                    <div
                        className={styles.eye}
                        onClick={() => {
                            setShow(!show);
                        }}
                    >
                        <Icon path={show ? mdiEyeOff : mdiEye} size={1} />
                    </div>
                </div>
            )}
            {primaryTokens.length > 0 && (
                <div className={styles.indices}>
                    {primaryTokens.map(([name, { quantity, price, value }], i) => {
                        return (
                            <IndicesItem
                                key={i}
                                color={colors[i]}
                                percentual={primaryTokens.length > 1 && i > 0 ? value / balance.value : undefined}
                                tokens={[
                                    {
                                        index: i,
                                        name,
                                        quantity,
                                        price,
                                        value,
                                    },
                                ]}
                                show={show}
                            />
                        );
                    })}
                    {otherTokens.length > 0 && (
                        <IndicesItem
                            percentual={otherTokens.reduce((acc, [, { value }]) => acc + value, 0) / balance.value}
                            tokens={otherTokens.map(([name, { quantity, price, value }], i) => ({
                                index: 5 + i,
                                name,
                                quantity,
                                price,
                                value,
                            }))}
                            show={show}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
