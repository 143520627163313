import React from "react";
import styles from "./styles.module.scss";
import Icon from "@mdi/react";
import { mdiBitcoin, mdiClipboardTextClock } from "@mdi/js";
import { Skeleton, Typography } from "@mui/material";

interface Props {
    loading?: boolean;
}

export const Tokens: React.FC<Props> = ({ loading = false }) => {
    return (
        <div className={styles.main}>
            {loading &&
                new Array(3).fill(0).map((_, index) => (
                    <Skeleton
                        key={index}
                        variant="rounded"
                        width={"100%"}
                        height={60}
                        style={{
                            backgroundColor: "var(--skeleton-background)",
                        }}
                    />
                ))}
            {!loading && (
                <div className={styles.empty}>
                    <Icon path={mdiBitcoin} size={3} />
                    <Typography variant="h6">Não há tokens em destaque</Typography>
                    <Typography
                        variant="caption"
                        sx={{
                            opacity: 0.8,
                        }}
                    >
                        As informações dos tokens em destaque aparecem aqui
                    </Typography>
                </div>
            )}
        </div>
    );
};
