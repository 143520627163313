import { Toast, useLocalStorage } from "shared-components";
import { storage } from "..";
import { useEffect, useLayoutEffect, useState } from "react";
import { MainAPI } from "Helpers";
import { useAPI, useWebsocket } from "Hooks";
import { PairItemInfo, WebsocketTrade } from "Types";

import styles from "./index.module.scss";
import { CircularProgress, Typography } from "@mui/material";
import { Portal } from "Components";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { clsx, formatCurrency } from "Utils";

interface Summary {
    // preço atual
    price_quote: number;
    // variação em 24h
    variation: number;
    open: number;
    close: number;
    // volume máximo em 24h
    max: number;
    // volume mínimo em 24h
    min: number;
    // volume primário em 24h (compra)
    primary_volume: number;
    // volume secundário em 24h (venda)
    secondary_volume: number;
}

interface TopBarProps {
    onChange?: (value: PairItemInfo) => void;
}

export const TopBar: React.FC<TopBarProps> = ({ onChange }) => {
    const [loading, setLoading] = useState(true);
    const [symbol, setSymbol] = useLocalStorage(storage, "tradeSymbol");
    const [showOptions, setShowOptions] = useState(false);
    const [summary, setSummary] = useState<Summary>({
        price_quote: 0,
        variation: 0,
        open: 0,
        close: 0,
        max: 0,
        min: 0,
        primary_volume: 0,
        secondary_volume: 0,
    });

    const [pairs = {}, pairs_status, pairs_fetch, pairs_error] = useAPI<Record<string, PairItemInfo>, "get">(MainAPI, "get", "/inventory/pairs", {}, true, 1000 * 60 * 5);

    useWebsocket<WebsocketTrade>(
        "trade",
        (info) => {
            if (info.symbol !== symbol?.pair) {
                return;
            }

            setSummary((prev) => ({
                price_quote: info.price,
                variation: info.price - prev.open,
                close: info.price,
                open: prev.open,
                max: Math.max(info.price, prev.max),
                min: Math.min(info.price, prev.min),
                primary_volume: prev.primary_volume + info.amount,
                secondary_volume: prev.secondary_volume + info.price,
            }));
        },
        [symbol]
    );

    useLayoutEffect(() => {
        setLoading(true);
        let canceled: boolean = false;

        const time = setTimeout(() => {
            MainAPI.get<Summary>("/inventory/summary", { symbol: symbol.pair })
                .then((res) => {
                    if (canceled) return;
                    setSummary(res);
                    setLoading(false);
                })
                .catch((error) => {
                    Toast.error(error);
                });
        }, 1000);

        return () => {
            canceled = true;
            clearTimeout(time);
        };
    }, [symbol]);

    useEffect(() => {
        if (pairs_status === "error" && pairs_error) {
            Toast.error(pairs_error);
        }
    }, [pairs_status, pairs_error]);

    useLayoutEffect(() => {
        if (onChange) {
            onChange(symbol);
        }
        // pairs_fetch();
    }, [symbol]);

    const isLoading = pairs_status !== "success" || loading;

    return (
        <div className={styles.main}>
            {isLoading && (
                <CircularProgress
                    color="inherit"
                    size="30px"
                    sx={{
                        opacity: 0.5,
                        marginLeft: "30px",
                    }}
                />
            )}
            {!isLoading && (
                <>
                    <div
                        className={styles.select}
                        onClick={() => {
                            console.log("click");
                            setShowOptions(!showOptions);
                        }}
                    >
                        <div className={styles.icon} style={{ backgroundImage: `url(${MainAPI.resolveUrl(`/assets/img/coins/${symbol.base.toLowerCase()}`)})` }}></div>
                        <div className={styles.icon} style={{ backgroundImage: `url(${MainAPI.resolveUrl(`/assets/img/coins/${symbol.quote.toLowerCase()}`)})` }}></div>{" "}
                        <span>
                            {symbol.base}/{symbol.quote}
                        </span>{" "}
                        <Icon path={mdiChevronDown} />
                        <Portal className={styles["dialog-select"]} show={showOptions} anchorOrigin={"bottom-left"} transformOrigin={"top-left"} onClosed={() => setShowOptions(false)}>
                            {Object.entries(pairs ?? {}).map(([id, { base, quote, pair }], i) => (
                                <div
                                    key={i}
                                    className={clsx({ [styles["selected"]]: symbol.pair === pair })}
                                    onClick={
                                        symbol.pair !== pair
                                            ? () => {
                                                  setSymbol(pairs[id]);
                                                  setShowOptions(false);
                                              }
                                            : undefined
                                    }
                                >
                                    <div className={styles.icon} style={{ backgroundImage: `url(${MainAPI.resolveUrl(`/assets/img/coins/${base.toLowerCase()}`)})` }}></div>
                                    <div className={styles.icon} style={{ backgroundImage: `url(${MainAPI.resolveUrl(`/assets/img/coins/${quote.toLowerCase()}`)})` }}></div>
                                    <span>
                                        {base}/{quote}
                                    </span>
                                </div>
                            ))}
                        </Portal>
                    </div>
                    <div
                        className={clsx(styles["column"], {
                            [styles["positive"]]: summary.variation > 0,
                            [styles["negative"]]: summary.variation < 0,
                        })}
                    >
                        <Typography variant="h6">{formatCurrency(summary.price_quote)}</Typography>
                    </div>
                    <div className={styles["column"]}>
                        <Typography variant="body2" className={styles["title"]}>
                            Variação em 24h
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            className={clsx(styles["subtitle"], {
                                [styles["positive"]]: summary.variation > 0,
                                [styles["negative"]]: summary.variation < 0,
                            })}
                        >
                            {formatCurrency(summary.variation)} (
                            {formatCurrency((summary.variation / summary.open) * 100, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                            %)
                        </Typography>
                    </div>
                    <div className={styles["column"]}>
                        <Typography variant="body2" className={styles["title"]}>
                            Máxima em 24h
                        </Typography>
                        <Typography variant="subtitle2" className={styles["subtitle"]}>
                            {formatCurrency(summary.max)}
                        </Typography>
                    </div>
                    <div className={styles["column"]}>
                        <Typography variant="body2" className={styles["title"]}>
                            Mínima em 24h
                        </Typography>
                        <Typography variant="subtitle2" className={styles["subtitle"]}>
                            {formatCurrency(summary.min)}
                        </Typography>
                    </div>
                    <div className={styles["column"]}>
                        <Typography variant="body2" className={styles["title"]}>
                            Volume em 24h ({symbol.base})
                        </Typography>
                        <Typography variant="subtitle2" className={styles["subtitle"]}>
                            {formatCurrency(summary.primary_volume)}
                        </Typography>
                    </div>
                    <div className={styles["column"]}>
                        <Typography variant="body2" className={styles["title"]}>
                            Volume em 24h ({symbol.quote})
                        </Typography>
                        <Typography variant="subtitle2" className={styles["subtitle"]}>
                            {formatCurrency(summary.secondary_volume)}
                        </Typography>
                    </div>
                </>
            )}
        </div>
    );
};
