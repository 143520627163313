import { forwardWidgets, CandlestickChart, WidgetConfig } from "Components";
import { CandleData, CandlesHistoryItem, IntervalType, WebsocketTrade, WidgetsProps } from "Types";
import styles from "./index.module.scss";
import { useLayoutEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { MainAPI } from "Helpers";
import { useWebsocket } from "Hooks";
import { Toast } from "shared-components";

const dateToInterval = (date: Date, interval: IntervalType) => {
    date = new Date(date);
    switch (interval) {
        case "1S":
            date.setSeconds(0, 0);
            break;
        case "1D":
            date.setHours(0, 0, 0, 0);
            break;
        case "4H":
            date.setHours(date.getHours() - (date.getHours() % 4), 0, 0, 0);
            break;
        case "1H":
            date.setMinutes(0, 0, 0);
            break;
        case "15P":
            date.setMinutes(date.getMinutes() - (date.getMinutes() % 15), 0, 0);
            break;
        case "1s":
            date.setMilliseconds(0);
            break;
        default:
            date.setSeconds(0, 0);
    }
    return date;
};

export const ChartTrade = forwardWidgets<WidgetsProps>(
    "ChartTrade",
    ({ symbol }) => {
        WidgetConfig.useTitle("Gráfico");

        const [loading, setLoading] = useState(true);
        const [data, setData] = useState<CandleData[]>([]);
        const [interval, setInterval] = useState<IntervalType>("1D");

        useWebsocket<WebsocketTrade>(
            "trade",
            (info) => {
                if (info.symbol !== symbol?.pair) {
                    return;
                }

                info.time = dateToInterval(new Date(info.time), interval).toISOString();

                setData((data) => {
                    if (data.length <= 0) {
                        return [
                            {
                                date: new Date(info.time),
                                open: info.price,
                                high: info.price,
                                low: info.price,
                                close: info.price,
                                volume: info.amount,
                            },
                        ];
                    } else {
                        const index = data.length - 1;
                        const firstCandle = data[index];

                        if (new Date(info.time).getTime() === dateToInterval(firstCandle.date, interval).getTime()) {
                            firstCandle.close = info.price;
                            firstCandle.high = Math.max(firstCandle.high, info.price);
                            firstCandle.low = Math.min(firstCandle.low, info.price);
                            firstCandle.volume += info.amount;
                            data[index] = firstCandle;
                            return [...data];
                        } else {
                            return [
                                ...data,
                                {
                                    date: new Date(info.time),
                                    open: info.price,
                                    high: info.price,
                                    low: info.price,
                                    close: info.price,
                                    volume: info.amount,
                                },
                            ];
                        }
                    }
                });
            },
            [symbol, interval]
        );

        useLayoutEffect(() => {
            setLoading(true);
            let canceled: boolean = false;

            const time = setTimeout(() => {
                MainAPI.get<CandlesHistoryItem[]>("/inventory/candles_history", { interval, limit: 1000, symbol: symbol?.pair })
                    .then((res) => {
                        if (canceled) return;
                        setData(
                            res
                                .map((item) => ({
                                    date: new Date(item.time),
                                    open: item.open,
                                    high: item.high,
                                    low: item.low,
                                    close: item.close,
                                    volume: item.volume,
                                }))
                                .sort((a, b) => a.date.getTime() - b.date.getTime())
                        );
                        setLoading(false);
                    })
                    .catch((error) => {
                        Toast.error(error);
                    });
            }, 1000);

            return () => {
                canceled = true;
                clearTimeout(time);
            };
        }, [interval, symbol]);

        if (loading) {
            return (
                <div className={styles.main}>
                    <CircularProgress color="inherit" />
                </div>
            );
        }

        if (data.length <= 0) {
            return <div className={styles.main}></div>;
        }

        return (
            <CandlestickChart
                colors={{
                    text: "var(--text-color)",
                    grid: "var(--border-color)",
                }}
                data={[...data]}
            />
        );
    },
    { minW: 2 }
);
