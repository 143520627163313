import { getAuth } from "ivipbase";
import { JSONObjLiteral, Parameters } from "Types";

const prepareParams = (params?: Parameters) => {
    return params && Object.keys(params).length > 0
        ? new URLSearchParams(
              Object.fromEntries(
                  Object.entries(params).map(([k, v]) => {
                      return [k, v instanceof Date ? v.toISOString() : v ? v.toString() : ""];
                  })
              )
          ).toString()
        : undefined;
};

const statusMensaegens: Record<number, string> = {
    400: "Requisição inválida, verifique os dados enviados",
    401: "Requisição não autorizada, requer autenticação",
    403: "O servidor recusou a requisição",
    404: "O servidor não conseguiu encontrar o que foi solicitado",
    405: "O servidor não suporta o método HTTP utilizado",
    408: "Tempo limite da requisição excedido",
    409: "Conflito na requisição, possível duplicação ou inconsistência",
    410: "O recurso solicitado não está mais disponível",
    413: "O corpo da requisição é muito grande para ser processado",
    414: "O URI da requisição é muito longo",
    415: "O formato da mídia não é suportado pelo servidor",
    429: "Muitas requisições feitas em um curto período",
    500: "Erro interno no servidor",
    501: "Funcionalidade não implementada no servidor",
    502: "Bad Gateway, o servidor recebeu uma resposta inválida",
    503: "Serviço indisponível, o servidor está temporariamente sobrecarregado ou em manutenção",
    504: "Gateway Timeout, tempo limite excedido ao aguardar uma resposta",
    505: "Versão HTTP não suportada pelo servidor",
};

const tryFetch = async (url: string, options: RequestInit) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(url, options);
            const typeContent = response.headers.get("Content-Type");

            if (!response.ok) {
                let { error, message } = {} as { error?: string; message?: string };

                if (typeContent && typeContent.includes("application/json")) {
                    const res = (await response.json()) as { error?: string; message?: string };
                    error = res?.error;
                    message = res?.message;
                }

                message = typeof message === "string" ? message : error;

                message = typeof message === "string" ? message : response.status in statusMensaegens ? statusMensaegens[response.status] : "Ocorreu um erro interno ao tentar realizar a requisição";

                return reject(new Error(message));
            }

            if (typeContent && typeContent.includes("application/json")) {
                resolve(response.json());
            } else if (typeContent && typeContent.includes("text/plain")) {
                resolve(response.text());
            } else if (typeContent && typeContent.includes("application/octet-stream")) {
                resolve(response.blob());
            } else if (typeContent && (typeContent.includes("multipart/form-data") || typeContent.includes("application/form-data"))) {
                resolve(response.formData());
            } else {
                resolve(response.text());
            }
        } catch (e) {
            console.error(e);
            reject(new Error("Ocorreu um erro interno ao tentar realizar a requisição"));
        }
    });
};

export class APIHelper {
    constructor(protected url: string) {}

    resolveUrl(path: string) {
        return `${this.url}/${path.replace(/^\/+/g, "")}`;
    }

    async get<T = any>(path: string, params?: Parameters): Promise<T> {
        return new Promise(async (resolve, reject) => {
            try {
                path = path.replace(/^\/+/g, "");
                const query = prepareParams(params);
                const user = getAuth().currentUser;

                await tryFetch(`${this.url}/${path}${query ? `?${query}` : ""}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: user ? `Bearer ${user.accessToken}` : "",
                    },
                })
                    .then((response) => {
                        resolve(response as T);
                    })
                    .catch(reject);
            } catch (e) {
                reject(e);
            }
        });
    }

    async post<T = any>(path: string, body: JSONObjLiteral, params?: Parameters): Promise<T> {
        return new Promise(async (resolve, reject) => {
            try {
                path = path.replace(/^\/+/g, "");
                const query = prepareParams(params);
                const user = getAuth().currentUser;

                await tryFetch(`${this.url}/${path}${query ? `?${query}` : ""}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: user ? `Bearer ${user.accessToken}` : "",
                    },
                    body: JSON.stringify(body),
                })
                    .then((response) => {
                        resolve(response as T);
                    })
                    .catch(reject);
            } catch (e) {
                reject(e);
            }
        });
    }

    async put<T = any>(path: string, body: JSONObjLiteral, params?: Parameters): Promise<T> {
        return new Promise(async (resolve, reject) => {
            try {
                path = path.replace(/^\/+/g, "");
                const query = prepareParams(params);
                const user = getAuth().currentUser;

                await tryFetch(`${this.url}/${path}${query ? `?${query}` : ""}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: user ? `Bearer ${user.accessToken}` : "",
                    },
                    body: JSON.stringify(body),
                })
                    .then((response) => {
                        resolve(response as T);
                    })
                    .catch(reject);
            } catch (e) {
                reject(e);
            }
        });
    }

    async delete<T = any>(path: string, params?: Parameters): Promise<T> {
        return new Promise(async (resolve, reject) => {
            try {
                path = path.replace(/^\/+/g, "");
                const query = prepareParams(params);
                const user = getAuth().currentUser;

                await tryFetch(`${this.url}/${path}${query ? `?${query}` : ""}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: user ? `Bearer ${user.accessToken}` : "",
                    },
                })
                    .then((response) => {
                        resolve(response as T);
                    })
                    .catch(reject);
            } catch (e) {
                reject(e);
            }
        });
    }
}

const development = process.env.NODE_ENV === "development" || window.location.hostname.split(".")[0] === "dev";

const local_api = ["true"].includes(
    String(process.env.REACT_APP_LOCAL_API || "false")
        .trim()
        .toLowerCase()
);

export const APIUrl = development && local_api ? "http://localhost:8080" : "https://api.ivipcoin.com:8080";

export const MainAPI = new APIHelper(APIUrl);
