import { DialogShowListener, DialogOptions, DialogCloseListener } from "Components";
import React from "react";
import { InternalStorage } from "Utils";

export const open = (body: React.ReactNode | (() => JSX.Element), options: DialogOptions = {}) => {
    return new Promise<{
        index: number;
        close: () => void;
        remove: () => void;
    }>((resolve) => {
        InternalStorage.emit<DialogShowListener>("show-dialog", body, options, (index) => {
            resolve({
                index,
                close() {
                    close(index);
                },
                remove() {
                    close(index);
                },
            });
        });
    });
};

export const close = (index?: number) => {
    return new Promise<void>((resolve) => {
        InternalStorage.emit<DialogCloseListener>("close-dialog", index, () => {
            resolve();
        });
    });
};
