export * from "./Global";
export * as InternalStorage from "./InternalStorage";
export * as mdi from "./mdi";

export class HandleError extends Error {
    constructor(readonly message: string, readonly name: string = "DEFAULT", readonly cause?: any) {
        super(message);
    }
}

export const formatCurrency = (
    value: number,
    options: Partial<{
        symbol: string;
        show: boolean;
        position: "rt" | "lt";
        minimumFractionDigits: number;
        maximumFractionDigits: number;
        idealFractionDigits: number;
        shorten: boolean;
        type: "T" | "B" | "M" | "K";
    }> = {}
): string => {
    let { symbol = "", show = true, position = "lt", minimumFractionDigits = 2, maximumFractionDigits = 8, idealFractionDigits = 2, shorten = false, type } = options;

    maximumFractionDigits = typeof maximumFractionDigits === "number" && isFinite(maximumFractionDigits) ? maximumFractionDigits : minimumFractionDigits + 2;

    value = isFinite(value) ? value : 0;

    if (shorten) {
        const short = (value: number) => {
            if (value >= 1e12) return { value: value / 1e12, type: "T" };
            if (value >= 1e9) return { value: value / 1e9, type: "B" };
            if (value >= 1e6) return { value: value / 1e6, type: "M" };
            if (value >= 1e3) return { value: value / 1e3, type: "K" };
            return { value, type: undefined };
        };

        const { value: shortValue, type } = short(value) as any;

        return formatCurrency(shortValue, { ...options, type, shorten: false });
    }

    let [integer = "0", decimal = "00"] = value.toString().split(".");

    const countLeadingZeros = (str: string) => {
        const match = str.match(/^0+/);
        return match ? match[0].length : 0;
    };

    const countFractionDigits = (str: string) => {
        const zeros = countLeadingZeros(str);
        return zeros > 0 ? str.length - zeros : 0;
    };

    minimumFractionDigits = Math.max(minimumFractionDigits, decimal.length);
    maximumFractionDigits = Math.min(maximumFractionDigits, decimal.length);

    const idealDecimals = parseInt(integer) === 0 && parseInt(decimal) !== 0 ? Math.max(2, countLeadingZeros(decimal) + idealFractionDigits) : 2;

    const language: string = (window.navigator as any).userLanguage || window.navigator.language;

    decimal = decimal.slice(0, maximumFractionDigits);
    decimal = decimal.slice(0, minimumFractionDigits) + decimal.slice(minimumFractionDigits, maximumFractionDigits).replace(/0+$/, "");
    decimal = decimal.length > 0 ? decimal : "0";

    let format = new Intl.NumberFormat(language, { minimumFractionDigits: idealDecimals }).format(show ? parseFloat(integer + "." + decimal.slice(0, maximumFractionDigits)) : 111.11);

    format = show ? format : format.replace(/\d/gi, "*");

    return [position === "lt" ? symbol : null, format, type, position === "rt" ? symbol : null]
        .filter((s) => !!s)
        .join(" ")
        .trim();
};
