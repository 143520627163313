import { cloneLiteral, expandToFourValues, joinObject, UUID } from "Utils";
import { CandleItem, Candles } from "./Candle";
import { ChartsController } from "./Controller";
import { AxisIndicator, ChartOption, ChartRender, ChartRenderTypes, ChartsNames, ClientRect, IndicadorRender, SimpleOptions } from "./Types";
import { DeepPartial } from "Types";

const defaultConfigs: {
    [c in ChartsNames]: ChartOption<c>;
} = {
    main: {
        name: "main",
        title: "Candlesticks",
        width: 0,
        height: 0,
        hidden: true,
        candle: {
            body: {
                width: 20,
            },
            shadow: {
                width: 0,
            },
            space: 10,
        },
        posY: 0,
        padding: [15, 0],
        indicators: [
            {
                type: "MA",
                hidden: false,
                config: {
                    "1": { type: "close", color: "orange", length: 3, stroke: 1, hidden: true },
                    "2": { type: "close", color: "purple", length: 7, stroke: 1, hidden: true },
                    "3": { type: "close", color: "cyan", length: 30, stroke: 1, hidden: true },
                    "4": { type: "close", color: "pink", length: 50, stroke: 1, hidden: true },
                    "5": { type: "close", color: "blue", length: 100, stroke: 1, hidden: true },
                    "6": { type: "close", color: "red", length: 200, stroke: 1, hidden: true },
                    "7": { type: "close", color: "green", length: 400, stroke: 1, hidden: true },
                    "8": { type: "close", color: "yellow", length: 800, stroke: 1, hidden: true },
                    "9": { type: "close", color: "brown", length: 1600, stroke: 1, hidden: true },
                    "10": { type: "close", color: "black", length: 3200, stroke: 1, hidden: true },
                },
            },
        ],
    },
    VOL: {
        name: "VOL",
        title: "Volume",
        width: 0,
        height: 0,
        hidden: true,
        posY: 0,
        padding: [15, 0, 0, 0],
        config: {
            long: "solid",
            short: "solid",
            MAVOL1: {
                hidden: true,
                color: "blue",
                length: 7,
                stroke: 1,
            },
            MAVOL2: {
                hidden: true,
                color: "red",
                length: 14,
                stroke: 1,
            },
        },
    },
    MACD: {
        name: "MACD",
        title: "Média Móvel Convergente e Divergente",
        width: 0,
        height: 0,
        hidden: true,
        posY: 0,
        padding: [15, 0],
        config: {
            fastLength: 12,
            slowLength: 26,
            signalLength: 9,
            DEA: {
                hidden: false,
                color: "purple",
                stroke: 1,
            },
            DIF: {
                hidden: false,
                color: "cyan",
                stroke: 1,
            },
            MACD: {
                hidden: false,
                longGrow: { type: "hollow", color: "green" },
                shortGrow: { type: "hollow", color: "red" },
                longFall: { type: "solid", color: "green" },
                shortFall: { type: "solid", color: "red" },
            },
        },
    },
};

export abstract class Controller<C extends ChartsNames> {
    public candles: Candles = new Candles();
    public candlesInView: CandleItem[] = [];
    public id: string = UUID();
    public index: number = Infinity;
    public divider: number = 0;
    public scrollY: number = 0;
    public zoomY: number = 1;
    readonly config: ChartOption<C>;
    public data: ChartRender<ChartRenderTypes, C>;
    public indicators: IndicadorRender[] = [];
    public maxValue: number = 0;
    public minValue: number = 0;

    constructor(readonly controller: ChartsController, readonly type: ChartsNames, config: DeepPartial<ChartOption<C>>, private getIndex: () => number) {
        this.config = joinObject<ChartOption<C>>(cloneLiteral(defaultConfigs[type] as any), config);
        this.data = this.initialize();
    }

    get options(): SimpleOptions {
        return this.controller.config;
    }

    abstract initialize(): ChartRender<ChartRenderTypes, C>;

    loadCandle(candle: CandleItem, index: number, self: CandleItem[]) {
        const { height: h } = this.clientRect;
        const [paddingTop, paddingRight, paddingBottom, paddingLeft] = this.padding;
        const height = h - paddingTop - paddingBottom;
        const min = this.minValue;
        const dif = this.maxValue - min;

        const { date, open, close, high, low, volume, color, client } = candle;

        switch (this.data.type) {
            case "candle": {
                this.data.data.push({ index, date, open, close, high, low, volume });
                this.data.candles.push({
                    color,
                    shadow: client.shadow,
                    body: client.body,
                });
                break;
            }
            case "bar": {
                this.data.data.push({ index, date, value: volume });
                const bottom = height + paddingTop;
                const top = bottom - Math.round(((volume - min) / dif) * height);
                const { long = "solid", short = "solid" } = (this.config as any).config ?? {};
                const type = color === "green" ? long : short;

                this.data.bars.push({
                    top,
                    left: client.body.left,
                    right: client.body.right,
                    bottom,
                    center: client.body.center,
                    height: bottom - top,
                    width: client.body.width,
                    color,
                    type,
                });
                break;
            }
            case "line": {
                break;
            }
        }

        this.indicators.forEach((render) => {
            render.callback(candle, index, self);
        });
    }

    show() {
        if (!this.config.hidden) {
            return;
        }
        this.index = this.getIndex();
        this.config.hidden = false;
        this.divider = 0.3;
    }

    hide() {
        this.index = Infinity;
        this.config.hidden = true;
        this.divider = 0;
    }

    get yAxis() {
        const { height: h } = this.clientRect;
        const [paddingTop, paddingRight, paddingBottom, paddingLeft] = this.padding;
        const height = h - paddingTop - paddingBottom;
        const space = this.options.axis.y.minSpace;
        const topLength = Math.round(paddingTop / space);
        const bottomLength = Math.round(paddingBottom / space);
        const length = Math.round(height / space);

        const view = this.maxValue - this.minValue;
        const diff = view / length;

        return new Array(length + topLength + bottomLength)
            .fill(null)
            .map((_, i) => {
                const value = this.minValue + diff * (i - bottomLength + 1);
                const y = height - ((value - this.minValue) / view) * height;
                return { y: y + paddingTop, value };
            })
            .filter(({ y }) => y >= space * 0.3);
    }

    get padding() {
        return expandToFourValues(this.config.padding ?? 0);
    }

    get clientRect() {
        const { width, height, posY } = this.config;
        return {
            width,
            height,
            x: 0,
            y: posY,
            left: 0,
            top: posY,
            right: width,
            bottom: posY + height,
        };
    }

    get isHovered() {
        const { mouse } = this.options;
        const rect = this.clientRect;
        return mouse.move.normalized.x > rect.left && mouse.move.normalized.x < rect.right && mouse.move.normalized.y > rect.top && mouse.move.normalized.y < rect.bottom;
    }

    get axisIndicator(): AxisIndicator | undefined {
        return undefined;
    }

    get yAxisCurrentValue(): number {
        const { mouse } = this.options;
        const [paddingTop, paddingRight, paddingBottom, paddingLeft] = this.padding;
        const rect = this.clientRect;
        const height = rect.height - paddingTop - paddingBottom;
        const currentY = mouse.move.normalized.y - rect.top - paddingTop;
        return this.minValue + ((height - currentY) / height) * (this.maxValue - this.minValue);
    }

    normalizeX(x: number) {
        const { width: w } = this.clientRect;
        const [paddingTop, paddingRight, paddingBottom, paddingLeft] = this.padding;
        const width = w - paddingLeft - paddingRight;
        return (width * x) / w + paddingLeft;
    }

    normalizeY(y: number) {
        const { height: h } = this.clientRect;
        const [paddingTop, paddingRight, paddingBottom, paddingLeft] = this.padding;
        const height = h - paddingTop - paddingBottom;
        return (height * y) / h + paddingTop;
    }

    getValuePosition(value: number) {
        const { height: h } = this.clientRect;
        const [paddingTop, paddingRight, paddingBottom, paddingLeft] = this.padding;
        const height = h - paddingTop - paddingBottom;
        return height - ((value - this.minValue) / (this.maxValue - this.minValue)) * height + paddingTop;
    }
}

export class ChartMain extends Controller<"main"> {
    constructor(readonly controller: ChartsController, config: DeepPartial<ChartOption<"main">>, getIndex: () => number) {
        super(controller, "main", config, getIndex);
    }

    initialize(): ChartRender<"candle", "main"> {
        this.maxValue = this.candlesInView.map((candle) => candle.high).reduce((a, b) => Math.max(a, b), -Infinity);
        this.minValue = this.candlesInView.map((candle) => candle.low).reduce((a, b) => Math.min(a, b), Infinity);

        this.data = {
            type: "candle",
            typeCandles: "normal",
            name: "main",
            data: [],
            candles: [],
        };

        this.indicators = [];

        this.config.indicators.forEach((indicator) => {
            switch (indicator.type) {
                case "MA": {
                    const { config } = indicator;
                    (Object.keys(config) as any[]).map((key: keyof typeof config) => {
                        const { length, color, type, stroke, hidden } = config[key];
                        if (hidden) return;

                        const names = { close: "close", max: "high", min: "low", open: "open" } as const;
                        const self = this;
                        const currentIndex = this.indicators.length;

                        this.indicators.push({
                            type: "simple",
                            color,
                            stroke,
                            data: [],
                            callback(candle: CandleItem, index: number, list: CandleItem[]) {
                                if (index < length) {
                                    return;
                                }
                                const value = list.slice(index - length, index).reduce((acc, candle) => acc + candle[names[type]], 0) / length;
                                self.indicators[currentIndex].data.push({
                                    x: candle.client.body.center,
                                    y: self.getValuePosition(value),
                                    date: candle.date,
                                    value,
                                });
                            },
                        });
                    });
                }
            }
        });

        return this.data;
    }

    get axisIndicator() {
        if (this.candlesInView.length < 1) return undefined;

        const { axis } = this.options;
        const { right, height } = this.clientRect;
        const { close, color, client } = this.candlesInView[this.candlesInView.length - 1];
        const posY = color === "green" ? client.body.top : client.body.bottom;

        return {
            y: {
                top: posY,
                bottom: posY,
                left: right,
                right: right + axis.y.width,
                value: close,
                color,
                line: true,
            },
        };
    }
}

export class ChartVOL extends Controller<"VOL"> {
    constructor(readonly controller: ChartsController, config: DeepPartial<ChartOption<"VOL">>, getIndex: () => number) {
        super(controller, "VOL", config, getIndex);
    }

    initialize(): ChartRender<"bar", "VOL"> {
        this.maxValue = this.candlesInView.map((candle) => candle.volume).reduce((a, b) => Math.max(a, b), -Infinity);
        this.minValue = this.candlesInView.map((candle) => candle.volume).reduce((a, b) => Math.min(a, b * 0.9), Infinity);

        this.data = {
            type: "bar",
            name: "VOL",
            data: [],
            bars: [],
        };

        this.indicators = [];

        const getMAData = (config: { length: number; color: string; stroke?: number; hidden: boolean }) => {
            const { length, color, stroke = 2, hidden } = config;
            if (hidden) return;
            const self = this,
                currentIndex = this.indicators.length;
            this.indicators.push({
                type: "simple",
                color,
                stroke,
                data: [],
                callback({ client, date }, index, list) {
                    if (index < length) {
                        return;
                    }
                    const value = list.slice(index - length, index).reduce((acc, { volume }) => acc + volume, 0) / length;

                    self.indicators[currentIndex].data.push({
                        x: client.body.center,
                        y: self.getValuePosition(value),
                        date,
                        value,
                    });
                },
            });
        };

        getMAData(this.config.config.MAVOL1);
        getMAData(this.config.config.MAVOL2);

        return this.data;
    }
}

export class ChartMACD extends Controller<"MACD"> {
    constructor(readonly controller: ChartsController, config: DeepPartial<ChartOption<"MACD">>, getIndex: () => number) {
        super(controller, "MACD", config, getIndex);
    }

    initialize(): ChartRender<"bar", "MACD"> {
        return {} as any;
    }
}
