import { Backdrop, Button, ButtonGroup, CircularProgress } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useEffect, createContext, useState, useRef } from "react";
import { clsx, InternalStorage, UUID } from "Utils";
import styles from "./styles.module.scss";
import { useSizeEffect } from "shared-components";

export interface DialogOptions {
    title?: string;
    message?: string;
    confirm?: {
        label: string;
        action: (loading: (loading: boolean) => void) => void;
    };
    cancel?: {
        label: string;
        action: (loading: (loading: boolean) => void) => void;
    };
    onClose?: () => void;
}

export type DialogShowListener = (body: React.ReactNode | (() => JSX.Element), options: DialogOptions, callback: (index: number) => void) => void;
export type DialogCloseListener = (index: number | undefined, callback: () => void) => void;

interface DialogBodyRef {
    current: HTMLDivElement | null;
    isInside: (element: HTMLElement) => boolean;
}

export const DialogContext = createContext<{
    setLoading: (loading: boolean) => void;
    setTitle: (title: string) => void;
    close: (all?: boolean) => void;
    setExit: (exit: boolean) => void;
}>({
    setLoading: () => {},
    setTitle: () => {},
    close: () => {},
    setExit: () => {},
});

const DialogBody = forwardRef<DialogBodyRef, { children: React.ReactNode | (() => JSX.Element); options: DialogOptions; index: number; currentIndex: number; onCancel: () => void; loading?: boolean }>(
    ({ children, options, index, currentIndex, onCancel, loading: _loading = false }, ref) => {
        const [loading, setLoading] = useState<boolean>(false);
        const mainRef = useRef<HTMLDivElement>(null);

        useImperativeHandle(
            ref,
            () => ({
                current: mainRef.current,
                isInside(element) {
                    return mainRef.current?.contains(element) ?? false;
                },
            }),
            []
        );

        useEffect(() => {
            const time = setTimeout(() => {
                if (mainRef.current) {
                    mainRef.current.classList.add(styles["dialog-body-show"]);
                }
            }, 300);

            return () => {
                clearTimeout(time);
            };
        }, [ref]);

        const isLoading = loading || _loading;

        return (
            <div
                ref={mainRef}
                className={clsx(styles["dialog-body"])}
                style={{
                    pointerEvents: index === currentIndex ? "auto" : "none",
                }}
            >
                {options.title && <div className={clsx(styles["title"])}>{options.title}</div>}
                <div className={clsx(styles["body"], isLoading ? styles["loading"] : null)}>{typeof children === "function" ? children() : children}</div>
                {isLoading && (
                    <div className={clsx(styles["circular-loading"])}>
                        <CircularProgress color="inherit" />
                    </div>
                )}
                {(options.confirm || options.cancel) && (
                    <div className={clsx(styles["footer"])}>
                        <ButtonGroup>
                            {options.confirm && (
                                <Button
                                    variant="contained"
                                    disabled={isLoading}
                                    onClick={() => {
                                        options.confirm?.action((loading) => {
                                            setLoading(loading);
                                        });
                                    }}
                                >
                                    {options.confirm.label}
                                </Button>
                            )}
                            {options.cancel && (
                                <Button
                                    variant="outlined"
                                    disabled={isLoading}
                                    onClick={() => {
                                        const time = setTimeout(() => {
                                            onCancel();
                                        }, 300);
                                        options.cancel?.action((loading) => {
                                            clearTimeout(time);
                                            setLoading(loading);
                                            if (!loading) {
                                                onCancel();
                                            }
                                        });
                                    }}
                                >
                                    {options.cancel.label}
                                </Button>
                            )}
                        </ButtonGroup>
                    </div>
                )}
            </div>
        );
    }
);

export const Dialog: React.FC = () => {
    const [_, forceUpdate] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [exit, setExit] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    const [currentDialog, setCurrentDialog] = useState<number>(-1);
    const backdropRef = useRef<HTMLDivElement>(null);
    const dialogsRef = useRef<Array<{ body: React.ReactNode | (() => JSX.Element); options: DialogOptions; id: string }>>([]);
    const bodysRef = useRef<Array<DialogBodyRef | null>>([]);

    useEffect(() => {
        const showEvent = InternalStorage.addListener<DialogShowListener>("show-dialog", (body, options, callback) => {
            const index = dialogsRef.current.length;
            dialogsRef.current.push({ body, options, id: UUID() });
            setCurrentDialog(index);
            callback(index);
        });

        const closeEvent = InternalStorage.addListener<DialogCloseListener>("close-dialog", (index, callback) => {
            if (index === undefined) {
                setCurrentDialog(-1);
            } else if (typeof index === "number" && index >= 0 && index < dialogsRef.current.length) {
                setCurrentDialog(index - 1);
            }

            callback();
        });

        return () => {
            showEvent.stop();
            closeEvent.stop();
        };
    }, []);

    const updatePositions = () => {
        bodysRef.current.map((element, index) => {
            if (element?.current) {
                if (index > currentDialog) {
                    element.current.classList.remove(styles["dialog-body-show"]);
                    element.current.classList.add(styles["dialog-body-hide"]);
                } else if (currentDialog >= 0) {
                    const percent = (currentDialog - index) * 0.4;

                    element.current.style.setProperty("--scale", (1 - percent).toFixed(2));
                    element.current.style.setProperty("--percent", (percent * 100).toFixed(2) + "%");
                    element.current.style.setProperty("--opacity", Math.min(1, 1 - percent).toFixed(2));

                    // element.current.style.right = `calc(${index * 10}% + 15px)`;

                    if (loading && index < currentDialog) {
                        setLoading(false);
                    }
                }
            }
        });
    };

    useEffect(() => {
        if (currentDialog >= 0) {
            setOpen(true);
            if (currentDialog < dialogsRef.current.length - 1) {
                setTimeout(() => {
                    if (typeof dialogsRef.current[currentDialog + 1]?.options?.onClose === "function") {
                        dialogsRef.current[currentDialog + 1]?.options?.onClose?.();
                    }

                    dialogsRef.current.splice(currentDialog + 1);
                    bodysRef.current.splice(currentDialog + 1);
                }, 300);
            }
        } else {
            setTimeout(() => {
                setOpen(false);
                if (typeof dialogsRef.current[0]?.options?.onClose === "function") {
                    dialogsRef.current[0]?.options?.onClose?.();
                }
                dialogsRef.current = [];
                bodysRef.current = [];
            }, 300);
        }
        setExit(true);
        updatePositions();
    }, [currentDialog]);

    const handleClose: React.MouseEventHandler<HTMLElement> = (e) => {
        const inBackdrop = backdropRef.current?.contains(e.target as HTMLElement) ?? false;
        const isInside = bodysRef.current.some((ref) => ref?.isInside(e.target as HTMLElement) ?? false);
        if (!inBackdrop || isInside || !exit) {
            return;
        }

        if (typeof dialogsRef.current[currentDialog]?.options?.onClose === "function") {
            dialogsRef.current[currentDialog]?.options?.onClose?.();
            setCurrentDialog(currentDialog - 1);
        }
    };

    return (
        <DialogContext.Provider
            value={{
                setLoading(loading) {
                    setLoading(loading);
                },
                setTitle(title) {
                    if (dialogsRef.current[currentDialog]?.options) {
                        dialogsRef.current[currentDialog].options.title = title;
                        forceUpdate(Date.now());
                    }
                },
                close(all = false) {
                    setCurrentDialog(all ? -1 : currentDialog - 1);
                },
                setExit(exit) {
                    setExit(exit);
                },
            }}
        >
            <Backdrop ref={backdropRef} sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={open} onClick={handleClose}>
                {open &&
                    dialogsRef.current.map(({ body, options, id }, i) => {
                        return (
                            <DialogBody
                                key={id}
                                ref={(ref) => {
                                    bodysRef.current[i] = ref;
                                }}
                                options={options}
                                index={i}
                                currentIndex={currentDialog}
                                onCancel={() => {
                                    setCurrentDialog(i - 1);
                                }}
                                loading={loading}
                            >
                                {body}
                            </DialogBody>
                        );
                    })}
            </Backdrop>
        </DialogContext.Provider>
    );
};
