import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { createLocalStorage, useLocalStorage, DevelopmentTrace, MediaQuery } from "shared-components";
import { LayoutOptions, GridLayout } from "Components";

import * as widgets from "Widgets";
import { TopBar } from "./TopBar";
import { PairItemInfo } from "Types";

export const storage = createLocalStorage<{
    layout: LayoutOptions<keyof typeof widgets>[];
    tradeSymbol: PairItemInfo;
}>({
    layout: [
        { i: "ChartTrade", r: 0, w: 7, h: 5 },
        { i: "OrderBook", r: 0, w: 2, h: 5 },
        { i: "OrderOperation", r: 0, w: 3, h: 5 },
    ],
    tradeSymbol: {
        pair: "BNBUSDT",
        base: "BNB",
        quote: "USDT",
        price: 0,
    },
});

const Trade: React.FC = () => {
    const [layout, setLayout] = useLocalStorage(storage, "layout");
    const [symbol, setSymbol] = useLocalStorage(storage, "tradeSymbol");

    return (
        <MediaQuery.Query
            query={{
                mediatype: "screen",
                width: {
                    min: 768,
                },
            }}
        >
            <MediaQuery.Valid>
                <TopBar />
                <GridLayout
                    components={widgets}
                    layout={layout}
                    onLayoutChange={setLayout}
                    // createItem={
                    //     {
                    //         vertical: {
                    //             i: "item5",
                    //             r: 0,
                    //             w: 2,
                    //             h: 1,
                    //         },
                    //     }
                    // }
                    props={{ symbol }}
                />
            </MediaQuery.Valid>
            <MediaQuery.Invalid message="Essa página não está disponível para versão mobile no momento!" />
        </MediaQuery.Query>
    );
};

export default Trade;
