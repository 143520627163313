import { Underline, UnderlineRef } from "Components";
import { useLayoutEffect, useRef } from "react";
import styles from "./styles.module.scss";

interface Props {
    loading?: boolean;
}

export const Statistic: React.FC<Props> = () => {
    const underlineRef = useRef<UnderlineRef>(null);

    const handleTab = (index: number) => {
        if (underlineRef.current && underlineRef.current.container) {
            const tab = underlineRef.current.container.getElementsByClassName(styles.tab)[index] as HTMLElement;

            Array.from(underlineRef.current.container.getElementsByClassName(styles.active)).forEach((el) => {
                el.classList.remove(styles.active);
            });

            tab.classList.add(styles.active);
            underlineRef.current.updateUnderline(tab);
        }
    };

    useLayoutEffect(() => {
        const loop = setInterval(() => {
            if (!underlineRef.current?.container) {
                return;
            }
            handleTab(0);
            clearInterval(loop);
        }, 100);

        return () => {
            clearInterval(loop);
        };
    }, []);

    return (
        <>
            <Underline
                ref={underlineRef}
                className={styles.tabs}
                classesNames={{
                    underline: styles.underline,
                }}
            >
                <div className={styles.tab} onClick={() => handleTab(0)}>
                    <p>Crédito</p>
                </div>
                <div className={styles.tab} onClick={() => handleTab(1)}>
                    <p>Renda</p>
                </div>
            </Underline>
            <div className={styles.content}></div>
        </>
    );
};
