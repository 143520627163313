import React, { useEffect, useRef, useState } from "react";
import styles from "../styles.module.scss";
import { Box, Button, CircularProgress, Step, StepContent, StepLabel, Stepper, TextField, Typography, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import QRCode from "qrcode";
import { useAPI, useDialog } from "Hooks";
import { MainAPI } from "Helpers";
import { Toast } from "shared-components";
import { InputAmount } from "Components";

const walletAddressFormat = /^0x[a-fA-F0-9]{40}$/;

const SelectInput: React.FC<{
    value: string;
    onChange: (e: SelectChangeEvent<string>) => void;
    list: Array<{ image?: string; value: string; title: string; subtitle?: string; disabled?: boolean }>;
    labelNone?: string;
}> = ({ value, onChange, list, labelNone = "None" }) => {
    return (
        <FormControl size="small" fullWidth>
            <Select value={value} onChange={onChange} displayEmpty>
                <MenuItem value={""} disabled>
                    <div style={{ minHeight: "45px", display: "flex", alignItems: "center", justifyContent: "center", opacity: 0.4 }}>
                        <em>{labelNone}</em>
                    </div>
                </MenuItem>
                {list.map(({ image, value, title, subtitle, disabled = false }) => {
                    return (
                        <MenuItem key={value} value={value} disabled={disabled}>
                            <div
                                style={{
                                    minHeight: "45px",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                {image && (
                                    <div
                                        className={styles["coin-icon"]}
                                        style={{
                                            backgroundImage: image ? `url(${image})` : undefined,
                                        }}
                                    ></div>
                                )}
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Typography variant="h6">{title}</Typography>
                                    {subtitle && (
                                        <Typography
                                            variant="caption"
                                            style={{
                                                opacity: 0.5,
                                            }}
                                        >
                                            {subtitle}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

interface MethodCriptoProps {
    type: "deposit" | "withdraw";
}

export const MethodCripto: React.FC<MethodCriptoProps> = ({ type }) => {
    const dialog = useDialog();

    const [qrCode, setQrCode] = useState<string>("");

    const [tokens, tokens_status, tokens_fetch, tokens_error] = useAPI<Array<{ symbol: string; name: string; operable: boolean }>, "get">(MainAPI, "get", "/assets/token/list", [], true);

    const [networks, networks_status, networks_fetch, networks_error] = useAPI<Array<{ symbol: string; name: string; operable: boolean }>, "get">(MainAPI, "get", "/assets/token/networks", []);

    const [address, address_status, address_fetch, address_error] = useAPI<{ address: string }, "get">(MainAPI, "get", "/wallet/address", { address: "" });

    const [verification, verification_status, verification_fetch, verification_error] = useAPI<{}, "get">(MainAPI, "get", "/wallet/verify_deposit/address", {});

    const [balance, balance_status, balance_fetch, balance_error] = useAPI<
        {
            quantity: number;
            price: number;
            value: number;
        },
        "get"
    >(MainAPI, "get", "/wallet/consult_balance", { quantity: 0, price: 0, value: 0 });

    const [withdrawal, withdrawal_status, withdrawal_fetch, withdrawal_error] = useAPI<{}, "post">(MainAPI, "post", "/wallet/withdraw", {});

    const [activeStep, setActiveStep] = useState<number>(0);
    const [token, setToken] = useState<string>("");
    const [network, setNetwork] = useState<string>("");
    const [drawAmount, setDrawAmount] = useState<number>(0);
    const [walletAddress, setWalletAddress] = useState<string>("");

    const lastStage = useRef<number>(0);

    useEffect(() => {
        if (verification_status === "success") {
            Toast.success("Finalizado com sucesso");
            dialog.close(true);
        }
    }, [verification_status]);

    useEffect(() => {
        if (!address || !address?.address || address.address.trim() === "") {
            return;
        }

        QRCode.toDataURL(address?.address || "", { errorCorrectionLevel: "H", width: 400 }, (err, url) => {
            if (err) {
                console.error(err);
                return;
            }

            setQrCode(url);
        });
    }, [address]);

    useEffect(() => {
        if (tokens_status === "error" && tokens_error) {
            Toast.error(tokens_error);
            dialog.close();
        }

        if (networks_status === "error" && networks_error) {
            Toast.error(networks_error);
            dialog.close();
        }

        if (address_status === "error" && address_error) {
            Toast.error(address_error);
            dialog.close();
        }

        if (verification_status === "error" && verification_error) {
            Toast.error(verification_error);
            dialog.close();
        }

        if (balance_status === "error" && balance_error) {
            Toast.error(balance_error);
            dialog.close();
        }

        if (withdrawal_status === "error" && withdrawal_error) {
            Toast.error(withdrawal_error);
            dialog.close();
        }

        if (type === "withdraw" && withdrawal_status === "success") {
            Toast.success("Retirada realizada com sucesso");
            dialog.close(true);
        }
    }, [
        tokens_status,
        tokens_error,
        networks_status,
        networks_error,
        address_status,
        address_error,
        verification_status,
        verification_error,
        balance_status,
        balance_error,
        withdrawal_status,
        withdrawal_error,
    ]);

    useEffect(() => {
        const time = setTimeout(() => {
            if (activeStep === 1 && lastStage.current < 1) {
                setNetwork("");
                networks_fetch({
                    token,
                });
            } else if (type === "deposit" && activeStep === 2) {
                address_fetch();
            } else if (type === "withdraw" && activeStep === 2) {
                balance_fetch({
                    token,
                });
            }

            lastStage.current = activeStep;
        }, 400);

        return () => {
            clearTimeout(time);
        };
    }, [type, token, activeStep]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFinish = () => {
        dialog.setExit(false);
        if (type === "deposit") {
            verification_fetch({
                token,
                network,
            });
        } else if (type === "withdraw") {
            withdrawal_fetch({
                token,
                network,
                amount: drawAmount,
                address: walletAddress,
            });
        }
    };

    return (
        <div className={styles["dialog-body"]}>
            <Box sx={{ padding: "15px", width: "100%" }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step>
                        <StepLabel>Selecionar Moeda</StepLabel>
                        <StepContent>
                            <div className={styles["content"]}>
                                {tokens_status === "success" && (
                                    <SelectInput
                                        value={token}
                                        onChange={(e) => {
                                            setToken(e.target.value);
                                            // handleNext();
                                        }}
                                        list={
                                            tokens?.map(({ name, symbol, operable = true }) => {
                                                return {
                                                    image: MainAPI.resolveUrl(`/assets/img/coins/${symbol.toLowerCase()}`),
                                                    value: symbol,
                                                    title: symbol.toUpperCase(),
                                                    subtitle: name,
                                                    disabled: !operable,
                                                };
                                            }) ?? []
                                        }
                                        labelNone="Selecione uma moeda"
                                    />
                                )}
                                {tokens_status !== "success" && (
                                    <Box sx={{ margin: "15px 0px", textAlign: "center", opacity: 0.3 }}>
                                        <CircularProgress color="inherit" />
                                    </Box>
                                )}
                            </div>
                            <Box sx={{ mb: 2 }}>
                                <Button disabled={tokens_status !== "success" || token === ""} variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                                    Continue
                                </Button>
                                <Button disabled onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                    Voltar
                                </Button>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Selecionar Rede</StepLabel>
                        <StepContent>
                            <div className={styles["content"]}>
                                {networks_status === "success" && (
                                    <SelectInput
                                        value={network}
                                        onChange={(e) => {
                                            setNetwork(e.target.value);
                                            // handleNext();
                                        }}
                                        list={
                                            networks?.map(({ name, symbol, operable = true }) => {
                                                return {
                                                    value: symbol,
                                                    title: symbol.toUpperCase(),
                                                    subtitle: name,
                                                    disabled: !operable,
                                                };
                                            }) ?? []
                                        }
                                        labelNone="Selecione a Rede"
                                    />
                                )}
                                {networks_status !== "success" && (
                                    <Box sx={{ margin: "15px 0px", textAlign: "center", opacity: 0.3 }}>
                                        <CircularProgress color="inherit" />
                                    </Box>
                                )}
                            </div>
                            <Box sx={{ mb: 2 }}>
                                <Button disabled={networks_status !== "success" || network === ""} variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                                    Continue
                                </Button>
                                <Button disabled={networks_status !== "success"} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                    Voltar
                                </Button>
                            </Box>
                        </StepContent>
                    </Step>
                    {type === "withdraw" && (
                        <Step>
                            <StepLabel>Digite o montante desejável</StepLabel>
                            <StepContent>
                                <div
                                    style={{
                                        height: "32px",
                                    }}
                                ></div>
                                {balance_status == "success" && <InputAmount value={drawAmount} onChange={setDrawAmount} currency={token} min={0} max={balance?.quantity ?? 0} />}
                                {balance_status !== "success" && (
                                    <Box sx={{ margin: "15px 0px", textAlign: "center", opacity: 0.3 }}>
                                        <CircularProgress color="inherit" />
                                    </Box>
                                )}
                                <Box sx={{ mt: 4, mb: 2 }}>
                                    <Button
                                        disabled={balance_status !== "success" || drawAmount <= 0 || drawAmount > (balance?.quantity ?? 0)}
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Continue
                                    </Button>
                                    <Button disabled={balance_status !== "success"} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                        Voltar
                                    </Button>
                                </Box>
                            </StepContent>
                        </Step>
                    )}
                    {type === "deposit" && (
                        <Step>
                            <StepLabel>Endereço de Depósito</StepLabel>
                            <StepContent>
                                <div className={styles["content"]}>
                                    {(address_status === "success" || verification_status !== "idle") && (
                                        <>
                                            <div className={styles["qrcode"]}>
                                                <img src={qrCode} alt={address?.address ?? ""} draggable="false" />
                                            </div>
                                            <TextField
                                                label="BEP-20"
                                                value={address?.address ?? ""}
                                                size="small"
                                                fullWidth
                                                slotProps={{
                                                    input: {
                                                        readOnly: true,
                                                    },
                                                }}
                                                sx={{
                                                    margin: "0px auto 15px",
                                                }}
                                            />
                                            <div className={styles["warning"]}>
                                                Utilize o endereço acima para receber seus ativos. A operação poderá ser confirmada dentre 10 a 40 minutos. Atenção: envie ativos somente da rede
                                                especificada para que o sistema possa identificar e creditar em sua conta.
                                            </div>
                                        </>
                                    )}

                                    {(address_status !== "success" || verification_status !== "idle") && (
                                        <Box sx={{ margin: "15px 0px", textAlign: "center", opacity: 0.3 }}>
                                            <CircularProgress color="inherit" />
                                        </Box>
                                    )}
                                </div>
                                {verification_status === "idle" && (
                                    <Box sx={{ mb: 2 }}>
                                        <Button disabled={address_status !== "success"} variant="contained" onClick={handleFinish} sx={{ mt: 1, mr: 1 }}>
                                            Finalizar
                                        </Button>
                                        <Button disabled={address_status !== "success"} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                            Voltar
                                        </Button>
                                    </Box>
                                )}
                            </StepContent>
                        </Step>
                    )}
                    {type === "withdraw" && (
                        <Step>
                            <StepLabel>Informe o endereço para envio</StepLabel>
                            <StepContent>
                                <div className={styles["content"]}>
                                    <TextField
                                        value={walletAddress}
                                        onChange={({ target }) => {
                                            setWalletAddress(target.value);
                                        }}
                                        label="Endereço"
                                        variant="outlined"
                                        autoComplete="off"
                                        fullWidth
                                        sx={{ mt: 4, mb: 2 }}
                                        error={walletAddress.length > 0 && !walletAddressFormat.test(walletAddress)}
                                        helperText={walletAddress.length > 0 && !walletAddressFormat.test(walletAddress) ? "Endereço inválido, formato esperado: 0x0000...0000" : ""}
                                    />
                                    <div className={styles["warning"]}>
                                        Atenção ao informar o endereço de destino, a operação não poderá ser desfeita. Certifique-se de que o endereço informado é correto antes de finalizar.
                                    </div>
                                    {withdrawal_status !== "idle" && (
                                        <Box sx={{ margin: "15px 0px", textAlign: "center", opacity: 0.3 }}>
                                            <CircularProgress color="inherit" />
                                        </Box>
                                    )}
                                </div>
                                {withdrawal_status === "idle" && (
                                    <Box sx={{ mb: 4 }}>
                                        <Button disabled={!walletAddressFormat.test(walletAddress)} variant="contained" onClick={handleFinish} sx={{ mt: 1, mr: 1 }}>
                                            Finalizar
                                        </Button>
                                        <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                            Voltar
                                        </Button>
                                    </Box>
                                )}
                            </StepContent>
                        </Step>
                    )}
                </Stepper>
            </Box>
        </div>
    );
};
