import { MainWebsocket } from "Helpers";
import React from "react";

export const useWebsocket = <D = any>(event: string, effect: (data: D) => ReturnType<React.EffectCallback>, deps?: React.DependencyList) => {
    React.useEffect(() => {
        let destructor: ReturnType<React.EffectCallback> = () => {};

        const callback = (data: D) => {
            destructor?.();
            destructor = effect(data);
        };

        MainWebsocket.onReceive(event, callback);

        return () => {
            MainWebsocket.offReceive(event, callback);
            destructor?.();
        };
    }, deps);
};
