import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { GridItemRef, GridItemProps, SimpleGridItemProps } from "./Types";
import { clsx } from "Utils";

const GridItemComponent = <C extends Record<string, React.FC>, K extends keyof C = keyof C>(
    {
        component,
        className,
        style,
        clientHeigth,
        cols,
        rows,
        i: id,
        uuid,
        margin,
        w: width,
        h: height,
        c: column,
        r: row,
        maxW,
        minW,
        maxH,
        minH,
        fixed,
        isDraggable,
        onResize,
        onInsertionNew,
        isStart,
        isEnd,
        insertable = false,
    }: GridItemProps<C, K>,
    ref: React.Ref<GridItemRef<C, K>>
) => {
    const [_, forceUpdate] = useState<number>(Date.now());
    const [resizing, setResizing] = useState(false);
    const [dragging, setDragging] = useState(false);

    const propsNow = useRef<SimpleGridItemProps<C, K>>({
        fixed,
        isDraggable,
        cols,
        rows,
        uuid,
        w: width,
        h: height,
        c: column,
        r: row,
        maxW,
        minW,
        maxH,
        minH,
        margin,
        clientHeigth,
    });

    const elementRef = useRef<HTMLDivElement>(null);
    const startResRef = useRef<HTMLDivElement>(null);
    const endResRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        propsNow.current = {
            fixed,
            isDraggable,
            cols,
            rows,
            uuid,
            w: width,
            h: height,
            c: column,
            r: row,
            maxW,
            minW,
            maxH,
            minH,
            margin,
            clientHeigth,
        };

        updateByProps();
    }, [uuid, fixed, isDraggable, cols, rows, width, height, column, row, clientHeigth, margin, maxW, minW, maxH, minH]);

    const [marginY, marginX] = margin;

    const updateByProps = useCallback(() => {
        const props = propsNow.current;
        const [marginY, marginX] = props.margin;

        const leftPosition = parseFloat(((props.c / props.cols) * 100).toFixed(2));
        const widthPosition = parseFloat(((props.w / props.cols) * 100).toFixed(2));

        if (elementRef.current) {
            elementRef.current.classList.toggle("static", props.fixed);
            elementRef.current.classList.toggle("react-grid-draggable", props.isDraggable);
            elementRef.current.classList.toggle("react-grid-remove", Math.round(leftPosition) < 0 || Math.round(leftPosition + (props.minW / props.cols) * 100) > 100);

            elementRef.current.style.left = `calc(${leftPosition}% + ${props.c <= 0 ? marginY : 0}px)`;
            elementRef.current.style.width = `calc(${widthPosition}% - ${props.c <= 0 ? marginX * 2 : marginX}px)`;
            elementRef.current.style.maxHeight = typeof props.maxH === "number" && isFinite(props.maxH) ? `${(props.clientHeigth * (props.maxH / rows)).toFixed(2)}px` : "";
        }

        if (startResRef.current) {
            startResRef.current.style.left = `0px`;
            startResRef.current.style.width = `${marginY}px`;
        }

        if (endResRef.current) {
            endResRef.current.style.left = `calc(${leftPosition + widthPosition}% - ${marginY}px)`;
            endResRef.current.style.width = `${marginY - 1}px`;
        }
    }, []);

    useImperativeHandle(
        ref,
        () => {
            return {
                updateProps({ fixed, isDraggable, cols, w: width, h: height, c: column, r: row, margin, clientHeigth }) {
                    propsNow.current.fixed = typeof fixed === "boolean" ? fixed : propsNow.current.fixed;
                    propsNow.current.isDraggable = typeof isDraggable === "boolean" ? isDraggable : propsNow.current.isDraggable;
                    propsNow.current.margin = Array.isArray(margin) && margin.length === 2 ? margin : propsNow.current.margin;
                    propsNow.current.cols = typeof cols === "number" ? cols : propsNow.current.cols;
                    propsNow.current.w = typeof width === "number" ? width : propsNow.current.w;
                    propsNow.current.h = typeof height === "number" ? height : propsNow.current.h;
                    propsNow.current.c = typeof column === "number" ? column : propsNow.current.c;
                    propsNow.current.r = typeof row === "number" ? row : propsNow.current.r;
                    propsNow.current.clientHeigth = typeof clientHeigth === "number" ? clientHeigth : propsNow.current.clientHeigth;
                    updateByProps();
                },
                forceUpdate(p) {
                    this.updateProps(p);
                    forceUpdate(Date.now());
                },
                getProp(key) {
                    return propsNow.current[key];
                },
            };
        },
        [updateByProps]
    );

    const insertionNew = (p: { isStart: boolean; isEnd: boolean }) =>
        insertable
            ? (e: React.MouseEvent<HTMLDivElement>) => {
                  e.preventDefault?.();
                  e.stopPropagation?.();
                  const { c: column, r: row, w: width, h: height } = propsNow.current;
                  onInsertionNew?.({ ...p, id: id as any, uuid, column, row, width, height });
              }
            : undefined;

    const resizeMousedown = (p: { isStart: boolean; isEnd: boolean }) =>
        !insertable && (p.isStart || p.isEnd)
            ? undefined
            : (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
                  e.preventDefault?.();
                  e.stopPropagation?.();
                  const { c: column, r: row, w: width, h: height } = propsNow.current;
                  onResize?.({ ...p, id: id as any, uuid, column, row, width, height });
              };

    const leftPosition = parseFloat(((column / cols) * 100).toFixed(2));
    const widthPosition = parseFloat(((width / cols) * 100).toFixed(2));

    return (
        <>
            {isStart === true && (
                <div
                    ref={startResRef}
                    className={clsx("react-grid-y-resize-handle", {
                        "react-grid-handle-disabled": !insertable,
                    })}
                    style={{
                        left: `0px`,
                        width: `${marginY}px`,
                    }}
                    onDoubleClick={insertionNew({ isStart: true, isEnd: false })}
                    onMouseDown={resizeMousedown({ isStart: true, isEnd: false })}
                />
            )}
            <div
                key={uuid}
                ref={elementRef}
                className={clsx("react-grid-item", className, {
                    static: fixed,
                    resizing: Boolean(resizing),
                    "react-draggable": isDraggable,
                    "react-draggable-dragging": Boolean(dragging),
                })}
                style={{
                    ...style,
                    left: `calc(${leftPosition}% + ${column <= 0 ? marginY : 0}px)`,
                    width: `calc(${widthPosition}% - ${column <= 0 ? marginX * 2 : marginX}px)`,
                    maxHeight: typeof maxH === "number" && isFinite(maxH) ? `${(clientHeigth * (maxH / rows)).toFixed(2)}px` : undefined,
                    margin: "0px",
                }}
            >
                {React.isValidElement(component) ? component : typeof component === "function" ? component({}) : null}
            </div>
            <div
                ref={endResRef}
                className={clsx("react-grid-y-resize-handle", {
                    "react-grid-handle-disabled": !insertable && isEnd,
                })}
                onDoubleClick={insertionNew({ isStart: false, isEnd })}
                onMouseDown={resizeMousedown({ isStart: false, isEnd })}
                style={{
                    left: `calc(${leftPosition + widthPosition}% - ${marginY}px)`,
                    width: `${marginY - 1}px`,
                }}
            />
        </>
    );
};

export const GridItem = forwardRef(GridItemComponent);
