import React, { useEffect, useState } from "react";
import { getAuth } from "ivipbase";

export const useUser = () => {
    const [user, setUser] = useState(getAuth().currentUser);

    useEffect(() => {
        const auth = getAuth();
        auth.ready((user) => {
            setUser(user);
        });

        const onAuthStateChanged = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        return () => {
            onAuthStateChanged.stop();
        };
    }, []);

    return user;
};
