import React, { useState, useRef, useEffect, useCallback } from "react";
import { Color } from "shared-components";
import { Candles } from "./Candle";
import { ChartsController } from "./Controller";
import { drawTextWithBackground, getCSSProperty, getFontDetails, measureText } from "Utils";

export const useCandles = (effect: (data: Candles) => ReturnType<React.EffectCallback> = () => {}, deps: React.DependencyList = []) => {
    const [time, setTime] = useState<number>(Date.now());
    const candlesRef = useRef<Candles>(new Candles([]));

    useEffect(() => {
        const event = candlesRef.current.on("mutation", () => {
            setTime(Date.now());
        });

        return () => {
            event.stop();
        };
    }, []);

    useEffect(() => {
        return effect(candlesRef.current);
    }, [...deps, time]);

    return {
        list: candlesRef.current.candles,
        loadData: candlesRef.current.loadData.bind(candlesRef.current),
        push: candlesRef.current.push.bind(candlesRef.current),
        getCandlesByView: candlesRef.current.getCandlesByView.bind(candlesRef.current),
        current: candlesRef.current,
    };
};

const defaultProperties = {
    colors: {
        background: "transparent",
        grid: "rgba(0, 0, 0, 0.1)",
        text: "#000",
        dark: "#000",
        light: "#fff",
        candle: {
            up: "#03a678",
            down: "#c0392b",
        },
    },
};

const formatPriceLabel = (price: number) => {
    const [int] = price.toFixed(8).split(".");
    const label = int.length > 2 ? Math.round(price).toFixed(0) : parseInt(int) > 0 ? price.toFixed(2) : price.toFixed(8);
    return parseFloat(label).toLocaleString();
};

export const useChartsController = (effect: React.EffectCallback = () => {}, deps: React.DependencyList = []) => {
    const [time, setTime] = useState<number>(Date.now());
    const candlesticksRef = useRef<ChartsController>(new ChartsController());
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext("2d");

        if (!canvas || !ctx) {
            return;
        }

        let theme = getCSSProperty(canvas, defaultProperties),
            axisYWidth = 0;

        candlesticksRef.current.normalizePointer = (pos: { x: number; y: number }) => {
            if (!canvas) {
                return pos;
            }
            const rect = canvas.getBoundingClientRect();
            const x = (pos.x - rect.left) * (canvas.width / rect.width);
            const y = (pos.y - rect.top) * (canvas.height / rect.height);
            return { x, y };
        };

        const on_initialRender = candlesticksRef.current.on("initialRender", (scope) => {
            const pixelRatio = window.devicePixelRatio || 1;

            canvas.width = (canvas.clientWidth ?? 0) * pixelRatio;
            canvas.height = (canvas.clientHeight ?? 0) * pixelRatio;

            scope.config.width = canvas.width;
            scope.config.height = canvas.height;

            const rect = canvas.getBoundingClientRect();

            scope.config.top = rect.top;
            scope.config.left = rect.left;
            scope.config.right = rect.right;
            scope.config.bottom = rect.bottom;

            ctx.font = getFontDetails(getComputedStyle(canvas).font, {
                fontSize: 12,
            }).font;

            scope.config.axis.y.width = axisYWidth;
            scope.config.axis.x.height = measureText(ctx, "text").height + 14;

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            theme = getCSSProperty(canvas, defaultProperties);
        });

        const on_render = candlesticksRef.current.on("render", ({ grid, charts, crosshair, mouse }) => {
            axisYWidth = 0;

            canvas.style.cursor = mouse.cursor;

            grid.vertical.forEach(({ left, top, bottom, date }) => {
                ctx.save();
                ctx.strokeStyle = theme.colors.grid;
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.moveTo(left, top);
                ctx.lineTo(left, bottom);
                ctx.stroke();
                ctx.restore();

                const label = date instanceof Date ? date.toLocaleDateString().replace(String(date.getFullYear()), "").replace(/^\/+/g, "").replace(/\/+$/g, "") : "";

                ctx.save();
                ctx.fillStyle = theme.colors.text;
                ctx.textAlign = "center";
                ctx.textBaseline = "hanging";
                ctx.fillText(label, left, bottom + 8);
                ctx.restore();
            });

            grid.horizontal.forEach(({ left, right, top, value }) => {
                ctx.save();
                ctx.strokeStyle = theme.colors.grid;
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.moveTo(left, top);
                ctx.lineTo(right, top);
                ctx.stroke();
                ctx.restore();

                ctx.save();
                ctx.fillStyle = theme.colors.text;
                ctx.textAlign = "start";
                ctx.textBaseline = "middle";
                ctx.fillText(formatPriceLabel(value), right + 10, top);
                axisYWidth = Math.max(axisYWidth, Math.round((measureText(ctx, formatPriceLabel(value)).width + 10) / 10) * 10);
                ctx.restore();
            });

            for (const chart of charts) {
                const { type, width, height, x, y, right, axisIndicator, indicators } = chart;
                ctx.save();
                ctx.beginPath();
                ctx.rect(x, y, width, height);
                ctx.clip();

                switch (type) {
                    case "candle": {
                        const { candles } = chart;

                        candles.map(({ shadow, body, color: c }) => {
                            const color = c === "green" ? theme.colors.candle.up : theme.colors.candle.down;

                            ctx.save();
                            ctx.strokeStyle = color;
                            ctx.fillStyle = color;
                            ctx.lineWidth = 2;
                            ctx.beginPath();
                            ctx.moveTo(shadow.left, shadow.top + y);
                            ctx.lineTo(shadow.left, shadow.bottom + y);
                            ctx.stroke();
                            ctx.fill();

                            ctx.fillRect(body.left, body.top + y, body.width, body.height);
                            ctx.strokeRect(body.left, body.top + y, body.width, body.height);
                            ctx.restore();
                        });

                        break;
                    }
                    case "bar": {
                        const { bars } = chart;

                        bars.map(({ left, top, color: c, width, height, type = "solid" }) => {
                            const color = c === "green" ? theme.colors.candle.up : theme.colors.candle.down;
                            const lineWidth = 2;

                            ctx.save();
                            if (type === "solid") {
                                ctx.fillStyle = color;
                                ctx.fillRect(left, top + y, width, height);
                            } else {
                                ctx.strokeStyle = color;
                                ctx.lineWidth = lineWidth;
                                ctx.beginPath();
                                ctx.moveTo(left + lineWidth, top + lineWidth + y);
                                ctx.lineTo(left + width - lineWidth, top + lineWidth + y);
                                ctx.lineTo(left + width - lineWidth, top + height - lineWidth + y);
                                ctx.lineTo(left + lineWidth, top + height - lineWidth + y);
                                ctx.closePath();
                                ctx.stroke();
                            }
                            ctx.restore();
                        });

                        break;
                    }
                }

                indicators.forEach(({ type, color, data, stroke = 1 }) => {
                    ctx.save();
                    ctx.strokeStyle = new Color(color).rgb;
                    ctx.lineWidth = stroke;
                    let isStarted = false;
                    data.forEach((item) => {
                        if (!item) {
                            isStarted = false;
                            return;
                        }
                        if (!isStarted) {
                            ctx.beginPath();
                        }
                        const { x, y: top } = item;
                        ctx.lineTo(x, top + y);

                        isStarted = true;
                    });
                    ctx.stroke();
                    ctx.restore();
                });

                ctx.restore();

                if (axisIndicator && axisIndicator.y) {
                    const { left, top, value, color, line = false } = axisIndicator.y;
                    const colorBox = new Color(color && color === "red" ? theme.colors.candle.down : color && color === "green" ? theme.colors.candle.up : theme.colors.text);

                    const { width } = drawTextWithBackground(ctx, formatPriceLabel(value), left, top, {
                        fill: colorBox.rgba,
                        fontSize: 14,
                        fontWeight: "bold",
                        stroke: "transparent",
                        color: colorBox.brightness(0.9),
                        padding: [5, 8],
                        borderRadius: [0, 5, 5, 0],
                        textAlign: "left",
                        baseline: "middle",
                    });

                    axisYWidth = Math.max(axisYWidth, Math.round((width + 10) / 10) * 10);

                    if (line) {
                        ctx.save();
                        ctx.strokeStyle = colorBox.rgba;
                        ctx.lineWidth = 1;
                        ctx.setLineDash([5]);
                        ctx.beginPath();
                        ctx.moveTo(0, top);
                        ctx.lineTo(left, top);
                        ctx.stroke();
                        ctx.restore();
                    }
                }

                ctx.save();
                ctx.strokeStyle = theme.colors.grid;
                ctx.lineWidth = 4;
                ctx.beginPath();
                ctx.moveTo(0, y + height);
                ctx.lineTo(right + axisYWidth, y + height);
                ctx.stroke();
                ctx.beginPath();
                ctx.moveTo(right, y);
                ctx.lineTo(right, y + height);
                ctx.stroke();
                ctx.restore();
            }

            (({ view, lines, show }) => {
                if (show) {
                    ctx.save();
                    ctx.beginPath();
                    ctx.rect(view.x, view.y, view.width, view.height);
                    ctx.clip();

                    ctx.strokeStyle = theme.colors.text;
                    ctx.lineWidth = 1;
                    ctx.setLineDash([5]);

                    if (lines.horizontal.show) {
                        ctx.beginPath();
                        ctx.moveTo(lines.horizontal.left, lines.horizontal.top);
                        ctx.lineTo(lines.horizontal.right, lines.horizontal.bottom);
                        ctx.stroke();
                    }

                    if (lines.vertical.show) {
                        ctx.beginPath();
                        ctx.moveTo(lines.vertical.left, lines.vertical.top);
                        ctx.lineTo(lines.vertical.right, lines.vertical.bottom);
                        ctx.stroke();
                    }

                    ctx.restore();
                    ctx.save();

                    if (lines.vertical.show) {
                        let isDate = lines.vertical.value.getHours() === 0 && lines.vertical.value.getMinutes() === 0 && lines.vertical.value.getSeconds() === 0;

                        drawTextWithBackground(ctx, isDate ? lines.vertical.value.toLocaleDateString() : lines.vertical.value.toLocaleString(), lines.vertical.left, lines.vertical.bottom, {
                            fill: theme.colors.text,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: new Color(theme.colors.text).brightness(0.8),
                            padding: [5, 8],
                            borderRadius: 5,
                            textAlign: "center",
                            stroke: "transparent",
                        });
                    }

                    if (lines.horizontal.show) {
                        drawTextWithBackground(ctx, formatPriceLabel(lines.horizontal.value), lines.horizontal.right, lines.horizontal.top, {
                            fill: theme.colors.text,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: new Color(theme.colors.text).brightness(0.8),
                            padding: [5, 8],
                            borderRadius: [0, 5, 5, 0],
                            textAlign: "left",
                            baseline: "middle",
                            stroke: "transparent",
                        });
                    }

                    ctx.beginPath();
                    ctx.fillStyle = theme.colors.text;
                    ctx.arc(lines.vertical.left, lines.horizontal.top, 2, 0, 2 * Math.PI);
                    ctx.fill();

                    ctx.restore();
                }
            })(crosshair);
        });

        const callback = effect();
        return () => {
            on_initialRender.stop();
            on_render.stop();
            candlesticksRef.current.disconnect();
            callback?.();
        };
    }, [...deps, time, canvasRef.current]);

    const ref = useCallback((canvas: HTMLCanvasElement | null) => {
        const modified = canvas !== canvasRef.current;
        canvasRef.current = canvas;
        if (modified) {
            setTime(Date.now());
        }
    }, []);

    return {
        ref,
        loadCandles: candlesticksRef.current.loadCandles.bind(candlesticksRef.current),
        toZoom: candlesticksRef.current.toZoom.bind(candlesticksRef.current),
        toScroll: candlesticksRef.current.toScroll.bind(candlesticksRef.current),
        eventMousedown: candlesticksRef.current.eventMousedown.bind(candlesticksRef.current),
        eventMousemove: candlesticksRef.current.eventMousemove.bind(candlesticksRef.current),
        eventMouseup: candlesticksRef.current.eventMouseup.bind(candlesticksRef.current),
        eventMouseover: candlesticksRef.current.eventMouseover.bind(candlesticksRef.current),
        eventMouseout: candlesticksRef.current.eventMouseout.bind(candlesticksRef.current),
        eventTouchstart: candlesticksRef.current.eventTouchstart.bind(candlesticksRef.current),
        eventTouchmove: candlesticksRef.current.eventTouchmove.bind(candlesticksRef.current),
        eventTouchend: candlesticksRef.current.eventTouchend.bind(candlesticksRef.current),
        eventWheel: candlesticksRef.current.eventWheel.bind(candlesticksRef.current),
        current: candlesticksRef.current,
    };
};
