import { SimpleEventEmitter } from "shared-components";
import { CandleItem, Candles } from "./Candle";
import { ChartsNames, ControllerConfig, ControllerRender } from "./Types";
import { DeepPartial } from "Types";
import { cloneLiteral, joinObject } from "Utils";
import { ChartMACD, ChartMain, ChartVOL, Controller } from "./Charts";
import MouseController from "./MouseController";

interface EventsEmitters {
    initialized: [ChartsController];
    initialRender: [ChartsController];
    finalRender: [ChartsController];
    render: [ControllerRender];
    disconnected: [];
}

export class ChartsController extends SimpleEventEmitter<EventsEmitters> {
    private _candles: Candles = new Candles();
    private _candles_in_view: CandleItem[] = [];
    private _frame_loop: number | undefined = undefined;
    private _is_disconnected: boolean = false;
    private _config: ControllerConfig = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: 0,
        height: 0,
        axis: {
            x: {
                height: 0,
                minSpace: 100,
            },
            y: {
                width: 0,
                minSpace: 40,
            },
        },
        mouse: new MouseController(this),
        zoom: {
            x: 1,
            y: 1,
        },
        scroll: {
            x: 18,
            y: 0,
        },
        charts: {
            main: {},
            VOL: {},
            MACD: {},
        },
    };
    private _normalized_pointer: (pos: { x: number; y: number }) => { x: number; y: number } = (pos) => pos;

    private _chart: {
        [c in ChartsNames]: Controller<c>;
    };

    constructor() {
        super();

        let chartsLength = 0;

        this._chart = {
            main: new ChartMain(this, this._config.charts.main, () => chartsLength++),
            VOL: new ChartVOL(this, this._config.charts.VOL, () => chartsLength++),
            MACD: new ChartMACD(this, this._config.charts.MACD, () => chartsLength++),
        };

        this.showChart("main");
        this.showChart("VOL");
    }

    get config(): ControllerConfig {
        return this._config;
    }

    set config(config: DeepPartial<ControllerConfig>) {
        this._config = joinObject(this._config, config);
    }

    get normalizePointer() {
        return this._normalized_pointer;
    }

    set normalizePointer(normalize: (pos: { x: number; y: number }) => { x: number; y: number }) {
        this._normalized_pointer = typeof normalize === "function" ? normalize : (pos) => pos;
    }

    showChart(chart: ChartsNames) {
        if (this._chart[chart].config.hidden === false) {
            return;
        }

        const length = Object.keys(this._chart).filter((k) => !this._chart[k as ChartsNames].config.hidden).length;

        this._chart[chart].show();

        const initalDivider = (this._chart[chart].divider = length < 1 ? 1 : 0.3);
        const subtract = length < 1 ? 0 : initalDivider / length;

        for (const k in this._chart) {
            const c = k as ChartsNames;
            if (!this._chart[c].config.hidden && c !== chart) {
                this._chart[c].divider -= subtract;
            }
        }
    }

    hideChart(chart: Exclude<ChartsNames, "main">) {
        if (this._chart[chart].config.hidden || (chart as any) === "main") {
            return;
        }

        this._chart[chart].hide();

        const length = Object.keys(this._chart).filter((k) => !this._chart[k as ChartsNames].config.hidden).length;
        const subtract = this._chart[chart].divider / length;

        for (const k in this._chart) {
            const c = k as ChartsNames;

            if (!this._chart[c].config.hidden && c !== chart) {
                this._chart[c].divider += subtract;
            }
        }
    }

    loadCandles(candles: Candles) {
        this._candles = candles.clone();
        this.initialize();
    }

    initialize() {
        this._is_disconnected = false;
        this.emit("initialized", this);
        this.update();
    }

    updateDivider() {
        const { height, axis, mouse, resize } = this._config;

        if (resize) {
            const percent = (mouse.move.normalized.y - mouse.move.before.normalized.y) / (height - axis.x.height);
            this._chart[resize.before].divider += percent;
            this._chart[resize.after].divider -= percent;
            const before = this._chart[resize.before].divider;
            this._chart[resize.before].divider = Math.max(before, 0.1);
            this._chart[resize.after].divider += before - this._chart[resize.before].divider;
            const after = this._chart[resize.after].divider;
            this._chart[resize.after].divider = Math.max(after, 0.1);
            this._chart[resize.before].divider += after - this._chart[resize.after].divider;
        }
    }

    updateSizes() {
        const { width, height, axis } = this._config;
        let posY = 0;

        for (const k in this._chart) {
            const divider = this._chart[k as ChartsNames].divider;
            this._chart[k as ChartsNames].config.width = width - axis.y.width;
            this._chart[k as ChartsNames].config.height = (height - axis.x.height) * divider;
            this._chart[k as ChartsNames].config.posY = posY;
            posY += this._chart[k as ChartsNames].config.height;
        }
    }

    getScroll(): { x: number; y: number } {
        this.updateSizes();
        const { zoom, scroll } = this.config;
        const {
            config: { width, height },
            padding,
        } = this._chart.main;
        this._candles.viewConfig = { width, height, zoom, padding };
        const { width: candles_width } = this._candles.getDimensions();
        const x = candles_width - width + scroll.x;
        const y = scroll.y;
        return { x, y };
    }

    disconnect() {
        this._is_disconnected = true;
        if (typeof this._frame_loop === "number") window.cancelAnimationFrame(this._frame_loop);
        this.emit("disconnected");
        this.clearEvents();
    }

    update() {
        if (typeof this._frame_loop === "number") window.cancelAnimationFrame(this._frame_loop);
        if (this._is_disconnected) {
            return;
        }

        this.emit("initialRender", this);
        this.updateSizes();

        const { zoom, mouse } = this.config;
        const {
            config: { width, height, candle },
            padding,
        } = this._chart.main;

        // this._candles.bodyWidth = Math.min(zoom.x !== 1 ? ((width * zoom.x) / (candle.body.width + candle.space)) * candle.body.width : candle.body.width, candle.body.width);
        this._candles.shadowWidth = candle.shadow.width;
        this._candles.space = candle.space;
        this._candles.viewConfig = { ...this.getScroll(), width, height, zoom, padding };

        const candles_in_view = (this._candles_in_view = this._candles.getCandlesByView());

        const result: ControllerRender = {
            charts: [],
            grid: this.renderGrid(),
            mouse: {
                ...this._config.mouse,
                inAreaChart: this.inAreaChart,
                inResizeChart: this.inResizeChart || mouse.resizing,
                inAxisX: this.inAxisX,
                inAxisY: this.inAxisY,
                cursor: this.inResizeChart || mouse.resizing || this.inAxisY ? "row-resize" : this.inAxisX ? "col-resize" : "crosshair",
            },
            crosshair: this.crosshair,
        };

        const chartsNames: ChartsNames[] = [];

        for (const k in this._chart) {
            const chart = k as ChartsNames;

            if (this._chart[chart].config.hidden) {
                continue;
            }

            // this._chart[chart].options = this._config;
            this._chart[chart].candles = this._candles;
            this._chart[chart].candlesInView = candles_in_view;
            this._chart[chart].initialize();
            chartsNames.push(chart);
        }

        const allList = this._candles.candles;

        candles_in_view.forEach((candle) => {
            chartsNames.forEach((chart) => {
                this._chart[chart].loadCandle(candle, candle.index, allList);
            });
        });

        chartsNames.forEach((chart) => {
            const { index, config } = this._chart[chart];
            const { width, posY } = config;
            result.charts.push({
                ...this._chart[chart].data,
                index,
                ...this._chart[chart].clientRect,
                axisIndicator: this._chart[chart].axisIndicator,
                indicators: this._chart[chart].indicators ?? [],
            });

            this._chart[chart].yAxis.forEach(({ y, value }) => {
                result.grid.horizontal.push({
                    top: y + posY,
                    left: 0,
                    right: width,
                    bottom: y + posY,
                    width,
                    height: 0,
                    center: y + posY,
                    value,
                });
            });
        });

        result.charts.sort((a, b) => (a.index ?? Infinity) - (b.index ?? Infinity));

        this.emit("render", result);
        this.emit("finalRender", this);
        this._frame_loop = window.requestAnimationFrame(() => {
            this.update();
        });
    }

    get currentChart() {
        for (const k in this._chart) {
            if (!this._chart[k as ChartsNames].config.hidden && this._chart[k as ChartsNames].isHovered) {
                return this._chart[k as ChartsNames];
            }
        }
        return undefined;
    }

    get crosshair() {
        const { width, height, axis, mouse } = this._config;

        let posX = this._config.mouse.move.x;
        let candleSelected: CandleItem | undefined = this._candles_in_view[0];

        if (candleSelected) {
            this._candles_in_view.forEach((candle, i) => {
                const { body, space } = candle.client;
                const left = body.left - space;
                const right = body.right + space;

                if ((i === 0 && left >= posX) || (i === this._candles_in_view.length - 1 && right <= posX) || (left <= posX && right >= posX)) {
                    candleSelected = candle;
                }
            });

            posX = candleSelected.client.body.center;
        }

        return {
            show: this.inAreaChart && !mouse.resizing,
            view: {
                x: 0,
                y: 0,
                width: width - axis.y.width,
                height: height - axis.x.height,
            },
            pointer: {
                x: posX,
                y: mouse.move.y,
            },
            lines: {
                vertical: {
                    bottom: height - axis.x.height,
                    top: 0,
                    left: posX,
                    right: posX,
                    show: candleSelected !== undefined,
                    value: candleSelected ? candleSelected?.date : new Date(),
                },
                horizontal: {
                    bottom: mouse.move.y,
                    top: mouse.move.y,
                    left: 0,
                    right: width - axis.y.width,
                    show: true,
                    value: this.currentChart?.yAxisCurrentValue ?? 0,
                },
            },
        };
    }

    private renderGrid() {
        const primary = this._candles.getCandleByIndex(0);
        const secondary = this._candles.getCandleByIndex(1);

        if (!primary) {
            return {
                vertical: [],
                horizontal: [],
            };
        }

        const { height, axis } = this.config;
        const { candle, width } = this._chart.main.config;

        const startX = primary.client.body.center ?? 0;
        const endX = secondary?.client.body.center ?? startX + candle.body.width + candle.space;
        const size = Math.abs(endX - startX);
        const minSize = Math.max(axis.x.minSpace, (candle.body.width + candle.space) * 3);

        let jumps = 2,
            x = startX;

        while (size * jumps < minSize) {
            jumps *= 2;
        }

        if (x < 0) {
            while (x < 0) {
                x += size * (jumps - 1);
            }
        } else if (x > size) {
            while (x > size) {
                x -= size * (jumps - 1);
            }
        }

        const getDateAtIndex = (index: number): Date | undefined => {
            const candle = this._candles.getCandleByIndex(index);

            if (candle) {
                return candle.date;
            }

            // else if (index < 0) {
            //     const end = this._candles.getCandleByIndex(0, true);
            //     const start = this._candles.getCandleByIndex(end.index + jumps - 1, true);
            //     const diff = start.date.getTime() - end.date.getTime();
            //     return new Date(end.date.getTime() - (Math.abs(index) / (start.index - end.index)) * diff);
            // }

            // const start = this._candles.getCandleByIndex(Infinity, true);
            // const end = this._candles.getCandleByIndex(start.index - (jumps - 1), true);
            // const diff = start.date.getTime() - end.date.getTime();
            // return new Date(start.date.getTime() + ((index - start.index) / (start.index - end.index)) * diff);
        };

        const result: ControllerRender["grid"] = {
            vertical: [],
            horizontal: [],
        };

        for (x; x < width; x += size * (jumps - 1)) {
            if (x < 0) {
                continue;
            }

            const index = Math.floor((x - startX) / size);

            result.vertical.push({
                top: 0,
                left: x,
                right: x,
                bottom: height - axis.x.height,
                width: 0,
                height: height - axis.x.height,
                center: x,
                date: getDateAtIndex(index),
            });
        }

        return result;
    }

    get inAreaChart(): boolean {
        const { width, height, axis, mouse } = this._config;
        return (
            mouse.hover &&
            !this.inResizeChart &&
            mouse.move.normalized.x > 0 &&
            mouse.move.normalized.x < width - axis.y.width &&
            mouse.move.normalized.y > 0 &&
            mouse.move.normalized.y < height - axis.x.height
        );
    }

    get chartResize() {
        const { mouse } = this._config;
        const charts = Object.entries(this._chart);
        const margin = 5;
        const before = charts.find(([k, { clientRect, config }]) => {
            return mouse.move.normalized.y > clientRect.bottom - margin && mouse.move.normalized.y < clientRect.bottom + margin && config.hidden === false;
        });
        const after = charts.find(([k, { clientRect, config }]) => {
            return mouse.move.normalized.y > clientRect.top - margin && mouse.move.normalized.y < clientRect.top + margin && config.hidden === false;
        });
        return { before: before?.[0] as ChartsNames | undefined, after: after?.[0] as ChartsNames | undefined };
    }

    get inResizeChart(): boolean {
        const { before, after } = this.chartResize;
        return before !== undefined && after !== undefined;
    }

    get inAxisY(): boolean {
        const { mouse, width, height, axis } = this._config;
        return mouse.hover && mouse.move.normalized.x > width - axis.y.width && mouse.move.normalized.y > 0 && mouse.move.normalized.y < height;
    }

    get inAxisX(): boolean {
        const { mouse, width, height, axis } = this._config;
        return mouse.hover && mouse.move.normalized.y > height - axis.x.height && mouse.move.normalized.x > 0 && mouse.move.normalized.x < width - axis.y.width;
    }

    toZoom(zoomX: number, zoomY?: number) {
        zoomX = Math.min(Math.max(0.1, zoomX), 25);
        zoomY = Math.min(Math.max(0.1, zoomY ?? this._config.zoom.y), 5);

        const pos = this.normalizePointer({ x: this._config.left, y: this._config.top });

        const baseX = this._config.width - (this._config.mouse.context.x - pos.x) - this._config.axis.y.width;
        const baseY = this._config.height - (this._config.mouse.context.y - pos.y) - this._config.axis.x.height;

        this._config.scroll.x = (this._config.scroll.x - baseX) * (this._config.zoom.x / zoomX) + baseX;
        this._config.scroll.y = (this._config.scroll.y - baseY) * (this._config.zoom.y / zoomY) + baseY;

        this._config.zoom.x = zoomX;
        this._config.zoom.y = zoomY ?? this._config.zoom.y;

        return this.toScroll(this._config.scroll.x, this._config.scroll.y);
    }

    toScroll(scrollX: number, scrollY: number) {
        const { width: candles_width } = this._candles.getDimensions();

        this._config.scroll.x = Math.max(Math.min(scrollX, this.config.width * 0.7), candles_width * -1 + this.config.width * (1 - 0.7));
        this._config.scroll.y = Math.max(Math.min(scrollY, this.config.height / 2), (this.config.height / 2) * -1);

        return cloneLiteral({ scroll: this._config.scroll, zoom: this._config.zoom });
    }

    eventMousedown(e: MouseEvent) {
        this._config.mouse.eventMousedown(e, (mouse) => {
            mouse.dragging = this.inAreaChart || this.inAxisX;
            mouse.resizing = this.inResizeChart;
            this._config.resize = mouse.resizing ? (this.chartResize as any) : undefined;
            mouse.zooming = !mouse.dragging && this.inAxisY;
        });
    }

    eventMousemove(e: MouseEvent) {
        this._config.mouse.eventMousemove(e, (mouse) => {
            if (mouse.resizing) {
                this.updateDivider();
            }

            if (mouse.dragging) {
                let { x, y } = this._config.scroll;
                if (this.inAreaChart || this.inAxisX) {
                    mouse.context = mouse.move;
                    x -= e.movementX;
                }
                // y -= e.movementY;
                this.toScroll(x, y);
            }

            // if (mouse.zooming) {
            //     const difY = mouse.move.normalized.y - mouse.move.before.normalized.y;
            //     const delta = difY / this._config.height;
            //     let { x, y } = this._config.zoom;
            //     y += delta * y;
            //     this.toZoom(x);
            // }
        });
    }

    eventMouseup(e: MouseEvent) {
        this._config.mouse.eventMouseup(e);
        this._config.resize = undefined;
    }

    eventMouseover(e: MouseEvent) {
        this._config.mouse.eventMouseover(e);
    }

    eventMouseout(e: MouseEvent) {
        this._config.mouse.eventMouseout(e);
    }

    eventTouchstart(e: TouchEvent) {
        this._config.mouse.eventTouchstart(e);
    }

    eventTouchmove(e: TouchEvent) {
        this._config.mouse.eventTouchmove(e);
    }

    eventTouchend(e: TouchEvent) {
        this._config.mouse.eventTouchend(e);
    }

    eventWheel(e: WheelEvent) {
        this._config.mouse.eventWheel(e);

        this._config.mouse.context = this._config.mouse.move;
        let { x, y } = this._config.zoom;
        if (this.inAreaChart) {
            x += (e.deltaY / 1000) * x;
        }

        this.toZoom(x);
    }
}
