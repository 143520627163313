import React from "react";
import { PerformanceSummary } from "Types";
import styles from "./styles.module.scss";
import { CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { clsx, formatCurrency } from "Utils";

interface PerformanceSummaryProps {
    data?: PerformanceSummary;
    secret?: boolean;
}

export const Performance: React.FC<PerformanceSummaryProps> = ({ data, secret = false }) => {
    return (
        <div className={styles.main}>
            {!data && <CircularProgress color="inherit" sx={{ opacity: 0.4 }} />}
            {data && (
                <Grid container spacing={3} rowSpacing={1} columns={12} className={styles["info-performance"]} sx={{ flex: "1" }}>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Saldo Líquido Total
                        </Typography>
                        <Typography
                            className={clsx({
                                [styles["green"]]: data.total_net_balance > 0,
                                [styles["red"]]: data.total_net_balance < 0,
                            })}
                            variant="body2"
                            gutterBottom
                        >
                            {formatCurrency(data.total_net_balance, { symbol: "US$", show: !secret })}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Saldo Total
                        </Typography>
                        <Typography
                            className={clsx({
                                [styles["green"]]: (data.total_balance ?? 0) > 0,
                                [styles["red"]]: (data.total_balance ?? 0) < 0,
                            })}
                            variant="body2"
                            gutterBottom
                        >
                            {formatCurrency(data.total_balance, { symbol: "US$", show: !secret })}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Lucro Bruto
                        </Typography>
                        <Typography className={clsx({ [styles["green"]]: data.gross_profit > 0 })} variant="body2" gutterBottom>
                            {formatCurrency(data.gross_profit, { symbol: "US$", show: !secret })}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Prejuízo Bruto
                        </Typography>
                        <Typography className={clsx({ [styles["red"]]: data.gross_loss > 0 })} variant="body2" gutterBottom>
                            {formatCurrency(data.gross_loss, { symbol: "US$", show: !secret })}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Fator de Lucro
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {data.profit_factor}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Custos
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {formatCurrency(data.costs, { symbol: "US$", show: !secret })}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 12 }}></Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Número Total de Operações
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {data.total_number_of_trades}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Percentual de Operações Vencedoras
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {data.winning_trades_percentage}%
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Operações Vencedoras
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {data.winning_trades}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Operações Perdedoras
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {data.losing_trades}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 6 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Operações Zeradas
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {data.break_even_trades}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 12 }}></Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 12 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Média de Lucro/Prejuízo
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {formatCurrency(data.average_profit_loss, { symbol: "US$", show: !secret })}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 12 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Razão Média de Lucro:Média Prejuízo
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {data.average_profit_loss_ratio}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 12 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Média de Operações Vencedoras
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {formatCurrency(data.average_winning_trades, { symbol: "US$", show: !secret })}
                        </Typography>
                    </Grid>
                    <Grid className={styles["item"]} size={{ xs: 12, md: 12 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Média de Operações Perdedoras
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {formatCurrency(data.average_losing_trades, { symbol: "US$", show: !secret })}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};
