import React, { useEffect, useRef, useState } from "react";
import styles from "../styles.module.scss";
import { Box, Button, CircularProgress, Step, StepContent, StepLabel, Stepper, TextField, Typography, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import QRCode from "qrcode";
import { useAPI, useDialog, useWebsocket } from "Hooks";
import { MainAPI } from "Helpers";
import { Toast } from "shared-components";
import { CountdownBar, InputAmount } from "Components";
import { HistoryItem } from "Types";

interface PixemvData {
    emv: string;
    amount: number;
    id: number;
    external_id: string;
    created_date: string;
    payer: number;
    receiver: number;
    key: number;
    custom_code: string;
    processor_callback_payload: any;
}

interface Props {
    type: "deposit" | "withdraw";
}

export const MethodBRLPix: React.FC<Props> = ({ type }) => {
    const dialog = useDialog();

    const [pixemv, pixemv_status, pixemv_fetch, pixemv_error] = useAPI<PixemvData | null, "post">(MainAPI, "post", "/wallet/deposit/brl/pix", null);

    const [activeStep, setActiveStep] = useState<number>(0);
    const [qrCode, setQrCode] = useState<string>("");

    const [depositAmount, setDepositAmount] = useState<number>(0);

    const lastStage = useRef<number>(0);

    useEffect(() => {
        if (pixemv_status === "error" && pixemv_error) {
            Toast.error(pixemv_error);
            dialog.close();
        }
    }, [pixemv_status, pixemv_error]);

    useEffect(() => {
        const time = setTimeout(() => {
            if (type === "deposit" && activeStep === 1) {
                pixemv_fetch({
                    amount: depositAmount,
                });
                dialog.setExit(false);
            }

            if (type === "deposit" && activeStep != 1) {
                dialog.setExit(true);
            }

            lastStage.current = activeStep;
        }, 400);

        return () => {
            clearTimeout(time);
        };
    }, [type, depositAmount, activeStep]);

    useEffect(() => {
        if (pixemv && pixemv.emv) {
            QRCode.toDataURL(pixemv.emv, { errorCorrectionLevel: "H", width: 400 }, (err, url) => {
                if (err) {
                    console.error(err);
                    return;
                }

                setQrCode(url);
            });
        }
    }, [pixemv]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useWebsocket<HistoryItem>(
        "transaction",
        (data) => {
            if (type === "deposit" && activeStep === 1 && pixemv && pixemv.emv && data.type === "DEPOSIT" && data.method === "PIX") {
                try {
                    const reference = JSON.parse(data.details.reference);

                    if (reference.custom_code === pixemv.custom_code) {
                        handleNext();
                        Toast.success("Pagamento recebido com sucesso.");
                    }
                } catch (e) {
                    Toast.error("Ocorreu um erro internamente, por favor tente novamente mais tarde.");
                    dialog.close();
                }
            }
        },
        [type, pixemv, activeStep]
    );

    const handleFinish = () => {
        if (type === "deposit" && activeStep === 1) {
            Toast.error("O tempo para realizar o pagamento expirou. Tente novamente.");
            dialog.close();
            handleBack();
        }
    };

    return (
        <div className={styles["dialog-body"]}>
            <Box sx={{ padding: "15px", width: "100%" }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step>
                        <StepLabel>Selecionar Moeda</StepLabel>
                        <StepContent>
                            <div className={styles["content"]}>
                                <InputAmount value={depositAmount} onChange={setDepositAmount} currency={"BRL"} min={5} max={10000} />
                            </div>
                            <Box sx={{ mb: 2 }}>
                                <Button disabled={depositAmount < 5} variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                                    Continue
                                </Button>
                                <Button disabled onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                    Voltar
                                </Button>
                            </Box>
                        </StepContent>
                    </Step>
                    {type === "deposit" && (
                        <Step>
                            <StepLabel>Endereço de Depósito</StepLabel>
                            <StepContent>
                                <div className={styles["content"]}>
                                    {pixemv_status === "success" && (
                                        <>
                                            <CountdownBar
                                                duration={1000 * 60 * 10}
                                                onComplete={handleFinish}
                                                style={{
                                                    margin: "0px auto 15px",
                                                }}
                                            />
                                            <div className={styles["qrcode"]}>
                                                <img src={qrCode} alt={pixemv?.emv ?? ""} draggable="false" />
                                            </div>
                                            <TextField
                                                label="Copia e cola"
                                                value={pixemv?.emv ?? ""}
                                                size="small"
                                                fullWidth
                                                slotProps={{
                                                    input: {
                                                        readOnly: true,
                                                    },
                                                }}
                                                sx={{
                                                    margin: "0px auto 15px",
                                                }}
                                            />
                                            <div className={styles["warning"]}>
                                                Utilize o código acima para realizar o pagamento via PIX. Você pode optar por escanear o QR-Code ou copiar e colar o código.
                                            </div>
                                        </>
                                    )}

                                    {pixemv_status !== "success" && (
                                        <Box sx={{ margin: "15px 0px", textAlign: "center", opacity: 0.3 }}>
                                            <CircularProgress color="inherit" />
                                        </Box>
                                    )}
                                </div>
                            </StepContent>
                        </Step>
                    )}
                    {type === "deposit" && (
                        <Step>
                            <StepLabel>Sucesso</StepLabel>
                            <StepContent>
                                <div className={styles["content"]}>
                                    <Typography>Seu pagamento foi recebido com sucesso.</Typography>
                                </div>
                            </StepContent>
                        </Step>
                    )}
                </Stepper>
            </Box>
        </div>
    );
};
