import React, { useEffect, useState } from "react";
import styles from "../styles.module.scss";
import Icon from "@mdi/react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useAPI, useDialog } from "Hooks";
import { DialogHelper, MainAPI } from "Helpers";
import { Toast } from "shared-components";
import { clsx, mdi } from "Utils";
import { MethodCripto } from "./MethodCripto";
import { MethodBRLPix } from "./MethodBRLPix";

interface Data {
    methods: Array<{
        symbol: string;
        name: string;
        options: Array<{
            name: string;
            message: Record<string, string>;
            value: string;
            operable: string[];
            disabled: string[];
        }>;
    }>;
}

const optionsIcon: { [t: string]: string } = {
    pix: mdi.mdiPix,
    boleto: mdi.mdiFileDocument,
    transfer: mdi.mdiBankTransfer,
    cryptocurrency: mdi.mdiBitcoin,
};

interface OptionsTransactionProps {
    type: "deposit" | "withdraw";
}

const methods: {
    [key: string]: {
        [key in "deposit" | "withdraw"]?: {
            [key: string]: () => void;
        };
    };
} = {
    BRL: {
        deposit: {
            pix: () => {
                DialogHelper.open(() => <MethodBRLPix type="deposit" />, {
                    title: "Receber via Pix",
                    onClose() {},
                });
            },
        },
    },
};

export const OptionsTransaction: React.FC<OptionsTransactionProps> = ({ type }) => {
    const dialog = useDialog();
    const [currency, setCurrency] = useState<string>("BRL");
    const [data, status, fetch, messageError] = useAPI<Data, "get">(
        MainAPI,
        "get",
        "/assets/method/currencies/list",
        {
            methods: [],
        },
        true
    );

    useEffect(() => {
        if (status === "error" && messageError) {
            Toast.error(messageError);
            dialog.close();
        } else if (status === "loading" || status === "idle") {
            dialog.setLoading(true);
        } else if (status === "success") {
            dialog.setLoading(false);
        }
    }, [status, messageError]);

    if (status !== "success") {
        return <></>;
    }

    const options = data?.methods.find((method) => method.symbol === currency)?.options ?? [];

    return (
        <div className={styles["dialog-body"]}>
            <div style={{ width: "100%", padding: "0px 15px" }}>
                <FormControl size="small" fullWidth>
                    <Select
                        value={currency}
                        onChange={(e) => {
                            setCurrency(e.target.value as string);
                        }}
                        displayEmpty
                    >
                        {data?.methods.map(({ symbol, name, options }) => {
                            const disabled = options.some(({ operable }) => operable.includes(type) !== true);

                            return (
                                <MenuItem key={symbol} value={symbol} disabled={disabled}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <div
                                            className={styles["coin-icon"]}
                                            style={{
                                                backgroundImage: `url(${MainAPI.resolveUrl(`/assets/img/coins/${symbol.toLowerCase()}`)})`,
                                            }}
                                        ></div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Typography variant="h6">{symbol.toUpperCase()}</Typography>
                                            <Typography
                                                variant="caption"
                                                style={{
                                                    opacity: 0.5,
                                                }}
                                            >
                                                {name}
                                            </Typography>
                                        </div>
                                    </div>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className={styles["options"]}>
                <div className={styles["title"]}>
                    <Typography variant="subtitle1">{type === "deposit" ? "Eu não tenho criptoativos" : "Eu não quero enviar em criptoativos"}</Typography>
                </div>
                <div className={styles["items"]}>
                    {options
                        .filter(({ operable }) => {
                            return operable.includes(type);
                        })
                        .map(({ name, message, value, disabled }, i) => {
                            const isDisabled = disabled.includes(type);

                            return (
                                <div key={i} className={clsx(isDisabled ? styles["disabled"] : null)} onClick={isDisabled ? undefined : methods[currency]?.[type]?.[value]}>
                                    <div className={styles["icon"]}>
                                        <Icon path={optionsIcon[value]} />
                                    </div>
                                    <div className={styles["label"]}>
                                        <Typography variant="h6">{name}</Typography>
                                        <Typography variant="caption">{message[type]}</Typography>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className={styles["options"]}>
                <div className={styles["title"]}>
                    <Typography variant="subtitle1">{type === "deposit" ? "Eu tenho criptoativos" : "Eu quero enviar em criptoativos"}</Typography>
                </div>
                <div className={styles["items"]}>
                    <div
                        onClick={() => {
                            DialogHelper.open(<MethodCripto type={type} />, {
                                title: "Receber",
                                onClose() {},
                            });
                        }}
                    >
                        <div className={styles["icon"]}>
                            <Icon path={optionsIcon["cryptocurrency"]} />
                        </div>
                        {type === "deposit" ? (
                            <div className={styles["label"]}>
                                <Typography variant="h6">Depósito de criptomoedas</Typography>
                                <Typography variant="caption">Realize um depósito de criptomoedas para o endereço gerado e aguarde o processamento pelo sistema.</Typography>
                            </div>
                        ) : (
                            <div className={styles["label"]}>
                                <Typography variant="h6">Envio de criptomoedas</Typography>
                                <Typography variant="caption">Realize um envio de criptomoedas para o endereço informado e aguarde o processamento pelo sistema.</Typography>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
