import { SimpleEventEmitter } from "shared-components";
import { MouseEventPosition } from "./Types";
import { ChartsController } from "./Controller";
import { cloneLiteral } from "Utils";

interface EventsEmitters {
    mousedown: [MouseController];
    mousemove: [MouseController];
    mouseup: [MouseController];
    mouseover: [MouseController];
    mouseout: [MouseController];
    touchstart: [MouseController];
    touchmove: [MouseController];
    touchend: [MouseController];
    wheel: [MouseController];
}

export default class MouseController extends SimpleEventEmitter<EventsEmitters> {
    public down: MouseEventPosition & { normalized: MouseEventPosition } = {
        x: 0,
        y: 0,
        movementX: 0,
        movementY: 0,
        normalized: {
            x: 0,
            y: 0,
            movementX: 0,
            movementY: 0,
        },
    };

    public move: MouseEventPosition & { normalized: MouseEventPosition; before: MouseEventPosition & { normalized: MouseEventPosition } } = {
        x: 0,
        y: 0,
        movementX: 0,
        movementY: 0,
        normalized: {
            x: 0,
            y: 0,
            movementX: 0,
            movementY: 0,
        },
        before: {
            x: 0,
            y: 0,
            movementX: 0,
            movementY: 0,
            normalized: {
                x: 0,
                y: 0,
                movementX: 0,
                movementY: 0,
            },
        },
    };

    public up: MouseEventPosition & { normalized: MouseEventPosition } = {
        x: 0,
        y: 0,
        movementX: 0,
        movementY: 0,
        normalized: {
            x: 0,
            y: 0,
            movementX: 0,
            movementY: 0,
        },
    };

    public context: MouseEventPosition & { normalized: MouseEventPosition } = {
        x: 0,
        y: 0,
        movementX: 0,
        movementY: 0,
        normalized: {
            x: 0,
            y: 0,
            movementX: 0,
            movementY: 0,
        },
    };

    public hover: boolean = false;
    public dragging: boolean = false;
    public resizing: boolean = false;
    public zooming: boolean = false;

    constructor(readonly controller: ChartsController) {
        super();
    }

    get normalizePointer() {
        return this.controller.normalizePointer;
    }

    eventMousedown(e: MouseEvent, callback?: (e: MouseController) => void) {
        e.preventDefault();
        const pos = this.normalizePointer({ x: this.controller.config.left, y: this.controller.config.top });

        const { x, y } = this.normalizePointer({ x: e.clientX, y: e.clientY });

        const { x: movementX, y: movementY } = this.normalizePointer({ x: e.movementX, y: e.movementY });

        this.down = { x, y, movementX, movementY, normalized: { x: x - pos.x, y: y - pos.y, movementX: movementX - pos.x, movementY: movementY - pos.y } };

        callback && callback(this);
        this.emit("mousedown", this);
    }

    eventMousemove(e: MouseEvent, callback?: (e: MouseController) => void) {
        e.preventDefault();
        {
            const before = cloneLiteral({ ...this.move, before: undefined });

            const pos = this.normalizePointer({ x: this.controller.config.left, y: this.controller.config.top });

            const { x, y } = this.normalizePointer({ x: e.clientX, y: e.clientY });
            const { x: movementX, y: movementY } = this.normalizePointer({ x: e.movementX, y: e.movementY });

            this.move = { x, y, movementX, movementY, normalized: { x: x - pos.x, y: y - pos.y, movementX: movementX - pos.x, movementY: movementY - pos.y }, before };
        }

        callback && callback(this);
        this.emit("mousemove", this);
    }

    eventMouseup(e: MouseEvent, callback?: (e: MouseController) => void) {
        e.preventDefault();
        {
            const pos = this.normalizePointer({ x: this.controller.config.left, y: this.controller.config.top });

            const { x, y } = this.normalizePointer({ x: e.clientX, y: e.clientY });

            const { x: movementX, y: movementY } = this.normalizePointer({ x: e.movementX, y: e.movementY });

            this.up = { x, y, movementX, movementY, normalized: { x: x - pos.x, y: y - pos.y, movementX: movementX - pos.x, movementY: movementY - pos.y } };
        }

        this.dragging = false;
        this.resizing = false;
        this.zooming = false;

        callback && callback(this);
        this.emit("mouseup", this);
    }

    eventMouseover(e: MouseEvent, callback?: (e: MouseController) => void) {
        e.preventDefault();
        this.hover = true;
        callback && callback(this);
        this.emit("mouseover", this);
    }

    eventMouseout(e: MouseEvent, callback?: (e: MouseController) => void) {
        e.preventDefault();
        this.hover = false;
        callback && callback(this);
        this.emit("mouseout", this);
    }

    eventTouchstart(e: TouchEvent, callback?: (e: MouseController) => void) {
        e.preventDefault();
        callback && callback(this);
        this.emit("touchstart", this);
    }
    eventTouchmove(e: TouchEvent, callback?: (e: MouseController) => void) {
        e.preventDefault();
        callback && callback(this);
        this.emit("touchmove", this);
    }
    eventTouchend(e: TouchEvent, callback?: (e: MouseController) => void) {
        e.preventDefault();
        callback && callback(this);
        this.emit("touchend", this);
    }

    eventWheel(e: WheelEvent, callback?: (e: MouseController) => void) {
        e.preventDefault();
        callback && callback(this);
        this.emit("wheel", this);
    }
}
