import { DeepPartial, JSONObjLiteral, clsxType } from "Types";

export const isJson = (value: any): value is JSONObjLiteral => {
    try {
        const obj = JSON.parse(value);
        return typeof obj === "object" && obj !== null;
    } catch (e) {
        return false;
    }
};

export const clsx = (...classes: Array<string | clsxType | Record<string, clsxType>>): string => {
    return classes
        .filter((c): c is string | Record<string, clsxType> => typeof c === "string" || (typeof c === "object" && c !== null))
        .map((c) => {
            if (typeof c === "string") return c;
            return Object.keys(c as Record<string, clsxType>)
                .filter((key) => Boolean(c?.[key]))
                .join(" ");
        })
        .join(" ");
};

export const UUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const isConstructedObject = (value: any): boolean => {
    return typeof value === "object" && value !== null && value.constructor !== Object;
};

export const joinObject = <T extends Object>(obj: T, ...objs: Array<DeepPartial<T>>): T => {
    objs.forEach((o) => {
        for (let key in o) {
            if (o[key] === null || (!Array.isArray(o[key]) && isConstructedObject(o[key]))) {
                obj[key] = o[key] as any;
                continue;
            }

            if (typeof o[key] === "object") {
                obj[key] = joinObject(obj[key] as any, o[key] as any);
                continue;
            }

            obj[key] = o[key] as any;
        }
    });

    return obj;
};

export const getCSSProperty = <T extends Object>(element: HTMLElement, property: T): T => {
    const style = getComputedStyle(element);

    const prepareProperties = (properties: Object, names: string[] = []): Object => {
        return Object.keys(properties).reduce((acc, key) => {
            if (typeof (properties as any)[key] === "object") {
                acc[key] = prepareProperties((properties as any)[key], [...names, key]);
                return acc;
            }
            const value = style.getPropertyValue("--" + [...names, key].join("-"));
            acc[key] = typeof value === "string" && value.trim() !== "" ? value : (properties as any)[key];
            return acc;
        }, {} as any);
    };

    return prepareProperties(property) as T;
};

export const cloneLiteral = <T extends Object>(obj: T, ...objs: Array<DeepPartial<T>>): T => {
    return joinObject<T>(JSON.parse(JSON.stringify(obj)) as T, ...objs.map((o) => JSON.parse(JSON.stringify(o))));
};

export const measureText = (ctx: CanvasRenderingContext2D, text: string, font?: string) => {
    ctx.save();
    if (font) ctx.font = font;
    const measure = ctx.measureText(text);
    const height = measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent;
    ctx.restore();
    return { width: measure.width, height };
};

export type FourSidedValue = number | [number, number] | [number, number, number] | [number, number, number, number];

export const expandToFourValues = (value: FourSidedValue): [number, number, number, number] => {
    if (typeof value === "number") return [value, value, value, value];
    if (value.length === 2) return [value[0], value[1], value[0], value[1]];
    if (value.length === 3) return [value[0], value[1], value[2], value[1]];
    return value as [number, number, number, number];
};

export const getFontDetails = (
    font: string,
    props: Partial<{
        fontStyle: string;
        fontVariant: "normal" | "small-caps";
        fontWeight: "normal" | "bold" | "bolder" | "lighter" | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
        fontSize: number;
        lineHeight: string;
        fontFamily: string;
    }> = {}
) => {
    let fontFamily = null,
        fontSize = null,
        fontStyle = "normal",
        fontWeight = "normal",
        fontVariant = "normal",
        lineHeight = "normal";

    let elements = font.split(/\s+/),
        current: string | undefined;
    outer: while ((current = elements.shift())) {
        switch (current) {
            case "normal":
                break;

            case "italic":
            case "oblique":
                fontStyle = current;
                break;

            case "small-caps":
                fontVariant = current;
                break;

            case "bold":
            case "bolder":
            case "lighter":
            case "100":
            case "200":
            case "300":
            case "400":
            case "500":
            case "600":
            case "700":
            case "800":
            case "900":
                fontWeight = current;
                break;

            default:
                if (!fontSize) {
                    var parts = current.split("/");
                    fontSize = parts[0];
                    if (parts.length > 1) lineHeight = parts[1];
                    break;
                }

                fontFamily = current;
                if (elements.length) fontFamily += " " + elements.join(" ");
                break outer;
        }
    }

    const options = {
        fontStyle: props?.fontStyle ?? fontStyle,
        fontVariant: props?.fontVariant ?? fontVariant,
        fontWeight: props?.fontWeight ?? fontWeight,
        fontSize: props?.fontSize ?? parseInt((fontSize?.match(/(\d+)px/) ?? "16")[1], 10),
        lineHeight: props?.lineHeight ?? lineHeight,
        fontFamily: props?.fontFamily ?? fontFamily ?? "sans-serif",
    };

    return {
        font: [options.fontStyle, options.fontVariant, options.fontWeight, `${options.fontSize}px`, options.lineHeight, options.fontFamily].filter((v) => v && v !== "normal").join(" "),
        ...options,
    };
};

export const drawTextWithBackground = (
    ctx: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    options: Partial<{
        padding: FourSidedValue;
        borderRadius: FourSidedValue;
        textAlign: "left" | "center" | "right";
        baseline: "top" | "middle" | "bottom";
        font: string;
        fontStyle: string;
        fontVariant: "normal" | "small-caps";
        fontWeight: "normal" | "bold" | "bolder" | "lighter" | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
        fontSize: number;
        lineHeight: string;
        fontFamily: string;
        stroke: string;
        fill: string;
        strokeWidth: number;
        color: string;
    }> = {}
) => {
    const {
        padding = 0,
        borderRadius = 0,
        font,
        stroke = "transparent",
        fill,
        strokeWidth,
        color,
        fontSize,
        fontFamily,
        fontStyle,
        fontVariant,
        fontWeight,
        lineHeight,
        textAlign,
        baseline,
    } = options;
    ctx.save();
    if (font) ctx.font = font; // Fonte do texto
    ctx.font = getFontDetails(ctx.font, { fontSize, fontFamily, fontStyle, fontVariant, fontWeight, lineHeight }).font;

    // Configura fonte e mede o texto
    const { width: textWidth, height: textHeight } = measureText(ctx, text);

    const [paddingTop, paddingRight, paddingBottom, paddingLeft] = expandToFourValues(padding);
    const [borderTopLeft, borderTopRight, borderBottomRight, borderBottomLeft] = expandToFourValues(borderRadius);

    // Calcula dimensões do retângulo com padding
    const rectWidth = textWidth + paddingLeft + paddingRight;
    const rectHeight = textHeight + paddingTop + paddingBottom;

    // Ajusta a posição do texto com textAlign e baseline
    if (textAlign) {
        if (textAlign === "center") x -= rectWidth / 2;
        if (textAlign === "right") x -= rectWidth;
    }

    if (baseline) {
        if (baseline === "middle") y -= rectHeight / 2;
        if (baseline === "bottom") y -= rectHeight;
    }

    // Começa o desenho do retângulo com borderRadius individual
    ctx.beginPath();

    // Ponto inicial (esquerda superior)
    ctx.moveTo(x + borderTopLeft, y);

    // Linha superior
    ctx.lineTo(x + rectWidth - borderTopRight, y);
    ctx.quadraticCurveTo(x + rectWidth, y, x + rectWidth, y + borderTopRight);

    // Linha direita
    ctx.lineTo(x + rectWidth, y + rectHeight - borderBottomRight);
    ctx.quadraticCurveTo(x + rectWidth, y + rectHeight, x + rectWidth - borderBottomRight, y + rectHeight);

    // Linha inferior
    ctx.lineTo(x + borderBottomLeft, y + rectHeight);
    ctx.quadraticCurveTo(x, y + rectHeight, x, y + rectHeight - borderBottomLeft);

    // Linha esquerda
    ctx.lineTo(x, y + borderTopLeft);
    ctx.quadraticCurveTo(x, y, x + borderTopLeft, y);

    ctx.closePath();

    // Configura o estilo do retângulo e desenha
    if (stroke) ctx.strokeStyle = stroke; // Cor da borda do retângulo
    if (typeof strokeWidth === "number") ctx.lineWidth = strokeWidth; // Largura da borda do retângulo
    ctx.stroke();
    if (fill) ctx.fillStyle = fill; // Cor de fundo do retângulo
    ctx.fill();

    // Desenha o texto dentro do retângulo
    if (color) ctx.fillStyle = color; // Cor do texto
    ctx.fillText(text, x + paddingLeft, y + paddingTop + textHeight); // Ajusta a posição do texto com padding
    ctx.restore();

    return {
        x,
        y,
        width: rectWidth,
        height: rectHeight,
        top: y,
        left: x,
        right: x + rectWidth,
        bottom: y + rectHeight,
    };
};
