import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { GridItem } from "./GridItem";
import { ForwardWidgetsProps, GridItemProps, GridItemRef, GridLayoutProps, LayoutOptions, LayoutOptionsNormalized, LayoutOpts, onResizeBy } from "./Types";
import { useSizeEffect } from "shared-components";
import { getMinWidth, normalizeLayoutItem, resolveRow, updateLayout, HandleError, adjustColsBy, getHeigthByLine } from "./Utils";

import "./style.scss";
import { clsx } from "Utils";

export * from "./forwardWidgets";

export type { LayoutOptions };

const PropsContext = createContext<Record<string, any>>({});

export const GridLayout = <C extends Record<string, React.FC>, K extends keyof C = keyof C>({
    components,
    layout,
    cols = 12,
    rows = 6,
    margin = 15,
    isBounded = false,
    isDraggable = true,
    isResizable = true,
    createItem: _createItem,
    onError,
    onLayoutChange,
    className,
    props: _props = {},
}: GridLayoutProps<C, K>) => {
    const [_, forceUpdate] = useState<number>(Date.now());
    const [props, setProps] = useState(_props);
    const mainRef = React.useRef<HTMLDivElement | null>(null);
    const layoutRef = React.useRef<LayoutOptionsNormalized<K>[]>([]);
    const itemsRef = React.useRef<Map<string, GridItemRef<C, K> | undefined>>(new Map());
    const childrenRef = React.useRef<Map<string, React.ReactElement<any> | undefined>>(new Map());
    const linesRef = React.useRef<Map<string, HTMLDivElement>>(new Map());

    const [marginY, marginX] = Array.isArray(margin) ? margin : [margin, margin];
    const createItem: Partial<{
        horizontal: LayoutOpts<K>;
        vertical: LayoutOpts<K>;
    }> = {
        horizontal: _createItem ? (_createItem as any).horizontal ?? (!(_createItem as any).vertical ? _createItem : undefined) : undefined,
        vertical: _createItem ? (_createItem as any).vertical ?? (!(_createItem as any).horizontal ? _createItem : undefined) : undefined,
    };

    const insertableVertical = createItem.vertical !== undefined;
    const insertableHorizontal = createItem.horizontal !== undefined;

    const onUpdateLayout = () => {
        onLayoutChange?.(layoutRef.current);
    };

    useEffect(() => {
        const time = setTimeout(() => {
            // childrenRef.current.clear();
            setProps(_props);
        }, 1000);

        return () => clearTimeout(time);
    }, [_props]);

    useEffect(() => {
        if (JSON.stringify(layout) === JSON.stringify(layoutRef.current)) {
            return;
        }

        layoutRef.current = updateLayout(
            layout.map((layout) => {
                if (!(layout.i in components)) {
                    return layout;
                }
                const layoutComponent = components[layout.i];
                return "isWidget" in layoutComponent && "defaultConfig" in layoutComponent ? { ...(layoutComponent as any).defaultConfig, ...layout } : layout;
            }),
            {
                cols,
                isBounded,
                isDraggable,
                isResizable,
            }
        );

        forceUpdate(Date.now());
        onUpdateLayout();
    }, [layout, cols, isBounded, isDraggable, isResizable]);

    const forceUpdateItems = useCallback(() => {
        const { height = 0 } = mainRef.current?.getBoundingClientRect() ?? {};

        layoutRef.current.forEach(({ uuid, ...p }) => {
            itemsRef.current.get(uuid)?.updateProps?.({ uuid, ...p, clientHeigth: height, isBounded, isDraggable, isResizable });
        });

        linesRef.current.forEach((line, uuid) => {
            const props = layoutRef.current.find((item) => item.rowUuid === uuid);
            if (!props) return;
            line.style.height = `calc(${((getHeigthByLine(layoutRef.current, props?.r ?? 0) / rows) * 100).toFixed(2)}% - ${marginY}px)`;
        });
    }, []);

    const resizeRef = useSizeEffect(() => {
        forceUpdateItems();
    }, [forceUpdateItems]);

    const onInsertionNew: onResizeBy = useCallback(({ id, uuid, column: c, row: r, width: w, height: h, isHorizontal, isStart, isEnd }) => {
        if (isHorizontal) {
            return;
        }

        console.log(getMinWidth(layoutRef.current, r));

        onError?.(new HandleError("", "INSERTION-WIDTH-EXCEEDED"));
    }, []);

    const onResizeBy: onResizeBy = useCallback(
        ({ id, uuid, column: c, row: r, width: w, height: h, isHorizontal, isStart, isEnd }) => {
            const minHeigth = layoutRef.current.reduce((acc, item) => (item.r === r ? Math.max(acc, item.minH) : acc), 0);
            const maxHeigth = layoutRef.current.reduce((acc, item) => (item.r === r ? Math.max(acc, item.maxH) : acc), Infinity);
            const line = layoutRef.current.filter((item) => item.r === r);

            let stop: () => void = () => {};

            new Promise<void>(async (resolve) => {
                let mousemove: (e: MouseEvent) => void = () => {};
                let mouseup: () => void = () => {};
                const beforeCursor = document.body.style.cursor;
                const beforeUserSelect = document.body.style.userSelect;

                await new Promise<void>((resolve) => {
                    document.body.style.cursor = isHorizontal ? "row-resize" : "col-resize";
                    document.body.style.userSelect = "none";

                    mousemove = (e: MouseEvent) => {
                        e.preventDefault();
                        const currentItem = layoutRef.current.find((item) => item.uuid === uuid);
                        const { width = 0, height = 0 } = mainRef.current?.getBoundingClientRect() ?? {};
                        const movementX = e.movementX / (width / cols);
                        const movementY = e.movementY / (height / rows);

                        if (!isHorizontal && isStart) {
                        } else if (!isHorizontal && isEnd) {
                        } else if (!isHorizontal) {
                            if (currentItem) {
                                layoutRef.current = resolveRow(layoutRef.current, { byItem: { ...currentItem, w: currentItem.w + movementX }, cols, removeItens: false });
                            }
                        } else if (isHorizontal && isEnd) {
                            const rowHeight = layoutRef.current.reduce((acc, item) => (item.r === r ? Math.max(acc, item.h) : acc), 0) + movementY;
                            layoutRef.current = layoutRef.current.map((item, i, self) => {
                                if (item.r === r) {
                                    item.h = Math.max(minHeigth, Math.min(rowHeight, item.maxH, maxHeigth));
                                }
                                return item;
                            });
                        }

                        forceUpdateItems();
                    };

                    stop = mouseup = () => {
                        resolve();
                    };

                    document.addEventListener("mousemove", mousemove);
                    document.addEventListener("mouseup", mouseup);
                    document.addEventListener("mouseleave", mouseup);
                });

                if (!isHorizontal) {
                    layoutRef.current = layoutRef.current.map((item, i, self) => {
                        item.c = Math.round(item.c);
                        item.w = Math.round(item.w);
                        item.isRemoved = item.c < 0 || item.c + item.minW > cols;
                        return item;
                    });

                    line.forEach((item, i, self) => {
                        const index = layoutRef.current.findIndex((a) => a.uuid === item.uuid);
                        layoutRef.current[index].c = i == 0 ? layoutRef.current[index].c : self[i - 1].c + self[i - 1].w;

                        if (i === self.length - 1) {
                            layoutRef.current[index].w = cols - layoutRef.current[index].c;
                        }
                    });
                } else if (isHorizontal && isEnd) {
                    layoutRef.current = layoutRef.current.map((item, i, self) => {
                        if (item.r === r) item.h = Math.min(maxHeigth, Math.max(minHeigth, Math.round(item.h)));
                        return item;
                    });
                }

                document.body.style.cursor = beforeCursor;
                document.body.style.userSelect = beforeUserSelect;
                document.removeEventListener("mousemove", mousemove);
                document.removeEventListener("mouseup", mouseup);
                document.removeEventListener("mouseleave", mouseup);

                forceUpdateItems();

                if (layoutRef.current.findIndex((item) => item.isRemoved) >= 0) {
                    layoutRef.current
                        .filter((item) => item.isRemoved)
                        .forEach((item) => {
                            itemsRef.current.delete(item.uuid);
                            childrenRef.current.delete(item.uuid);

                            const line = layoutRef.current.filter((i) => i.rowUuid === item.rowUuid && !i.isRemoved);
                            const columns: { c: number; w: number }[] = [];

                            line.map((item, i, self) => {
                                item.c = i === 0 ? 0 : columns[i - 1].c + columns[i - 1].w;
                                columns.push({ c: item.c, w: item.w });
                                item.isStart = i === 0;
                                item.isEnd = i === self.length - 1;
                                return item;
                            }).forEach((item) => {
                                const index = layoutRef.current.findIndex((a) => a.uuid === item.uuid);
                                layoutRef.current[index] = item;
                            });
                        });
                    layoutRef.current = layoutRef.current.filter((item) => !item.isRemoved);
                    const line = layoutRef.current.filter((i) => i.r === r && !i.isRemoved);
                    layoutRef.current = adjustColsBy(layoutRef.current, r, line.length - 1, cols).layout;
                    forceUpdate(Date.now());
                }

                onUpdateLayout();
                resolve();
            });
        },
        [forceUpdateItems]
    );

    const processGridItem = (l: LayoutOptionsNormalized<K>, isDroppingItem: boolean = false): React.ReactElement<GridItemProps<C, K>> | undefined => {
        if (!(l.i in components)) return;
        const { height = 0 } = mainRef.current?.getBoundingClientRect() ?? {};

        if (!childrenRef.current.has(l.uuid)) {
            const Component: React.FC = () => {
                const props = useContext(PropsContext);

                const updateProp = (prop: string) => (value: any) => {
                    layoutRef.current = layoutRef.current.map((item) => {
                        if (item.uuid === l.uuid) {
                            (item as any)[prop] = value;
                        }
                        return item;
                    });
                    onUpdateLayout();
                };

                const Widget: React.FC<ForwardWidgetsProps> = components[l.i];
                return <Widget {...props} key={l.uuid} state={l.state} updateState={updateProp("state")} config={l.config} updateConfig={updateProp("config")} />;
            };
            childrenRef.current.set(l.uuid, <Component key={l.uuid} />);
        }

        return (
            <GridItem
                key={l.uuid}
                component={childrenRef.current.get(l.uuid) as any}
                ref={(e) => {
                    if (e) {
                        itemsRef.current.set(l.uuid, e as any);
                    } else {
                        itemsRef.current.delete(l.uuid);
                    }
                    forceUpdateItems();
                }}
                clientHeigth={height}
                cols={cols}
                rows={rows}
                margin={Array.isArray(margin) ? margin : [margin, margin]}
                {...(l as any)}
                onResize={(p) => onResizeBy({ ...p, isHorizontal: false })}
                onInsertionNew={(p) => onInsertionNew({ ...p, isHorizontal: false })}
                insertable={insertableHorizontal}
            />
        );
    };

    return (
        <PropsContext.Provider value={props}>
            <div
                ref={(element) => {
                    resizeRef(element);
                    mainRef.current = element;
                }}
                className={clsx("react-grid-main", className)}
                style={{
                    padding: `${marginY}px 0px 0px 0px`,
                }}
            >
                {layoutRef.current
                    .map((p) => {
                        return processGridItem(p);
                    })
                    .reduce((c, item) => {
                        if (!item) return c;
                        const child = React.Children.only(item);
                        if (!child || !child.props || typeof child.props.r !== "number") return c;
                        const r = itemsRef.current.get(String(child.props.uuid))?.getProp("r") ?? child.props.r;
                        c[r] = [...(c[r] ?? []), item];
                        return c;
                    }, [] as React.ReactElement<any>[][])
                    .filter((r) => Array.isArray(r) && r.length)
                    .map((c, i) => {
                        const child = React.Children.only(c[c.length - 1]);
                        if (!child || !child.props) return c;

                        const uuid = String(child.props.uuid);
                        const props = layoutRef.current.find((item) => item.uuid === uuid);

                        return (
                            <React.Fragment key={"row-" + (props?.rowUuid ?? i)}>
                                <div
                                    ref={(e) => {
                                        if (!props?.rowUuid) {
                                            return;
                                        }
                                        if (e) {
                                            linesRef.current.set(props.rowUuid, e as any);
                                        } else {
                                            linesRef.current.delete(props.rowUuid);
                                        }
                                    }}
                                    className={clsx("react-grid-row")}
                                    style={{
                                        height: `calc(${((getHeigthByLine(layoutRef.current, props?.r ?? 0) / rows) * 100).toFixed(2)}% - ${marginY}px)`,
                                    }}
                                    key={"row-items-" + (props?.rowUuid ?? i)}
                                >
                                    {c}
                                </div>
                                <div
                                    className="react-grid-x-resize-handle"
                                    onDoubleClick={
                                        insertableVertical
                                            ? () => {
                                                  if (!createItem.vertical) {
                                                      return;
                                                  }
                                                  const currentIndex = layoutRef.current.findIndex((item) => item.uuid === uuid) + 1;
                                                  const left = layoutRef.current.slice(0, currentIndex);
                                                  const right = layoutRef.current.slice(currentIndex);

                                                  const item = normalizeLayoutItem(
                                                      { ...createItem.vertical, c: 0, r: left.length > 0 ? left[left.length - 1].r + 1 : 0, w: cols },
                                                      { isBounded, isDraggable, isResizable }
                                                  );

                                                  const layout = [
                                                      ...left,
                                                      item,
                                                      ...right.map((i) => {
                                                          i.r += 1;
                                                          return i;
                                                      }),
                                                  ];

                                                  layoutRef.current = updateLayout(layout, {
                                                      cols,
                                                      isBounded,
                                                      isDraggable,
                                                      isResizable,
                                                  });

                                                  forceUpdateItems();
                                                  forceUpdate(Date.now());
                                              }
                                            : undefined
                                    }
                                    onMouseDown={() => {
                                        const { i: id, c: column, r: row, w: width, h: height } = layoutRef.current.find((item) => item.uuid === uuid) ?? ({} as any);
                                        if (id === undefined) return;
                                        onResizeBy({ isHorizontal: true, isStart: false, isEnd: true, id, uuid, column, row, width, height });
                                    }}
                                    style={{
                                        height: `${marginX}px`,
                                    }}
                                />
                            </React.Fragment>
                        );
                    })}
            </div>
        </PropsContext.Provider>
    );
};
