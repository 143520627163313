import React, { useEffect } from "react";
import { clsx } from "Utils";

import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

import styles from "./styles.module.scss";
import { useSizeEffect } from "shared-components";

interface TabsProps<I extends string = string> {
    tabs: Array<{ title: string; id: I; color?: string }>;
    currentTab?: I;
    setCurrentTab?: (tab: string) => void;
    className?: string;
    style?: React.CSSProperties;
}

const smooth = (from: number, to: number, effect: (current: number) => void, duration: number = 250) => {
    let t: number;
    const start = Date.now();
    const loop = () => {
        const now = Date.now();
        const progress = Math.min(1, (now - start) / duration);
        effect(from + (to - from) * progress);

        if (progress < 1) {
            t = requestAnimationFrame(loop);
        }
    };
    loop();
    return () => {
        cancelAnimationFrame(t);
    };
};

export const Tabs = <I extends string = string>({ tabs, currentTab, setCurrentTab, className, style }: TabsProps<I>) => {
    const [current, setCurrent] = React.useState<I | undefined>(currentTab);
    const mainRef = React.useRef<HTMLDivElement>(null);
    const underlineRef = React.useRef<HTMLDivElement>(null);
    const scrollLeftRef = React.useRef<HTMLDivElement>(null);
    const scrollRightRef = React.useRef<HTMLDivElement>(null);
    const scrollRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        setCurrent(currentTab);
    }, [currentTab]);

    const updateUnderline = React.useCallback(() => {
        if (mainRef.current && underlineRef.current) {
            const main = mainRef.current;
            const underline = underlineRef.current;
            const mainRec = main.getBoundingClientRect();

            const active = main.querySelector(`.${styles["tabs"]} > div >.${styles["active"]}`) as HTMLElement;

            const padding = 10;

            if (active) {
                const { left, width } = active.getBoundingClientRect();
                underline.style.width = `${width + padding + padding}px`;
                underline.style.left = `${left - mainRec.left - padding}px`;
            } else {
                underline.style.width = "";
                underline.style.left = "";
            }
        }
    }, []);

    const updateScroll = React.useCallback(() => {
        if (scrollRef.current && scrollLeftRef.current && scrollRightRef.current) {
            const scroll = scrollRef.current;
            const scrollLeft = scrollLeftRef.current;
            const scrollRight = scrollRightRef.current;

            if (scroll.scrollLeft === 0) {
                scrollLeft.style.display = "none";
            } else {
                scrollLeft.style.display = "";
            }

            if (scroll.scrollLeft + scroll.clientWidth >= scroll.scrollWidth) {
                scrollRight.style.display = "none";
            } else {
                scrollRight.style.display = "";
            }
        }
    }, []);

    const toActiveScroll = React.useCallback(() => {
        if (scrollRef.current) {
            const scroll = scrollRef.current;
            const active = scroll.querySelector(`.${styles["active"]}`) as HTMLElement;

            if (active) {
                const scrollLeft = active.offsetLeft - scroll.clientWidth / 2 + active.clientWidth / 2;

                smooth(scroll.scrollLeft, Math.min(Math.max(0, scrollLeft), scroll.scrollWidth - scroll.clientWidth), (current) => {
                    scroll.scrollLeft = current;
                    updateScroll();
                    updateUnderline();
                });
            }
        }
    }, []);

    const containerRef = useSizeEffect(() => {
        updateScroll();
        updateUnderline();
    }, []);

    useEffect(() => {
        const resize = () => {
            updateScroll();
            updateUnderline();
        };

        resize();
        toActiveScroll();

        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, [current]);

    const handleTabClick = (tab: I) => () => {
        setCurrent(tab);
        setCurrentTab?.(tab);
    };

    const handleScroll = (direction: number) => () => {
        if (scrollRef.current) {
            const scroll = scrollRef.current;
            scroll.scrollTo({
                left: scroll.scrollLeft + scroll.clientWidth * 0.7 * direction,
                behavior: "smooth",
            });

            smooth(scroll.scrollLeft, scroll.scrollLeft + scroll.clientWidth * 0.7 * direction, (current) => {
                scroll.scrollLeft = current;
                updateScroll();
                updateUnderline();
            });
        }
    };

    return (
        <div className={clsx(styles["main"], className)} ref={mainRef} style={style}>
            <div className={styles["underline"]} ref={underlineRef}></div>
            <div className={styles["scroll-button-left"]} ref={scrollLeftRef} onClick={handleScroll(-1)}>
                <Icon path={mdiChevronLeft} />
            </div>
            <div className={styles["tabs"]}>
                <div
                    ref={(el) => {
                        (scrollRef as any).current = el;
                        containerRef(el);
                    }}
                >
                    {tabs.map(({ id, title }) => (
                        <div key={id} className={clsx(styles["items"], id === currentTab && styles["active"])} onClick={handleTabClick(id)}>
                            {title}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles["scroll-button-right"]} ref={scrollRightRef} onClick={handleScroll(1)}>
                <Icon path={mdiChevronRight} />
            </div>
        </div>
    );
};
