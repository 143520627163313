import React, { forwardRef, useImperativeHandle, useEffect, useRef } from "react";
import { useRect } from "shared-components";
import { clsx } from "Utils";

interface Props {
    style?: React.CSSProperties;
    className?: string;
    classesNames?: {
        root?: string;
        underline?: string;
    };
    styles?: {
        root?: React.CSSProperties;
        underline?: React.CSSProperties;
    };
    children?: React.ReactNode;
}

export interface UnderlineRef {
    container: HTMLDivElement | null;
    updateUnderline: (active: HTMLElement) => void;
}

export const Underline = forwardRef<UnderlineRef, Props>(({ children, className, style = {}, styles = {}, classesNames = {} }, ref) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const underlineRef = useRef<HTMLDivElement>(null);
    const selectChildrenRef = useRef<HTMLElement>(null);
    const { rect: currentRect, ref: currentRef } = useRect();

    const updateUnderline = React.useCallback((active: HTMLElement) => {
        if (mainRef.current && underlineRef.current) {
            const main = mainRef.current;
            const underline = underlineRef.current;
            const mainRec = main.getBoundingClientRect();

            const padding = getComputedStyle(underlineRef.current).paddingLeft ? parseFloat(getComputedStyle(underlineRef.current).paddingLeft) : 0;

            if (active) {
                const { left, width } = active.getBoundingClientRect();
                underline.style.width = `${width + padding + padding}px`;
                underline.style.left = `${left - mainRec.left - padding}px`;
            } else {
                underline.style.width = "";
                underline.style.left = "";
            }
        }
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            container: mainRef.current,
            updateUnderline(active: HTMLElement) {
                (selectChildrenRef as any).current = active;
                currentRef(active);
                updateUnderline(active);
            },
        }),
        []
    );

    useEffect(() => {
        if (selectChildrenRef.current) {
            updateUnderline(selectChildrenRef.current);
        }
    }, [currentRect]);

    return (
        <div ref={mainRef} className={clsx(className, classesNames.root)} style={{ ...style, ...(styles.root ?? {}) }}>
            <div ref={underlineRef} className={clsx(classesNames.underline)} style={{ position: "absolute", transition: "left 0.15s ease, width 0.15s ease", ...(styles.underline ?? {}) }}></div>
            {children}
        </div>
    );
});
