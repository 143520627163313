import React, { useEffect, useRef } from "react";
import { useCandles, useChartsController } from "./useHooks";
import { cloneLiteral } from "Utils";
import { CandleData } from "Types";

interface Props {
    style?: React.CSSProperties;
    colors?: Partial<{
        background: string;
        text: string;
        grid: string;
        candle: {
            up: string;
            down: string;
        };
    }>;
    data: CandleData[];
}

const candlesData: Array<CandleData> = ((window as any)._candlesData = []);

let time: NodeJS.Timeout | undefined;

const getTrade = () => {
    clearTimeout(time);
    return new Promise<typeof candlesData>((result, reject) => {
        if (candlesData.length > 0) {
            result(candlesData);
            return;
        }

        time = setTimeout(() => {
            fetch("/Binance_BTCUSDT_d.csv")
                .then((r) => r.text())
                .then((r: string) => {
                    const rows = r.split("\n");
                    let keys = rows[1].split(",");

                    rows.forEach((l, i) => {
                        if (i <= 1) {
                            return;
                        }
                        const columns = l.split(",");
                        if (columns.length !== keys.length) {
                            return;
                        }

                        let n = {
                            date: new Date(/ [0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(columns[1]) ? columns[1] : columns[1] + " 00:00:00"),
                            open: Number(columns[3]),
                            high: Number(columns[4]),
                            low: Number(columns[5]),
                            close: Number(columns[6]),
                            volume: Number(columns[7]),
                        };

                        candlesData.push(n);
                    });

                    result(candlesData);
                })
                .catch(reject);
        }, 1000);
    });
};

export const CandlestickChart: React.FC<Props> = ({ style = {}, colors = {}, data = [] }) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const candlesticks = useChartsController();

    const candles = useCandles((candles) => {
        candlesticks.loadCandles(candles);
    }, []);

    useEffect(() => {
        // getTrade().then((candlesData) => {
        //     candles.loadData(candlesData);
        // });
        candles.loadData(cloneLiteral(data));
    }, [data]);

    useEffect(() => {
        if (!rootRef.current) {
            return;
        }

        const { eventMousedown, eventMousemove, eventMouseup, eventWheel, eventMouseover, eventMouseout } = candlesticks;

        rootRef.current.addEventListener("mousedown", eventMousedown);
        document.addEventListener("mousemove", eventMousemove);
        document.addEventListener("mouseup", eventMouseup);
        document.addEventListener("mouseleave", eventMouseup);
        rootRef.current.addEventListener("mouseover", eventMouseover);
        rootRef.current.addEventListener("mouseout", eventMouseout);
        rootRef.current.addEventListener("wheel", eventWheel);

        return () => {
            rootRef.current?.removeEventListener("mousedown", eventMousedown);
            document.removeEventListener("mousemove", eventMousemove);
            document.removeEventListener("mouseup", eventMouseup);
            document.removeEventListener("mouseleave", eventMouseup);
            rootRef.current?.removeEventListener("mouseover", eventMouseover);
            rootRef.current?.removeEventListener("mouseout", eventMouseout);
            rootRef.current?.removeEventListener("wheel", eventWheel);
        };
    }, [rootRef.current]);

    return (
        <div
            ref={rootRef}
            style={
                {
                    width: "100%",
                    height: "100%",
                    "--colors-text": colors.text,
                    "--colors-background": colors.background,
                    "--colors-grid": colors.grid,
                    "--colors-candle-up": colors.candle?.up,
                    "--colors-candle-down": colors.candle?.down,
                    ...style,
                    overflow: "hidden",
                } as React.CSSProperties
            }
        >
            <canvas
                ref={candlesticks.ref}
                style={{
                    width: "0px",
                    height: "0px",
                    minWidth: "100%",
                    minHeight: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                }}
            />
        </div>
    );
};
