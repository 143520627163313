import { SimpleEventEmitter, SimpleEventHandler } from "shared-components";

interface CandlesProps {
    open: number;
    close: number;
    high: number;
    low: number;
    volume?: number;
    date: Date | string | number;
}

interface CandleItemProps {
    length: number;
    index: number;
    x: number;
    y: number;
    width: number;
    height: number;
    padding: [number, number, number, number];
    candleBodyWidth: number;
    candleShadowWidth: number;
    candleSpace: number;
    minPrice: number;
    maxPrice: number;
}

export interface CandleItemClient {
    color: "red" | "green";
    columnSize: number;
    space: number;
    shadow: {
        left: number;
        right: number;
        top: number;
        bottom: number;
        width: number;
        height: number;
        center: number;
    };
    body: {
        left: number;
        right: number;
        top: number;
        bottom: number;
        width: number;
        height: number;
        center: number;
    };
}

export class CandleItem {
    readonly index: number;
    readonly open: number;
    readonly close: number;
    readonly high: number;
    readonly low: number;
    readonly volume: number;
    readonly date: Date;
    readonly color: "red" | "green";
    private config: CandleItemProps = {
        length: 0,
        index: 0,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        padding: [0, 0, 0, 0],
        candleBodyWidth: 20,
        candleShadowWidth: 1,
        candleSpace: 20,
        minPrice: 0,
        maxPrice: 1,
    };

    constructor({ open, close, high, low, volume, date }: CandlesProps, config?: Partial<CandleItemProps>) {
        this.open = open;
        this.close = close;
        this.high = high;
        this.low = low;
        this.volume = volume ?? 0;
        this.date = new Date(date);
        this.config = { ...this.config, ...config };
        this.color = close > open ? "green" : "red";
        this.index = this.config.index;
    }

    clone(config?: Partial<CandleItemProps>): CandleItem {
        return new CandleItem(
            {
                open: this.open,
                close: this.close,
                high: this.high,
                low: this.low,
                date: this.date,
                volume: this.volume ?? 0,
            },
            { ...this.config, ...config }
        );
    }

    get client(): CandleItemClient {
        const { index, length, candleBodyWidth, candleShadowWidth, candleSpace, width, height: h, x, y, minPrice, maxPrice, padding } = this.config;
        const [paddingTop, paddingRight, paddingBottom, paddingLeft] = padding;
        const height = h - paddingTop - paddingBottom;

        const getPercent = (value: number) => (value - minPrice) / (maxPrice - minPrice);

        const body_left = index * (candleBodyWidth + candleSpace) - x;
        const body_right = body_left + candleBodyWidth;

        const body_top = height - (getPercent(Math.max(this.open, this.close)) * height - y) + paddingTop;
        const body_bottom = height - (getPercent(Math.min(this.open, this.close)) * height - y) + paddingTop;

        const shadow_left = body_left + candleBodyWidth / 2 - candleShadowWidth / 2;
        const shadow_right = shadow_left + candleShadowWidth;

        const shadow_top = height - (getPercent(this.high) * height - y) + paddingTop;
        const shadow_bottom = height - (getPercent(this.low) * height - y) + paddingTop;

        const center = body_left + candleBodyWidth / 2;

        return {
            color: this.color,
            columnSize: candleBodyWidth + candleSpace,
            space: candleSpace / 2,
            shadow: {
                left: shadow_left,
                right: shadow_right,
                top: shadow_top,
                bottom: shadow_bottom,
                width: candleShadowWidth,
                height: shadow_bottom - shadow_top,
                center,
            },
            body: {
                left: body_left,
                right: body_right,
                top: body_top,
                bottom: body_bottom,
                width: candleBodyWidth,
                height: body_bottom - body_top,
                center,
            },
        };
    }
}

interface EventsEmitters {
    mutation: [Candles];
}

interface ViewConfig {
    x: number;
    y: number;
    width: number;
    height: number;
    zoom: { x: number; y: number };
    padding: [number, number, number, number];
}

export class Candles extends SimpleEventEmitter<EventsEmitters> {
    private _candles: CandleItem[] = [];
    private _view_config: ViewConfig = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        zoom: {
            x: 1,
            y: 1,
        },
        padding: [0, 0, 0, 0],
    };
    public bodyWidth: number = 20;
    public shadowWidth: number = 1;
    public space: number = 5;
    public maxPrice: number = 0;
    public minPrice: number = 0;

    constructor(data: CandlesProps[] = []) {
        super();
        this.loadData(data);
    }

    get viewConfig() {
        return this._view_config;
    }

    set viewConfig(config: Partial<ViewConfig>) {
        this._view_config = { ...this._view_config, ...config };
    }

    get candles() {
        return this._candles;
    }

    loadData(data: CandlesProps[]) {
        data.forEach((candle, index, self) => {
            this.push(candle.open, candle.close, candle.high, candle.low, candle.date, candle.volume, index === self.length - 1);
        });
    }

    push(open: number, close: number, high: number, low: number, date: Date | string | number, volume: number = 0, force = true) {
        this._candles.unshift(new CandleItem({ open, close, high, low, volume, date }));
        if (force) {
            this._candles = this._candles
                .filter(({ date }, i, list) => {
                    return list.findIndex((candle) => candle.date.getTime() === date.getTime()) === i;
                })
                .sort((a, b) => a.date.getTime() - b.date.getTime());

            this.maxPrice = Math.max(...this._candles.map((candle) => candle.high));
            this.minPrice = Math.min(...this._candles.map((candle) => candle.low));
            this.emit("mutation", this);
        }
    }

    getDimensions() {
        const rect = this._view_config;
        const bodyWidth = (rect.width * this.bodyWidth) / (rect.width * rect.zoom.x);
        // const space = (bodyWidth * this.bodyWidth) / this.space;
        const space = (rect.width * this.space) / (rect.width * rect.zoom.x);

        return {
            width: this._candles.length * (bodyWidth + space),
            height: this.maxPrice - this.minPrice,
            bodyWidth,
            shadowWidth: this.shadowWidth,
            space,
            padding: rect.padding,
        };
    }

    getCandleByIndex(index: number, force: true): CandleItem;
    getCandleByIndex(index: number, force?: false): CandleItem | undefined;
    getCandleByIndex(index: number, force: boolean = false): CandleItem | undefined {
        if (!force && (index < 0 || index >= this._candles.length)) {
            return;
        }

        index = Math.min(Math.max(index, 0), this._candles.length - 1);

        const candle = this._candles[index];
        const rect = this._view_config;
        const { bodyWidth, shadowWidth, space, padding } = this.getDimensions();

        return candle?.clone({
            index,
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
            candleBodyWidth: bodyWidth,
            candleShadowWidth: shadowWidth,
            candleSpace: space,
            length: this._candles.length,
            padding,
        });
    }

    getCandlesByView(): CandleItem[] {
        const rect = this._view_config;
        const { bodyWidth, shadowWidth, space, padding } = this.getDimensions();

        return this._candles
            .map((candle, index, list) => {
                return candle.clone({
                    index,
                    x: rect.x,
                    y: rect.y,
                    width: rect.width,
                    height: rect.height,
                    candleBodyWidth: bodyWidth,
                    candleShadowWidth: shadowWidth,
                    candleSpace: space,
                    length: list.length,
                    padding,
                });
            })
            .filter((candle) => {
                const { body } = candle.client;
                return body.right >= 0 && body.left <= rect.width;
            })
            .map((candle, index, list) => {
                const maxPrice = Math.max(...list.map((candle) => candle.high));
                const minPrice = Math.min(...list.map((candle) => candle.low));

                return candle.clone({
                    maxPrice,
                    minPrice,
                    length: list.length,
                });
            });
    }

    clone(): Candles {
        return new Candles(this._candles);
    }
}
