import React, { useCallback, useEffect, useRef, useState } from "react";
import { APIHelper } from "Helpers";
import { JSONObjLiteral, Parameters } from "Types";
import { useDebouncedCallback } from "shared-components";

type Methods = "get" | "post" | "put" | "delete";
type Status = "idle" | "loading" | "success" | "error";

export const useAPI = <T = any, M extends Methods = never>(api: APIHelper, method: M, path: string, defaultData?: T, immediate: boolean = false, duration: number = 2000) => {
    const [data, setData] = useState<T | undefined>(defaultData);
    const [status, setStatus] = useState<Status>("idle");
    const [messageError, setMessageError] = useState<string | undefined>(undefined);
    const apiRef = useRef(api);
    const pathRef = useRef(path);
    const promiseRef = useRef<Promise<void> | null>(null);
    const cacheRef = useRef<Map<string, { endTime: number; feth: Promise<any> }>>(new Map());

    const getStatusPromise = (promise: Promise<any>): Promise<"loading" | "finished"> => {
        const pending = Symbol("pending");
        return Promise.race([promise, Promise.resolve(pending)])
            .then((value) => (value === pending ? "loading" : "finished"))
            .catch(() => "finished");
    };

    const getMethodBy = <M extends Methods, B extends Function, S extends Function>(method: M, forBody: B, forSimple: S): M extends "post" | "put" ? B : S =>
        ["post", "put"].includes(method) ? (forBody as any) : (forSimple as any);

    const bodyFetch = useDebouncedCallback(async (body: JSONObjLiteral, parameters: Parameters = {}) => {
        const promiseStatus = await getStatusPromise(promiseRef.current ?? Promise.resolve());
        if (promiseStatus === "loading") {
            return;
        }

        const getCached = (key: string) => {
            const cached = cacheRef.current;
            if (cached.has(key)) {
                const { endTime, feth } = cached.get(key)!;
                if (Date.now() < endTime) {
                    return { endTime, feth };
                }
            }
            return { endTime: 0, feth: undefined };
        };

        promiseRef.current = new Promise<void>((resolve, reject) => {
            try {
                setStatus("loading");
                setMessageError(undefined);

                const key = JSON.stringify({ method, path, body, parameters, duration });

                const { endTime, feth: cacheFetch } = getCached(key);

                const prop =
                    cacheFetch ??
                    (method === "post" || method === "put" ? apiRef.current[method](pathRef.current, body as any, parameters) : apiRef.current[method](pathRef.current, parameters as any));

                prop.then((response) => {
                    setData(response);
                    setStatus("success");
                    resolve();
                })
                    .catch((error) => {
                        setData(undefined);
                        setStatus("error");
                        setMessageError("message" in error ? error.message : String(error));
                        resolve();
                    })
                    .finally(() => {
                        setTimeout(() => {
                            cacheRef.current.delete(key);
                        }, duration);
                    });

                if (Date.now() < endTime) cacheRef.current.set(key, { endTime: Date.now() + duration, feth: prop });
            } catch (e) {
                setData(undefined);
                setStatus("error");
                setMessageError("message" in (e as any) ? (e as any).message : String(e));
                resolve();
            }
        });
    }, 1000);

    const simpleFetch = useDebouncedCallback((parameters?: Parameters) => {
        bodyFetch({}, parameters);
    }, 1000);

    const fetch = getMethodBy(method, bodyFetch, simpleFetch);

    useEffect(() => {
        const time = setTimeout(() => {
            if (immediate && status === "idle" && ["get", "delete"].includes(method)) {
                simpleFetch();
            }
        }, 100);

        return () => clearTimeout(time);
    }, [simpleFetch, status, method]);

    return [data, status, fetch, messageError] as const;
};

// useAPI<{}, "get">({} as any, "get", "/users");
