import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styles from "./styles.module.scss";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { useSizeEffect } from "shared-components";
import { clsx } from "Utils";

interface Props {
    className?: string;
    classesNames?: {
        root?: string;
        content?: string;
        buttonLeft?: string;
        buttonRight?: string;
    };
    style?: React.CSSProperties;
    children?: React.ReactNode;
    onScroll?: () => void;
}

const smooth = (from: number, to: number, effect: (current: number) => void, duration: number = 250) => {
    let t: number;
    const start = Date.now();
    const loop = () => {
        const now = Date.now();
        const progress = Math.min(1, (now - start) / duration);
        effect(from + (to - from) * progress);

        if (progress < 1) {
            t = requestAnimationFrame(loop);
        }
    };
    loop();
    return () => {
        cancelAnimationFrame(t);
    };
};

export interface ScrollSliderRef {
    scrollToElement: (element: HTMLElement) => void;
}

export const ScrollSlider = forwardRef<ScrollSliderRef, Props>(({ className, style = {}, children, onScroll, classesNames = {} }, ref) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const scrollLeftRef = useRef<HTMLDivElement>(null);
    const scrollRightRef = useRef<HTMLDivElement>(null);
    const scrollRef = React.useRef<HTMLDivElement>(null);

    const updateScroll = React.useCallback(() => {
        if (scrollRef.current && scrollLeftRef.current && scrollRightRef.current) {
            const scroll = scrollRef.current;
            const scrollLeft = scrollLeftRef.current;
            const scrollRight = scrollRightRef.current;

            if (scroll.scrollLeft === 0) {
                scrollLeft.style.display = "none";
            } else {
                scrollLeft.style.display = "";
            }

            if (scroll.scrollLeft + scroll.clientWidth >= scroll.scrollWidth) {
                scrollRight.style.display = "none";
            } else {
                scrollRight.style.display = "";
            }
        }

        onScroll?.();
    }, []);

    const containerRef = useSizeEffect(() => {
        updateScroll();
    }, []);

    useImperativeHandle(
        ref,
        () => {
            return {
                scrollToElement(element: HTMLElement) {
                    if (scrollRef.current) {
                        const scroll = scrollRef.current;
                        const scrollLeft = element.offsetLeft - scroll.clientWidth / 2 + element.clientWidth / 2;

                        smooth(scroll.scrollLeft, Math.min(Math.max(0, scrollLeft), scroll.scrollWidth - scroll.clientWidth), (current) => {
                            scroll.scrollLeft = current;
                            updateScroll();
                        });
                    }
                },
            };
        },
        []
    );

    const handleScroll = (direction: number) => () => {
        if (scrollRef.current) {
            const scroll = scrollRef.current;
            scroll.scrollTo({
                left: scroll.scrollLeft + scroll.clientWidth * 0.7 * direction,
                behavior: "smooth",
            });

            smooth(scroll.scrollLeft, scroll.scrollLeft + scroll.clientWidth * 0.7 * direction, (current) => {
                scroll.scrollLeft = current;
                updateScroll();
            });
        }
    };

    return (
        <div
            ref={rootRef}
            className={clsx(styles["main"], className, classesNames.root)}
            style={{
                ...style,
            }}
        >
            <div className={clsx(styles["button-left"], classesNames.buttonLeft)} ref={scrollLeftRef} onClick={handleScroll(-1)}>
                <Icon path={mdiChevronLeft} />
            </div>
            <div
                className={styles["content"]}
                ref={(el) => {
                    (scrollRef as any).current = el;
                    containerRef(el);
                }}
            >
                <div className={clsx(styles["scroll"], classesNames.content)}>{children}</div>
            </div>
            <div className={clsx(styles["button-right"], classesNames.buttonRight)} ref={scrollRightRef} onClick={handleScroll(1)}>
                <Icon path={mdiChevronRight} />
            </div>
        </div>
    );
});
