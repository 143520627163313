import { isJson } from "./Global";

interface StoragerListener {
    index: number | null;
    stop: () => void;
    remove: () => void;
}

const data: {
    [key: string]: any;
} = {};

const listenersChange: {
    [key: string]: Array<(value: any) => void>;
} = {};

const listeners: Map<string, Array<(...args: any[]) => void>> = new Map();
const onceListeners: Map<string, Array<(...args: any[]) => void>> = new Map();

export const get = <val = any>(key: string): val | null => {
    return (data[key] ?? null) as any;
};

export const set = <val = any>(key: string, value: val) => {
    let isIguals = false;

    if (Object.keys(data).includes(key)) {
        if (value === data[key] || (isJson(value) && isJson(data[key]) && JSON.stringify(value) === JSON.stringify(data[key]))) {
            isIguals = true;
        }
    }

    data[key] = value;
    if (listenersChange[key] && !isIguals) {
        for (let i = 0; i < listenersChange[key].length; i++) {
            if (typeof listenersChange[key][i] === "function") {
                listenersChange[key][i](value);
            }
        }
    }

    return {
        key,
        get(): val {
            return data[key] as any;
        },
        remove(): void {
            remove(key);
        },
        onChange(callback: (value: val | undefined) => void): StoragerListener {
            return onChange(key, callback);
        },
        offChange(callback: (value: val | undefined) => void): void {
            offChange(key, callback);
        },
    };
};

export const push = <val = any>(key: string, value: val) => {
    if (!data[key]) {
        data[key] = [];
    }

    if (Array.isArray(data[key]) !== true) {
        throw new Error("The key is not an array.");
    }

    const index = data[key].length;
    data[key].push(value);

    if (listenersChange[key]) {
        for (let i = 0; i < listenersChange[key].length; i++) {
            if (typeof listenersChange[key][i] === "function") {
                listenersChange[key][i](data[key]);
            }
        }
    }

    return {
        index,
        get(): val {
            return data[key][index] as any;
        },
        remove(): void {
            data[key] = (data[key] as any[]).filter((_, i) => i !== index);
        },
    };
};

export const remove = (key: string): void => {
    delete data[key];
    removeChangeListeners(key);
};

export const hasKey = (key: string): boolean => {
    return key in data;
};

export const eraseAll = (): void => {
    for (let key in listenersChange) {
        for (let i = 0; i < listenersChange[key].length; i++) {
            if (typeof listenersChange[key][i] === "function") {
                listenersChange[key][i](undefined);
            }
        }
        delete listenersChange[key];
    }

    for (let key in data) {
        delete data[key];
    }
};

export const onChange = <val = any>(key: string, callback: (value: val | undefined) => void): StoragerListener => {
    if (!callback || typeof callback !== "function")
        return {
            index: null,
            stop: () => {},
            remove: () => {},
        };

    if (!listenersChange[key]) listenersChange[key] = [];

    let indexFunction: number | null = null;

    listenersChange[key].forEach((f, i) => {
        if (callback === f) {
            indexFunction = i;
            listenersChange[key][i] = callback;
        }
    });

    if (indexFunction === null) {
        indexFunction = listenersChange[key].length;
        listenersChange[key].push(callback);
    }

    return {
        index: indexFunction,
        stop() {
            if (key in listenersChange && typeof indexFunction === "boolean" && typeof listenersChange[key][indexFunction] === "function") {
                delete listenersChange[key][indexFunction];
            }
        },
        remove() {
            this.stop();
        },
    };
};

export const offChange = <val = any>(key: string | StoragerListener, callback: number | StoragerListener | ((value: val | undefined) => void)): void => {
    if (typeof key === "object" && typeof key.stop === "function") {
        return key.stop();
    }

    if (!(typeof key === "string" && key in listenersChange)) {
        return;
    }

    if (typeof callback === "function") {
        listenersChange[key].forEach((f, i) => {
            if (f === callback) {
                delete listenersChange[key][i];
            }
        });
    } else if (typeof callback === "number" && callback >= 0 && callback < listenersChange[key].length) {
        delete listenersChange[key][callback];
    } else if (typeof callback === "object" && typeof callback.stop === "function") {
        callback.stop();
    } else {
        delete listenersChange[key];
    }
};

export const removeChangeListeners = (key: string): void => {
    if (key in listenersChange) {
        delete listenersChange[key];
    }
};

export const addListener = <F extends (...args: any[]) => void>(key: string, callback: F): StoragerListener => {
    if (!listeners.has(key)) {
        listeners.set(key, []);
    }

    listeners.get(key)?.push(callback);

    return {
        index: (listeners.get(key)?.length ?? 0) - 1,
        stop() {
            if (listeners.has(key)) {
                listeners.set(
                    key,
                    (listeners.get(key) ?? []).filter((f) => f !== callback)
                );
            }
        },
        remove() {
            this.stop();
        },
    };
};

export const removeListener = <F extends (...args: any[]) => void>(key: string, callback: F): void => {
    if (listeners.has(key)) {
        listeners.set(
            key,
            (listeners.get(key) ?? []).filter((f) => f !== callback)
        );
    }
};

export const emit = <F extends (...args: A) => void, A extends any[] = Parameters<F>>(key: string, ...args: A): void => {
    if (listeners.has(key)) {
        listeners.get(key)?.forEach((f) => f(...args));
    }
};

export const addOnceListener = <F extends (...args: any[]) => void>(key: string, callback?: F): Promise<void> => {
    return new Promise((resolve) => {
        if (typeof callback === "function") {
            if (!onceListeners.has(key)) {
                onceListeners.set(key, []);
            }
            onceListeners.get(key)?.push(callback);
        }
        const listener = addListener(key, (...args) => {
            if (typeof callback === "function") {
                removeOnceListener(key, callback);
                callback(...args);
            }
            listener.stop();
            resolve();
        });
    });
};

export const removeOnceListener = <F extends (...args: any[]) => void>(key: string, callback: F): void => {
    if (onceListeners.has(key)) {
        onceListeners.set(
            key,
            (onceListeners.get(key) ?? []).filter((f) => f !== callback)
        );
    }
};

export const emitOnce = <F extends (...args: A) => void, A extends any[] = Parameters<F>>(key: string, ...args: A): void => {
    if (onceListeners.has(key)) {
        onceListeners.get(key)?.forEach((f) => f(...args));
        onceListeners.delete(key);
    }
};
