import { forwardWidgets, WidgetConfig } from "Components";
import styles from "./index.module.scss";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { WebsocketOrder, WebsocketTrade, WidgetsProps } from "Types";
import { formatCurrency } from "Utils";
import { useWebsocket } from "Hooks";
import { MainAPI } from "Helpers";
import { Toast } from "shared-components";

interface OrderBookItem {
    id: string;
    type: "BUY" | "SELL";
    symbol: string;
    price: number;
    amount: number;
    remaining_amount: number;
    status: "OPEN" | "CLOSED" | "PARTIALLY_FILLED" | "FILLED" | "CANCELED";
    created_at: string;
    updated_at: string;
}

interface OrderBook {
    buyOrders: OrderBookItem[];
    sellOrders: OrderBookItem[];
}

const getOrdersInfo = (orders: OrderBookItem[], key: keyof OrderBookItem = "price") => {
    let { max, min } = orders.reduce(
        (acc, order) => {
            acc.max = Math.max(acc.max, typeof (order as any)[key] === "number" ? (order as any)[key] : acc.max);
            acc.min = Math.min(acc.min, typeof (order as any)[key] === "number" ? (order as any)[key] : acc.min);
            return acc;
        },
        { max: -Infinity, min: Infinity }
    );

    min = isFinite(min) ? min : 0;
    return { max: isFinite(max) ? max : min, min };
};

const renderOrder = (order: OrderBook, depth: number = 15) => {
    const process = (orders: OrderBookItem[]) => {
        orders = orders
            .filter((order) => order.remaining_amount > 0)
            .reduce((acc, order) => {
                const index = acc.findIndex((o) => o.price === order.price);
                if (index >= 0) {
                    acc[index].remaining_amount = acc[index].remaining_amount + order.remaining_amount;
                } else {
                    acc.push(order);
                }
                return acc;
            }, [] as OrderBookItem[]);

        if (orders.length >= depth) {
            const { max, min } = getOrdersInfo(orders);

            return orders.reduce((acc, order) => {
                const index = Math.min(depth - 1, Math.max(0, Math.round(((order.price - min) / (max - min)) * (depth - 1))));
                if (acc[index]) {
                    acc[index].prices.push(order.price);
                    acc[index].remaining_amount = acc[index].remaining_amount + order.remaining_amount;
                    acc[index].price = acc[index].prices.reduce((acc, price) => acc + price, 0) / acc[index].prices.length;
                } else {
                    acc[index] = {
                        ...order,
                        prices: [order.price],
                    };
                }
                return acc;
            }, [] as (OrderBookItem & { prices: number[] })[]) as OrderBookItem[];
        }

        return orders;
    };

    return {
        buyOrders: process(order.buyOrders).sort((a, b) => b.price - a.price),
        sellOrders: process(order.sellOrders).sort((a, b) => a.price - b.price),
    };
};

export const OrderBook = forwardWidgets<WidgetsProps>(
    "OrderBook",
    ({ symbol }) => {
        const [loading, setLoading] = useState(true);
        const [orders, setOrders] = useState<OrderBook>({
            buyOrders: [],
            sellOrders: [],
        });
        const [price, setPrice] = useState(0);

        WidgetConfig.useTitle("Livro de Ordens");

        useWebsocket<WebsocketOrder>(
            "order",
            (data) => {
                if (data.symbol === symbol?.pair) {
                    setOrders((prev) => {
                        const prop = data.type === "SELL" ? "sellOrders" : "buyOrders";
                        prev[prop] = prev[prop].filter((order) => order.id !== data.id);
                        if (["OPEN", "PARTIALLY_FILLED"].includes(data.status)) {
                            prev[prop].push({
                                id: data.id,
                                type: data.type,
                                symbol: data.symbol,
                                price: data.price,
                                amount: data.amount,
                                remaining_amount: data.remaining_amount,
                                status: data.status,
                                created_at: data.time,
                                updated_at: data.time,
                            });
                        }
                        prev[prop] = prev[prop].filter((order) => order.remaining_amount > 0);
                        return { ...prev };
                    });
                }
            },
            [symbol]
        );

        useWebsocket<WebsocketTrade>(
            "trade",
            (data) => {
                if (data.symbol === symbol?.pair && !loading) {
                    setPrice(data.price);
                }
            },
            [loading, symbol]
        );

        useEffect(() => {
            setLoading(true);
            let canceled: boolean = false;
            const time = setTimeout(() => {
                Promise.all([MainAPI.get<OrderBook>("/inventory/order_book", { symbol: symbol?.pair }), MainAPI.get<{ route: string; price: number }>("/inventory/price", { symbol: symbol?.pair })])
                    .then(([orderBook, { price }]) => {
                        if (canceled) return;
                        setOrders(orderBook);
                        setPrice(price);
                        setLoading(false);
                    })
                    .catch((error) => {
                        Toast.error(error);
                    });
            }, 1000);

            return () => {
                canceled = true;
                clearTimeout(time);
            };
        }, [symbol]);

        const { buyOrders, sellOrders } = renderOrder(orders);

        const maxSellTotal = sellOrders.reduce((acc, order) => Math.max(acc, order.remaining_amount), 0);
        const minSellTotal = sellOrders.reduce((acc, order) => Math.min(acc, order.remaining_amount), 0);

        const maxBuyTotal = buyOrders.reduce((acc, order) => Math.max(acc, order.remaining_amount), 0);
        const minBuyTotal = buyOrders.reduce((acc, order) => Math.min(acc, order.remaining_amount), 0);

        const totalSell = sellOrders.reduce((acc, order) => acc + order.remaining_amount, 0);
        const totalBuy = buyOrders.reduce((acc, order) => acc + order.remaining_amount, 0);
        const total = totalSell + totalBuy;
        const sellPercent = totalSell + totalBuy === 0 ? 50 : totalSell === 0 ? 0 : Math.round((totalSell / total) * 100);
        const buyPercent = totalSell + totalBuy === 0 ? 50 : totalBuy === 0 ? 0 : Math.round((totalBuy / total) * 100);

        return (
            <div className={styles.main}>
                {loading && <CircularProgress color="inherit" />}
                {!loading && (
                    <div className={styles.orderbook}>
                        <div className={styles.header}>
                            <p>Preço ({symbol?.quote})</p>
                            <p>Quantidade ({symbol?.base})</p>
                            <p>Total</p>
                        </div>
                        <div className={styles.sellOrders}>
                            {sellOrders.map((order, index) => {
                                const total = order.price * order.remaining_amount;
                                const percent = ((order.remaining_amount - minSellTotal) / (maxSellTotal - minSellTotal)) * 100;
                                return (
                                    <div key={index} className={styles.order} style={{ "--percent": `${percent}%` } as React.CSSProperties}>
                                        <p>{formatCurrency(order.price, { shorten: true })}</p>
                                        <p>{formatCurrency(order.remaining_amount, { shorten: true })}</p>
                                        <p>{formatCurrency(total, { shorten: true })}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.divider}>
                            <p>{formatCurrency(price)}</p>
                            <span>{formatCurrency(price, { symbol: symbol?.quote, position: "rt" })}</span>
                        </div>
                        <div className={styles.buyOrders}>
                            {buyOrders.map((order, index) => {
                                const total = order.price * order.remaining_amount;
                                const percent = ((order.remaining_amount - minBuyTotal) / (maxBuyTotal - minBuyTotal)) * 100;
                                return (
                                    <div key={index} className={styles.order} style={{ "--percent": `${percent}%` } as React.CSSProperties}>
                                        <p>{formatCurrency(order.price, { shorten: true })}</p>
                                        <p>{formatCurrency(order.remaining_amount, { shorten: true })}</p>
                                        <p>{formatCurrency(total, { shorten: true })}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.footer}>
                            <p>{buyPercent}%</p>
                            <div className={styles.indice}>
                                <div className={styles.buy} style={{ width: `${buyPercent}%` }}></div>
                                <div className={styles.sell}></div>
                            </div>
                            <p>{sellPercent}%</p>
                        </div>
                    </div>
                )}
            </div>
        );
    },
    { minW: 2, maxW: 4 }
);
