import React from "react";

import styles from "./index.module.scss";
import logoImage from "../../assets/image/logo.png";
import { Typography } from "@mui/material";

interface Props {
    title?: string | null;
    subtitle?: string | null;
    image?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    reverse?: boolean;
}

export const LoginScope: React.FC<Props> = ({ title, subtitle, children, image, footer, className, style, reverse = false }) => {
    return (
        <div className={[styles.main, className].join(" ")} style={{ flexDirection: reverse ? "row-reverse" : "row", ...style }}>
            {image && <div className={styles.image}>{image}</div>}
            <div className={styles.content}>
                <div>
                    <div className={styles.logo} style={{ maskImage: `url(${logoImage})` }}></div>
                    {title && <Typography variant="h4">{title}</Typography>}
                    {subtitle && (
                        <Typography variant="subtitle1" component="h6">
                            {subtitle}
                        </Typography>
                    )}
                </div>
                <div>{children}</div>
                {footer && <div className={styles.footer}>{footer}</div>}
            </div>
        </div>
    );
};
