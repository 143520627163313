import React, { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

type position = "top" | "top-left" | "top-right" | "right" | "bottom-right" | "bottom" | "bottom-left" | "left" | "center";

const getOrientation = (position: position) => {
    const vertical = position.search("top") !== -1 ? "top" : position.search("bottom") !== -1 ? "bottom" : "center";
    const horizontal = position.search("left") !== -1 ? "left" : position.search("right") !== -1 ? "right" : "center";
    return { vertical, horizontal } as const;
};

export const Portal: React.FC<{
    reference?: React.RefObject<HTMLElement>;
    anchorOrigin?: position;
    transformOrigin?: position;
    children: ReactNode;
    onClosed?: () => void;
    show?: boolean;
    style?: React.CSSProperties;
    className?: string;
}> = ({ reference, anchorOrigin = "bottom-right", transformOrigin = "top-right", onClosed, children, show, style = {}, className }) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const referenceRef = useRef<HTMLDivElement>(null);

    const getBoundingFragmentRect = (): {
        top: number;
        left: number;
    } => {
        const parentReference: HTMLElement | null | undefined = reference?.current ?? (referenceRef.current?.parentNode as any);

        if (!mainRef.current || !parentReference || !parentReference.getBoundingClientRect) return { top: 0, left: 0 };

        const refRect = parentReference.getBoundingClientRect();
        const mainRect = mainRef.current.getBoundingClientRect();
        const pos = { top: 0, left: 0 };

        const anchor = getOrientation(anchorOrigin);
        const transform = getOrientation(transformOrigin);

        switch (anchor.vertical) {
            case "top":
                pos.top = refRect.top;
                break;
            case "bottom":
                pos.top = refRect.bottom;
                break;
            case "center":
                pos.top = refRect.top + refRect.height / 2;
                break;
        }

        switch (transform.vertical) {
            case "bottom":
                pos.top -= mainRect.height;
                break;
            case "center":
                pos.top -= mainRect.height / 2;
                break;
        }

        switch (anchor.horizontal) {
            case "left":
                pos.left = refRect.left;
                break;
            case "right":
                pos.left = refRect.left + refRect.width;
                break;
            case "center":
                pos.left = refRect.left + refRect.width / 2;
                break;
        }

        switch (transform.horizontal) {
            case "right":
                pos.left -= mainRect.width;
                break;
            case "center":
                pos.left -= mainRect.width / 2;
                break;
        }

        return pos;
    };

    useEffect(() => {
        const reposition = () => {
            if (!mainRef.current) return;
            const pos = getBoundingFragmentRect();
            mainRef.current.style.top = `${pos.top}px`;
            mainRef.current.style.left = `${pos.left}px`;
            const margin = 15;

            const { top, left, width, height } = mainRef.current.getBoundingClientRect();

            if (left + width > window.innerWidth) {
                mainRef.current.style.left = `${window.innerWidth - width - margin * 2}px`;
            } else if (left < 0) {
                mainRef.current.style.left = `${margin}px`;
            }

            if (top + height > window.innerHeight) {
                mainRef.current.style.top = `${window.innerHeight - height - margin * 2}px`;
            } else if (top < 0) {
                mainRef.current.style.top = `${margin}px`;
            }

            mainRef.current.style.maxWidth = `${Math.min(400, window.innerWidth - margin * 3)}px`;
        };

        const downOutside = (e: MouseEvent) => {
            if (mainRef.current && !mainRef.current.contains(e.target as Node)) {
                document.removeEventListener("mousedown", downOutside);
                onClosed?.();
                return;
            }
            reposition();
        };

        document.addEventListener("mousedown", downOutside);
        window.addEventListener("scroll", reposition);
        window.addEventListener("resize", reposition);

        reposition();

        return () => {
            document.removeEventListener("mousedown", downOutside);
            window.removeEventListener("scroll", reposition);
            window.removeEventListener("resize", reposition);
        };
    }, [show, reference, mainRef.current]);

    const { top, left } = getBoundingFragmentRect();

    return (
        <>
            <div
                ref={referenceRef}
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    zIndex: -9999999,
                    display: "none",
                    opacity: 0,
                }}
            ></div>
            {show &&
                createPortal(
                    <div
                        ref={mainRef}
                        className={className}
                        style={{
                            position: "fixed",
                            top: top,
                            left: left,
                            zIndex: 9999,
                            width: "max-content",
                            minWidth: 200,
                            maxWidth: 400,
                            overflow: "hidden",
                            ...style,
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {children}
                    </div>,
                    document.body
                )}
        </>
    );
};
