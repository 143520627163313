import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ForwardWidgets, ForwardWidgetsProps, ForwardWidgetsScope, joinObject, WidgetContextConfig, WidgetContextConfigOption, WidgetContextValue, WidgetStateScope } from "./Types";
import { clsx } from "Utils";
import { Tabs } from "Components/Tabs";
import Icon from "@mdi/react";
import { mdiDotsHorizontal } from "@mdi/js";
import { Portal } from "Components/Portal";
import { Paper } from "@mui/material";

const WidgetContext = createContext<WidgetContextValue<Object>>({
    config: {},
    state: {},
    setState: () => {},
    setConfig: () => {},
});

export const WidgetConfig = {
    useConfig<O extends Record<string, unknown>, T extends string>(initial: O & WidgetContextConfig<T>) {
        const i: joinObject<WidgetContextConfig<T>, O> = initial as any;
        const { config, setConfig } = useContext<WidgetContextValue<Object>>(WidgetContext as any);

        const setValue = useCallback(<K extends keyof typeof i>(key: K, v: React.SetStateAction<(typeof i)[K]>) => {
            setConfig((prev: any) => {
                const value = typeof v === "function" ? (v as any)(prev[key]) : v;
                return { ...prev, [key]: value };
            });
        }, []);

        const value: typeof i = { ...i, ...(config ?? {}), title: i.title, tabs: i.tabs };
        return [value, setValue] as const;
    },

    useTitle(initial: string) {
        const { config, setConfig } = useContext<WidgetContextValue<Object>>(WidgetContext as any);
        useLayoutEffect(() => {
            setConfig((prev) => ({ ...prev, title: initial }));
        }, []);

        const setValue = useCallback((v: React.SetStateAction<string>) => {
            setConfig((prev: any) => {
                const value = typeof v === "function" ? v(prev.title) : v;
                return { ...prev, title: value };
            });
        }, []);

        return [config.title ?? initial, setValue] as const;
    },

    useTabs<T extends string>(tabs: Array<{ title: string; id: T }>, initial: T) {
        const { config, setConfig } = useContext<WidgetContextValue<Object>>(WidgetContext as any);
        useLayoutEffect(() => {
            setConfig((prev) => ({ currentTab: initial, ...prev, tabs: tabs }));
        }, []);

        const setValue = useCallback((v: React.SetStateAction<T>) => {
            setConfig((prev) => {
                const value = typeof v === "function" ? v((prev.currentTab as T) ?? initial) : v;
                return { ...prev, currentTab: value };
            });
        }, []);

        return [config.currentTab ?? initial, setValue] as [T, typeof setValue];
    },

    useOption(name: string, options: WidgetContextConfigOption) {
        const { config, setConfig } = useContext<WidgetContextValue<Object>>(WidgetContext as any);

        useEffect(() => {
            setConfig((prev) => {
                const opt = { ...(prev.options ?? {}) };
                opt[name] = { ...(opt?.[name] ?? {}), ...options, value: opt?.[name].value ?? options.value } as any;
                return {
                    ...prev,
                    options: opt,
                };
            });
        }, []);

        const setValue = useCallback((v: React.SetStateAction<WidgetContextConfigOption>) => {
            setConfig((prev) => {
                const options = { ...(prev.options ?? {}) };
                options[name] = typeof v === "function" ? (v as any)(options[name]) : v;
                return { ...prev, options };
            });
        }, []);

        return [config.options?.[name] ?? options, setValue] as const;
    },

    useRadioGroup<T extends string | number>(name: string, options: Array<{ label: string; value: T }>, initial: T, label?: string) {
        const [{ value }, setOption] = WidgetConfig.useOption(name, { type: "radioGroup", label, value: initial, options });

        const setValue = useCallback((v: React.SetStateAction<T>) => {
            setOption((prev: any) => {
                const value = typeof v === "function" ? v(prev.value) : v;
                return { ...prev, value };
            });
        }, []);

        return [value as T, setValue] as const;
    },

    useCheckboxGroup<T extends string | number>(name: string, options: Array<{ label: string; value: T }>, initial: T, label?: string) {
        const [{ value }, setOption] = WidgetConfig.useOption(name, { type: "checkboxGroup", label, value: initial, options });

        const setValue = useCallback((v: React.SetStateAction<T>) => {
            setOption((prev: any) => {
                const value = typeof v === "function" ? v(prev.value) : v;
                return { ...prev, value };
            });
        }, []);

        return [value as T, setValue] as const;
    },

    useSwitch(name: string, label: string, initial: boolean) {
        const [{ value }, setOption] = WidgetConfig.useOption(name, { type: "switch", label, value: initial });

        const setValue = useCallback((v: React.SetStateAction<boolean>) => {
            setOption((prev: any) => {
                const value = typeof v === "function" ? v(prev.value) : v;
                return { ...prev, value };
            });
        }, []);

        return [value, setValue] as const;
    },

    useStorage<T = any>(key: string, initial: T) {
        const { state, setState } = useContext<WidgetContextValue<any>>(WidgetContext as any);
        const [value, setValue] = useState<T>(state?.[key] ?? initial);

        useEffect(() => {
            setState(key as any, value as any);
        }, [value]);

        return [value, setValue] as const;
    },
} as const;

const Component: React.FC<
    ForwardWidgetsProps & {
        Render: React.FC<any>;
    }
> = ({ state: s = {}, config: c = {}, updateState, updateConfig, Render, ...props }, deprecatedLegacyContext) => {
    const [config, setConfig] = useState<Partial<WidgetContextConfig<string>>>(c);
    const state = useRef(s);
    const btnConfigRef = useRef<HTMLDivElement>(null);
    const [showConfig, toggleConfig] = useState<boolean>(false);

    useEffect(() => {
        if (updateConfig) updateConfig(config);
        if (!config.options) {
            toggleConfig(false);
        }
    }, [config]);

    return (
        <WidgetContext.Provider
            value={{
                config: config,
                state: state.current,
                setState(k, v) {
                    const value = typeof v === "function" ? v(state.current as any) : v;
                    state.current = ((prev) => ({ ...prev, [k]: value }))(state.current);
                    if (updateState) updateState(state.current);
                },
                setConfig(value) {
                    setConfig(value);
                },
            }}
        >
            <div className={clsx("react-grid-header")}>
                {!Array.isArray(config.tabs) ? (
                    <div className={clsx("react-grid-header-title")}>{config.title}</div>
                ) : (
                    <Tabs
                        className={clsx("react-grid-header-tabs")}
                        tabs={config.tabs}
                        currentTab={config.currentTab}
                        setCurrentTab={(tab) => {
                            setConfig((prev) => ({ ...prev, currentTab: tab }));
                        }}
                    />
                )}
                {config.options && (
                    <div ref={btnConfigRef} className={clsx("react-grid-header-options")} onClick={!showConfig ? () => toggleConfig(!showConfig) : undefined}>
                        <Icon path={mdiDotsHorizontal} size={1} />
                    </div>
                )}
                <Portal anchorOrigin="bottom-right" transformOrigin="top-right" reference={btnConfigRef} show={showConfig} onClosed={() => toggleConfig(false)}>
                    <Paper
                        elevation={5}
                        sx={{
                            minHeight: "100px",
                        }}
                    ></Paper>
                </Portal>
            </div>
            <div className={clsx("react-grid-body")}>
                <Render {...props} {...deprecatedLegacyContext} />
            </div>
        </WidgetContext.Provider>
    );
};

export const forwardWidgets: ForwardWidgets = (name, render, defaultConfig = {}) => {
    const scope: ForwardWidgetsScope<typeof name> = ((props, deprecatedLegacyContext) => {
        return <Component {...props} {...deprecatedLegacyContext} Render={render} />;
    }) as ForwardWidgetsScope<typeof name>;

    Object.defineProperty(scope, "isWidget", {
        value: true,
        writable: false,
        configurable: true,
        enumerable: false,
    });

    Object.defineProperty(scope, "name", {
        value: name,
        writable: false,
        configurable: true,
        enumerable: false,
    });

    Object.defineProperty(scope, "defaultConfig", {
        value: { i: name, ...defaultConfig },
        writable: false,
        configurable: true,
        enumerable: false,
    });

    return scope as any;
};
