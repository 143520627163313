import React from "react";
import styles from "./styles.module.scss";
import { ScrollSlider } from "Components/ScrollSlider";
import Icon from "@mdi/react";
import { mdiArrowDownRight, mdiArrowUpRight, mdiCart, mdiInvoiceListOutline, mdiTicketConfirmationOutline } from "@mdi/js";
import { clsx } from "Utils";
import { DialogHelper } from "Helpers";
import { OptionsTransaction } from "./Options";

interface Props {
    loading?: boolean;
    amount?: number;
}

export const Actions: React.FC<Props> = ({ loading, amount = 0 }) => {
    const actions: Array<{
        icon: string;
        title: string;
        disabled?: boolean;
        action?: () => void;
    }> = [
        {
            icon: mdiArrowUpRight,
            title: "Enviar",
            // disabled: amount <= 0,
            action: () => {
                DialogHelper.open(<OptionsTransaction type={"withdraw"} />, {
                    title: "Enviar",
                    confirm: {
                        label: "Ok",
                        action() {
                            DialogHelper.close();
                        },
                    },
                    onClose() {},
                });
            },
        },
        {
            icon: mdiArrowDownRight,
            title: "Receber",
            action: () => {
                DialogHelper.open(<OptionsTransaction type={"deposit"} />, {
                    title: "Receber",
                    confirm: {
                        label: "Ok",
                        action() {
                            DialogHelper.close();
                        },
                    },
                    onClose() {},
                });
            },
        },
        {
            icon: mdiInvoiceListOutline,
            title: "Conta",
            disabled: true,
        },
        {
            icon: mdiCart,
            title: "Mercado",
            disabled: true,
        },
        {
            icon: mdiTicketConfirmationOutline,
            title: "Ticket",
            disabled: true,
        },
    ];

    return (
        <div className={styles.main}>
            <ScrollSlider
                classesNames={{
                    content: styles.actions,
                }}
            >
                {actions.map(({ icon, title, disabled, action }, i) => {
                    return (
                        <div className={clsx(styles.action, disabled || typeof action !== "function" || loading ? styles.disabled : null)} key={i} onClick={disabled ? undefined : action}>
                            <div className={styles.icon}>
                                <Icon path={icon} />
                            </div>
                            <div className={styles.title}>{title}</div>
                        </div>
                    );
                })}
            </ScrollSlider>
        </div>
    );
};
