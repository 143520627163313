import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { DevelopmentTrace, Toast } from "shared-components";
import { History } from "./History";
import { Balance } from "./Balance";
import { Actions } from "./Actions";
import { Statistic } from "./Statistic";
import { Tokens } from "./Tokens";
import { useAPI } from "Hooks";
import { MainAPI } from "Helpers";
import { PerformanceSummary } from "Types";
import { Performance } from "./Performance";

interface DashboardResponse {
    address: string;
    balance: {
        balance: {
            quantity: number;
            price: number;
            value: number;
        };
        tokens: {
            [key: string]: {
                quantity: number;
                price: number;
                value: number;
            };
        };
    };
    history: any[];
    performanceSummary: PerformanceSummary;
}

const Dashboard: React.FC = () => {
    const [data, status, fetch, messageError] = useAPI<DashboardResponse, "get">(MainAPI, "get", "/dashboard", undefined, true);

    useEffect(() => {
        if (status === "error" && messageError) {
            Toast.error(messageError);
        }
    }, [status, messageError]);

    const loading = status !== "success" || data === undefined;

    return (
        <div className={styles.main}>
            <div className={styles.info}>
                <Balance loading={loading} balance={data?.balance} />
                <Actions loading={loading} amount={data?.balance.balance.quantity} />
                <Statistic loading={loading} />
                <Performance data={data?.performanceSummary} />
            </div>
            <div className={styles.content}>
                <Tokens loading={loading} />
                <History loading={loading} />
            </div>
        </div>
    );
};

export default Dashboard;
