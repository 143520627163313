import { mdiEye, mdiEyeOff } from "@mdi/js";
import Icon from "@mdi/react";
import { FormControl, IconButton, InputAdornment, InputLabel, Input, TextField, OutlinedInput, FormHelperText } from "@mui/material";
import React, { useState, useId } from "react";

export const TextFieldEyes: React.FC<React.ComponentProps<typeof TextField>> = ({ variant = "outlined", helperText, sx, style, className, ...props }) => {
    const [showPassword, setShowPassword] = useState(false);
    const id = useId();
    const helperTextId = useId();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl {...(props as any)} sx={sx} variant={variant} className={className} style={style}>
            <InputLabel htmlFor={id}>{props.label}</InputLabel>
            <OutlinedInput
                {...(props as any)}
                variant={variant}
                id={id}
                aria-describedby={helperTextId}
                type={showPassword ? "text" : "password"}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} onMouseUp={handleMouseUpPassword} edge="end">
                            {showPassword ? <Icon path={mdiEyeOff} size={1} /> : <Icon path={mdiEye} size={1} />}
                        </IconButton>
                    </InputAdornment>
                }
                label={props.label}
            />
            {helperText && <FormHelperText id={helperTextId}>{helperText}</FormHelperText>}
        </FormControl>
    );
};
