import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useLayoutEffect } from "react";

interface Props {
    duration: number;
    onComplete: () => void;
    className?: string;
    style?: React.CSSProperties;
}

const formatTime = (ms: number): string => {
    if (ms < 0) return "0s";

    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
        return `${hours}h ${minutes % 60}m`;
    } else if (minutes > 0) {
        return `${minutes}m ${seconds % 60}s`;
    } else {
        return `${seconds}s`;
    }
};

export const CountdownBar: React.FC<Props> = ({ duration, onComplete, className, style = {} }) => {
    const [progress, setProgress] = React.useState(0);
    const start = React.useRef<number>(Date.now());

    useLayoutEffect(() => {
        start.current = Date.now();

        const timer = setInterval(() => {
            const now = Date.now();
            const diff = now - start.current;
            const progress = diff / duration;

            setProgress(Math.max(0, progress));

            if (progress >= 1) {
                clearInterval(timer);
                onComplete();
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const currentTime = (1 - progress) * duration;

    return (
        <div className={className} style={{ "--mui-palette-LinearProgress-primaryBg": "var(--mui-palette-action-selected)", width: "100%", ...style } as React.CSSProperties}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ flex: 1, mr: 1 }}>
                    <LinearProgress variant="determinate" value={Math.round((1 - progress) * 100)} />
                </Box>
                <Box>
                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        {formatTime(currentTime)}
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};
