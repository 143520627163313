import { LoginScope, TextFieldEyes } from "Components";
import React, { useEffect, useState } from "react";
import { Components, Helpers, Routes, useRoutes } from "shared-components";
import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiApple, mdiCheck, mdiClose, mdiGoogle, mdiIncognito } from "@mdi/js";
import { LoadingButton } from "@mui/lab";
import styles from "./index.module.scss";
import { getAuth } from "ivipbase";
import routes from "./";
import { useUser } from "Hooks";

import svgImage from "assets/image/svg/8979812946-9781723876764.svg";

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

const Signup: React.FC = () => {
    const navigate = useRoutes();
    const user = useUser();
    const [stage, setStage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmTerm, setConfirmTerm] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (user) {
            navigate.to("/");
        }
    }, [user]);

    useEffect(() => {
        if (stage === 0) {
            setConfirmTerm(false);
        }
        if (stage <= 1) {
            setPassword("");
        }
        if (stage <= 2) {
            setConfirmPassword("");
        }
    }, [stage]);

    const validEmail = emailRegex.test(email);
    const validPassword = passwordRegex.test(password);
    const validConfirmPassword = validPassword && password === confirmPassword;

    const createAccount = async () => {
        if (!validEmail || !validPassword || !validConfirmPassword || !confirmTerm) {
            setStage(0);
            setErrorMessage("Preencha todos os campos corretamente. Tente novamente");
            return;
        }

        setLoading(true);

        getAuth()
            .createUserWithEmailAndPassword(email, password, false)
            .then((e) => {
                Helpers.Toast.success("Conta criada com sucesso");
                navigate.to("/login");
            })
            .catch((error) => {
                setStage(0);
                let message: string = "";

                switch (error.message) {
                    case "auth/invalid-email":
                        message = "Email inválido";
                        break;
                    case "auth/email-already-in-use":
                        message = "Email em uso";
                        break;
                    case "auth/weak-password":
                        message = "Senha fraca";
                        break;
                    default:
                        message = "Erro ao criar conta";
                        break;
                }

                setErrorMessage(message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const shortEmail = email
        .split("@")
        .map((s, i) => {
            if (i === 0) {
                return s.slice(0, 2) + "****" + (s.length > 5 ? s.slice(s.length - 2, s.length) : "");
            }
            return s;
        })
        .join("@");

    return (
        <LoginScope
            reverse={true}
            title={stage === 0 ? "Bem vindo ao iVipEx" : stage === 1 ? "Verifique seu e-mail" : stage === 2 ? "Criar uma senha" : stage === 3 ? "Confirmação de senha" : null}
            subtitle={stage === 1 ? `Um código de 6 dígitos foi enviado para ${shortEmail}. Por favor, verifique seu e-mail e insira o código em até 30 minutos.` : null}
            image={
                <Components.SVGTheme
                    src={svgImage}
                    theme={{
                        primary: "var(--primary)",
                        secondary: "var(--secondary)",
                    }}
                />
            }
            footer={
                stage === 0 ? (
                    <Components.Link href="/login" color="inherit" disabled={loading} style={{ textDecoration: "none", margin: "0px auto", color: "var(--primary)" }}>
                        Realizar login
                    </Components.Link>
                ) : stage === 1 ? (
                    <Components.Link
                        href="/"
                        color="inherit"
                        disabled={loading}
                        style={{ textDecoration: "none", margin: "0px auto", color: "var(--primary)" }}
                        onClick={(e) => {
                            e.preventDefault();
                            Helpers.Toast.show("No momento não é possível reenviar o código. Por favor, tente novamente em breve.", "warning");
                        }}
                    >
                        Não recebeu o código?
                    </Components.Link>
                ) : null
            }
        >
            {stage === 0 && (
                <>
                    <Box component="form" noValidate sx={{ width: "100%" }} autoComplete="email">
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            fullWidth
                            autoComplete="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                if (errorMessage !== null && validEmail) {
                                    setErrorMessage(null);
                                }
                            }}
                            error={(email.length > 0 && !validEmail) || errorMessage !== null}
                            disabled={loading}
                            helperText={errorMessage ? errorMessage : email.length > 0 && !validEmail && "Email inválido"}
                        />
                        <br />
                        <br />
                        <FormControlLabel
                            required
                            control={<Checkbox checked={confirmTerm} onChange={(e) => setConfirmTerm(e.target.checked)} />}
                            label={
                                <Typography variant="caption">
                                    Eu concordo com os{" "}
                                    <Components.Link href="/terms" target="_blank">
                                        Termos de Serviços
                                    </Components.Link>{" "}
                                    e com a{" "}
                                    <Components.Link href="/privacy" target="_blank">
                                        Política de Privacidade
                                    </Components.Link>
                                    .
                                </Typography>
                            }
                            sx={{
                                alignItems: "flex-start",
                            }}
                        />
                    </Box>
                    <br />
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        size="large"
                        loading={loading}
                        disabled={!validEmail || !confirmTerm}
                        onClick={() => setStage(1)}
                        sx={{
                            gap: "10px",
                        }}
                    >
                        Próximo
                    </LoadingButton>
                    <br />
                    <br />
                    <br />
                    <Divider style={{ width: "100%", fontSize: "12px" }}>OU</Divider>
                    <br />
                    <Button variant="outlined" fullWidth size="large" color="inherit" startIcon={<Icon path={mdiGoogle} size={1} />} sx={{ borderColor: "var(--mui-palette-divider)" }} disabled>
                        <p style={{ margin: "0px", flex: "1" }}>Conta Google</p>
                    </Button>
                    <br />
                    <br />
                    <Button variant="outlined" fullWidth size="large" color="inherit" startIcon={<Icon path={mdiApple} size={1} />} sx={{ borderColor: "var(--mui-palette-divider)" }} disabled>
                        <p style={{ margin: "0px", flex: "1" }}>Conta Apple</p>
                    </Button>
                    <br />
                    <br />
                    <Button variant="outlined" fullWidth size="large" color="inherit" startIcon={<Icon path={mdiIncognito} size={1} />} sx={{ borderColor: "var(--mui-palette-divider)" }} disabled>
                        <p style={{ margin: "0px", flex: "1" }}>Conta Anônima</p>
                    </Button>
                </>
            )}

            {stage === 1 && (
                <>
                    <div className={styles.alert}>
                        No momento não estamos conseguindo validar o seu e-mail. Tente novamente mais tarde. A verificação de e-mail será necessária para operações futuras.
                    </div>
                    <br />
                    <br />
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        size="large"
                        loading={loading}
                        onClick={() => setStage(2)}
                        sx={{
                            gap: "10px",
                        }}
                    >
                        Próximo
                    </LoadingButton>
                    <br />
                    <br />
                    <Button variant="outlined" fullWidth size="large" disabled={loading} onClick={() => setStage(0)}>
                        Voltar
                    </Button>
                </>
            )}

            {stage === 2 && (
                <>
                    <Box component="form" noValidate sx={{ width: "100%" }} autoComplete="email password current-password">
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            fullWidth
                            autoComplete="email"
                            value={email}
                            sx={{
                                display: "none",
                            }}
                            disabled
                        />
                        <TextFieldEyes
                            label="Senha"
                            variant="outlined"
                            type="password"
                            fullWidth
                            autoComplete="current-password"
                            disabled={loading}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!validPassword}
                            helperText={!validPassword && "Senha inválida. Lembre-se de que deve conter pelo menos 8 caractéres, uma letra maiúscula, uma letra minúscula e um número."}
                        />
                    </Box>
                    <List
                        dense
                        sx={{
                            "&>*>*": {
                                color: "currentcolor",
                                opacity: ".7",
                            },
                            "& .MuiListItemIcon-root": {
                                minWidth: "auto",
                                marginRight: "10px",
                            },
                        }}
                    >
                        <ListItem
                            sx={{
                                color: password.length >= 8 && password.length <= 128 ? "currentcolor" : "var(--mui-palette-error-main)",
                            }}
                        >
                            <ListItemIcon>
                                <Icon path={password.length >= 8 && password.length <= 128 ? mdiCheck : mdiClose} size={1} />
                            </ListItemIcon>
                            <ListItemText primary="8 a 128 caracteres" />
                        </ListItem>
                        <ListItem
                            sx={{
                                color: /[A-Z]/.test(password) ? "currentcolor" : "var(--mui-palette-error-main)",
                            }}
                        >
                            <ListItemIcon>
                                <Icon path={/[A-Z]/.test(password) ? mdiCheck : mdiClose} size={1} />
                            </ListItemIcon>
                            <ListItemText primary="Pelo menos uma letra maiúscula" />
                        </ListItem>
                        <ListItem
                            sx={{
                                color: /[a-z]/.test(password) ? "currentcolor" : "var(--mui-palette-error-main)",
                            }}
                        >
                            <ListItemIcon>
                                <Icon path={/[a-z]/.test(password) ? mdiCheck : mdiClose} size={1} />
                            </ListItemIcon>
                            <ListItemText primary="Pelo menos uma letra minúscula" />
                        </ListItem>
                        <ListItem
                            sx={{
                                color: /[0-9]/.test(password) ? "currentcolor" : "var(--mui-palette-error-main)",
                            }}
                        >
                            <ListItemIcon>
                                <Icon path={/[0-9]/.test(password) ? mdiCheck : mdiClose} size={1} />
                            </ListItemIcon>
                            <ListItemText primary="Pelo menos um número" />
                        </ListItem>
                    </List>
                    <br />
                    <br />
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        size="large"
                        loading={loading}
                        disabled={!validPassword}
                        onClick={() => setStage(3)}
                        sx={{
                            gap: "10px",
                        }}
                    >
                        Próximo
                    </LoadingButton>
                    <br />
                    <br />
                    <Button variant="outlined" fullWidth size="large" disabled={loading} onClick={() => setStage(1)}>
                        Voltar
                    </Button>
                </>
            )}

            {stage === 3 && (
                <>
                    <Box component="form" noValidate sx={{ width: "100%" }} autoComplete="email password current-password">
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            fullWidth
                            autoComplete="email"
                            value={email}
                            sx={{
                                display: "none",
                            }}
                            disabled
                        />
                        <TextFieldEyes
                            label="Senha"
                            variant="outlined"
                            type="password"
                            fullWidth
                            autoComplete="current-password"
                            value={password}
                            sx={{
                                display: "none",
                            }}
                            disabled
                        />
                        <TextFieldEyes
                            label="Confirme a Senha"
                            variant="outlined"
                            type="password"
                            fullWidth
                            autoComplete="off"
                            disabled={loading}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={!validConfirmPassword}
                            helperText={!validConfirmPassword && "A senha deve ser igual a anteriormente informada."}
                        />
                    </Box>
                    <br />
                    <br />
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        size="large"
                        loading={loading}
                        disabled={!validConfirmPassword}
                        onClick={createAccount}
                        sx={{
                            gap: "10px",
                        }}
                    >
                        Criar conta
                    </LoadingButton>
                    <br />
                    <br />
                    <Button variant="outlined" fullWidth size="large" disabled={loading} onClick={() => setStage(2)}>
                        Voltar
                    </Button>
                </>
            )}
        </LoginScope>
    );
};

export default Signup;
