import { UUID } from "Utils";
import { LayoutOpts, LayoutOptionsNormalized, TypeHandleError, TypeHandleErrorName } from "./Types";

export class HandleError extends Error implements TypeHandleError {
    constructor(readonly message: string, readonly name: TypeHandleErrorName = "DEFAULT", readonly cause?: any) {
        super(message, { cause });
    }
}

export const normalizeLayoutItem = <K = string>(item: LayoutOpts<K>, props: { isBounded: boolean; isDraggable: boolean; isResizable: boolean }): LayoutOptionsNormalized<K> => {
    let { i, c = 0, r, w, h, minW = 0, minH = 0, maxH = Infinity, maxW = Infinity, isStart = false, isEnd = false, uuid = UUID(), rowUuid = null, isRemoved = false, state, config } = item;
    const { isBounded, isDraggable, isResizable } = props;

    item.flexible = typeof item.flexible === "boolean" ? item.flexible : !item.fixed;
    const draggable: boolean = (typeof item.isDraggable === "boolean" ? item.isDraggable : item.flexible && isDraggable) === true;
    const resizable: boolean = (typeof item.isResizable === "boolean" ? item.isResizable : item.flexible && isResizable) === true;
    const bounded: boolean = (draggable && isBounded && item.isBounded !== false) === true;
    const flexible = resizable && draggable;

    minW = Math.max(1, typeof minW === "number" && isFinite(minW) ? minW : 1);
    minH = Math.max(1, typeof minH === "number" && isFinite(minH) ? minH : 1);

    maxH = typeof maxH === "number" ? maxH : Infinity;
    maxW = typeof maxW === "number" ? maxW : Infinity;

    w = typeof w === "number" && isFinite(w) ? Math.min(Math.max(1, w), maxW) : minH;
    h = typeof h === "number" && isFinite(h) ? Math.min(Math.max(1, h), maxH) : minH;

    state = state !== null && typeof state === "object" ? state : {};
    config = config !== null && typeof config === "object" ? config : {};

    return {
        i,
        c,
        r,
        w,
        h,
        minW,
        minH,
        maxH,
        maxW,
        flexible: resizable && draggable,
        fixed: !flexible,
        isBounded: bounded,
        isDraggable: draggable,
        isResizable: resizable,
        isStart,
        isEnd,
        uuid,
        rowUuid,
        isRemoved,
        state,
        config,
    };
};

export const getLayoutItem = <K = string>(id: string, layout: LayoutOpts<K>[], props: { isBounded: boolean; isDraggable: boolean; isResizable: boolean }): LayoutOptionsNormalized<K> | undefined => {
    const find = layout.find((item) => item.i === id);
    if (!find) return;
    return normalizeLayoutItem(find, props);
};

export const updateLayout = <K = string>(
    layout: LayoutOpts<K>[],
    props: {
        cols: number;
        isBounded: boolean;
        isDraggable: boolean;
        isResizable: boolean;
    }
): LayoutOptionsNormalized<K>[] => {
    const { cols } = props;
    const rows: LayoutOptionsNormalized<K>[][] = [];

    let processRows: LayoutOptionsNormalized<K>[][] = layout
        .reduce((acc, item) => {
            const index = item.r,
                c = normalizeLayoutItem(item, props);
            if (!Array.isArray(acc[index])) {
                acc[index] = [c];
            } else {
                (acc as any)[index].push(c);
            }
            return acc;
        }, [] as Array<LayoutOptionsNormalized<K>[] | undefined>)
        .filter((row): row is Array<any> => Array.isArray(row) && row.length > 0);

    while (processRows.length > 0) {
        const [current] = processRows.splice(0, 1);
        let l: number = 0;

        const [row, ...r] = current.reduce(
            (acc, item) => {
                const coluns = acc[l].reduce((acc, item) => acc + item.w, 0);
                if (coluns + item.w > cols) {
                    l++;
                    acc[l] = [];
                }
                acc[l].push(item);
                return acc;
            },
            [[]] as LayoutOptionsNormalized<K>[][]
        );

        processRows = r.concat(processRows);

        let coluns = row.reduce((acc, item) => acc + item.w, 0),
            currentIndex = 0;

        while (coluns < cols) {
            if (row[currentIndex].w >= row[currentIndex].maxW || row[currentIndex].fixed) {
                currentIndex = (currentIndex + 1) % row.length;
                continue;
            }

            row[currentIndex].w += 1;
            coluns += 1;
            currentIndex = (currentIndex + 1) % row.length;
        }

        let column = 0;
        const rowUuid = row.find((a) => typeof a.rowUuid === "string")?.rowUuid ?? UUID();

        rows.push(
            row.map((item, i, self) => {
                item.c = column;
                item.r = rows.length;
                column += item.w;
                item.isStart = i === 0;
                item.isEnd = i === self.length - 1;
                item.rowUuid = rowUuid;
                return item;
            })
        );
    }

    return rows.reduce((acc, row) => {
        return acc.concat(row);
    }, [] as LayoutOptionsNormalized<K>[]);
};

export const getLineBy = <K = string>(layout: LayoutOptionsNormalized<K>[], row: number): LayoutOptionsNormalized<K>[] => {
    return layout.filter((item) => item.r === row && !item.isRemoved);
};

export const getMinWidth = <K = string>(layout: LayoutOptionsNormalized<K>[], row: number): number => {
    return getLineBy(layout, row).reduce((acc, item) => Math.max(acc, item.minW), 0);
};

export const getMinHeight = <K = string>(layout: LayoutOptionsNormalized<K>[], row: number): number => {
    return getLineBy(layout, row).reduce((acc, item) => Math.max(acc, item.minH), 0);
};

export const getMaxWidth = <K = string>(layout: LayoutOptionsNormalized<K>[], row: number): number => {
    return getLineBy(layout, row).reduce((acc, item) => Math.min(acc, item.maxW), Infinity);
};

export const getMaxHeight = <K = string>(layout: LayoutOptionsNormalized<K>[], row: number): number => {
    return getLineBy(layout, row).reduce((acc, item) => Math.min(acc, item.maxH), Infinity);
};

export const getHeigthByLine = <K = string>(layout: LayoutOptionsNormalized<K>[], row: number): number => {
    const max = getMaxHeight(layout, row);
    const heigth = Math.max(
        getMinHeight(layout, row),
        getLineBy(layout, row).reduce((acc, item) => Math.max(acc, item.h), 0)
    );

    return isFinite(max) ? Math.max(getMinHeight(layout, row), heigth) : heigth;
};

export const adjustColsBy = <K = string>(
    layout: LayoutOptionsNormalized<K>[],
    row: number,
    indexItem: number,
    size: number,
    toLeft: boolean = true,
    lastDif: number = 0,
    inverted: boolean = false
) => {
    let line = getLineBy(layout, row);

    // Obtem as colunas a serem ajustadas
    const columns = toLeft ? line.slice(0, indexItem + 1) : line.slice(indexItem + 1);

    // Obtem o total de largura das colunas
    const totalWidth = columns.reduce((acc, item) => acc + item.w, 0) - lastDif;
    // Obtem a largura minima e maxima das colunas
    const minWidth = columns.reduce((acc, item) => acc + item.minW, 0);
    const maxWidth = columns.reduce((acc, item) => acc + item.maxW, 0);

    const isMaxed = indexItem === line.length - 2 && toLeft && line[indexItem + 1].w >= line[indexItem + 1].maxW;

    // Verifica se a largura total das colunas é menor que a largura minima ou maior que a largura maxima
    if (totalWidth < minWidth || totalWidth > maxWidth || isMaxed) {
        return { dif: 0, columns, layout };
    }

    let dif = size - totalWidth;
    const addition = inverted ? -1 : 1;

    for (let i = inverted ? columns.length - 1 : 0; (inverted && i >= 0) || (!inverted && i < columns.length); i += addition) {
        const width = columns[i].w + dif;
        // Verifica se a largura da coluna é menor que a largura minima ou maior que a largura maxima
        columns[i].w = Math.min(Math.max(width, columns[i].minW), columns[i].maxW);
        dif = i === columns.length - 1 && width > columns[i].maxW ? dif : width - columns[i].w;
    }

    columns.forEach((item, i, self) => {
        const index = layout.findIndex((a) => a.uuid === item.uuid);
        if (index >= 0) {
            layout[index].c = i == 0 ? layout[index].c : self[i - 1].c + self[i - 1].w;
            layout[index].w = item.w;
        }
    });

    return { dif, columns, layout };
};

export const resolveRow = <K = string>(layout: LayoutOptionsNormalized<K>[], props: { byItem: LayoutOptionsNormalized<K>; cols?: number; removeItens?: boolean }) => {
    const { byItem, cols = 12, removeItens = true } = props;
    let line = layout.filter((item) => item.r === byItem.r);

    const currentIndex = layout.findIndex((item) => item.uuid === byItem.uuid);
    const indexItem = line.findIndex((item) => item.uuid === byItem.uuid);

    if (currentIndex < 0 || indexItem < 0) {
        return layout;
    }

    const currentItem = layout[currentIndex];

    let columnsLeft = line.slice(0, indexItem + 1),
        columnsRight = line.slice(indexItem + 1);

    const newPos = byItem.c + byItem.w;

    let dif = 0;

    // if (removeItens && indexItem === 0) {
    //     let currentWidth = Math.max(0, Math.min(byItem.w, currentItem.maxW));

    //     const columnsWidth = Math.min(cols - (currentItem.c + currentWidth), cols);

    //     const { dif: d, layout: l, columns } = adjustColsBy(layout, currentItem.r, indexItem, columnsWidth, false);

    //     const idealWidth = cols - columns.reduce((acc, item) => acc + item.w, 0);

    //     currentItem.c = Math.min(idealWidth, currentItem.minW) - currentItem.minW;
    //     currentItem.w = Math.max(idealWidth, currentItem.minW);

    //     dif = d;
    //     layout = l;

    //     columnsLeft = [currentItem];
    //     columnsRight = columns;
    // } else if (removeItens && indexItem === line.length - 2) {
    //     const [toItem] = columnsRight;
    //     const currentItem = layout.find((a) => a.uuid === toItem.uuid) as LayoutOptionsNormalized<K>;
    //     currentItem.c = byItem.w + byItem.c;

    //     const { dif: d, layout: l, columns } = adjustColsBy(layout, toItem.r, indexItem, currentItem.c, true, 0, true);

    //     let idealWidth = cols - columns.reduce((acc, item) => acc + item.w, 0);

    //     currentItem.w = Math.min(Math.max(idealWidth, currentItem.minW), currentItem.maxW);
    //     currentItem.c = cols - currentItem.w;

    //     dif = d;
    //     layout = l;

    //     columnsLeft = columns;
    //     columnsRight = [currentItem];
    // } else {
    for (let i = 0; i < 3; i++) {
        const width = i % 2 === 0 ? newPos : cols - newPos;

        const { dif: d, layout: l, columns } = adjustColsBy(layout, byItem.r, indexItem, width, i % 2 === 0, dif, i === 0);

        dif = d;
        layout = l;

        columnsLeft = i % 2 === 0 ? columns : columnsLeft;
        columnsRight = i % 2 === 0 ? columnsRight : columns;
    }
    // }

    columnsLeft.concat(columnsRight).forEach((item, i, self) => {
        const index = layout.findIndex((a) => a.uuid === item.uuid);
        if (index >= 0) {
            layout[index].c = i == 0 ? layout[index].c : self[i - 1].c + self[i - 1].w;
            layout[index].w = item.w;
        }
    });

    return layout;
};
