import React, { useCallback, useEffect } from "react";
import { forwardWidgets, OrderInput, WidgetConfig } from "Components";
import { DevelopmentTrace, Toast } from "shared-components";

import styles from "./style.module.scss";
import { Button, Slider } from "@mui/material";
import { formatCurrency } from "Utils";
import { MainAPI } from "Helpers";
import { WidgetsProps } from "Types";

interface Balance {
    quantity: number;
    price: number;
    value: number;
    pending: number;
}

const SpotWallet: React.FC<Partial<WidgetsProps>> = ({ symbol }) => {
    const [loading, setLoading] = React.useState(true);
    const [balance, setBalance] = React.useState({
        base: 0,
        quote: 0,
    });

    const [buyPrice, setBuyPrice] = React.useState(0);
    const [buyAmount, setBuyAmount] = React.useState(0);
    const [buyPercent, setBuyPercent] = React.useState(0);
    const [buyTotal, setBuyTotal] = React.useState(0);

    const [sellPrice, setSellPrice] = React.useState(0);
    const [sellAmount, setSellAmount] = React.useState(0);
    const [sellPercent, setSellPercent] = React.useState(0);
    const [sellTotal, setSellTotal] = React.useState(0);

    const zerar = useCallback(() => {
        setBuyPrice(0);
        setBuyAmount(0);
        setBuyPercent(0);
        setBuyTotal(0);

        setSellPrice(0);
        setSellAmount(0);
        setSellPercent(0);
        setSellTotal(0);
    }, []);

    const updateBalance = useCallback(() => {
        setLoading(true);
        zerar();

        if (!symbol?.quote || !symbol?.base) {
            return;
        }

        Promise.all([MainAPI.get<Balance>("/wallet/consult_balance", { token: symbol.quote }), MainAPI.get<Balance>("/wallet/consult_balance", { token: symbol.base })])
            .then(([quote, base]) => {
                setBalance({
                    quote: quote.quantity + (quote.pending < 0 ? quote.pending : 0),
                    base: base.quantity + (base.pending < 0 ? base.pending : 0),
                });
                setLoading(false);
            })
            .catch((error) => {
                Toast.error(error);
            });
    }, [symbol]);

    useEffect(() => {
        setLoading(true);
        zerar();

        const time = setTimeout(() => {
            updateBalance();
        }, 1000);

        return () => {
            clearTimeout(time);
        };
    }, [symbol]);

    const handleButton = (type: "BUY" | "SELL") => () => {
        setLoading(true);
        if (!symbol?.pair) {
            return;
        }

        MainAPI.post<Balance>("/inventory/order", { type, symbol: symbol?.pair, price: type === "BUY" ? buyPrice : sellPrice, amount: type === "BUY" ? buyAmount : sellAmount })
            .then(() => {
                Toast.success("Ordem enviada com sucesso!");
                updateBalance();
            })
            .catch((error) => {
                Toast.error(error);
                updateBalance();
            });
    };

    const changeValue = (type: "buy" | "sell", input: "price" | "amount" | "percent" | "total") => (value: number) => {
        const normalizeValue = (value: number) => (isFinite(value) ? parseFloat(value.toFixed(8)) : 0);

        if (type === "buy") {
            let price = buyPrice,
                amount = buyAmount,
                percent = buyPercent,
                total = buyTotal;

            switch (input) {
                case "price":
                    price = value;
                    total = value > 0 ? amount * value : 0;
                    break;
                case "amount":
                    amount = value;
                    total = price > 0 ? value * price : 0;
                    break;
                case "percent":
                    percent = value;
                    amount = (balance.quote / price) * value;
                    total = price > 0 ? amount * price : 0;
                    break;
                case "total":
                    total = value;
                    amount = price > 0 ? value / price : 0;
                    percent = Math.min(1, price > 0 ? (amount * price) / balance.quote : 0);
            }

            setBuyPrice(normalizeValue(price));
            setBuyAmount(normalizeValue(amount));
            setBuyPercent(normalizeValue(percent));
            setBuyTotal(normalizeValue(total));
        } else {
            let price = sellPrice,
                amount = sellAmount,
                percent = sellPercent,
                total = sellTotal;

            switch (input) {
                case "price":
                    price = value;
                    total = value > 0 ? amount * value : 0;
                    break;
                case "amount":
                    amount = value;
                    total = price > 0 ? value * price : 0;
                    break;
                case "percent":
                    percent = value;
                    amount = (balance.base / price) * value;
                    total = price > 0 ? amount * price : 0;
                    break;
                case "total":
                    total = value;
                    amount = price > 0 ? value / price : 0;
                    percent = Math.min(1, price > 0 ? (amount * price) / balance.base : 0);
            }

            setSellPrice(normalizeValue(price));
            setSellAmount(normalizeValue(amount));
            setSellPercent(normalizeValue(percent));
            setSellTotal(normalizeValue(total));
        }
    };

    const valuetext = (value: number) => {
        return `${Math.floor(value * 100)}%`;
    };

    const marks = [
        {
            value: 0,
        },
        {
            value: 0.25,
        },
        {
            value: 0.5,
        },
        {
            value: 0.75,
        },
        {
            value: 1,
        },
    ];

    return (
        <div className={styles["spot-wallet"]}>
            <div>
                <OrderInput label="Preço" symbol={symbol?.quote} value={buyPrice} onChange={changeValue("buy", "price")} disabled={loading} />
                <OrderInput label="Quantia" symbol={symbol?.base} value={buyAmount} onChange={changeValue("buy", "amount")} disabled={loading} />
                <Slider
                    value={buyPercent}
                    onChange={(event, newValue) => {
                        if (typeof newValue === "number") {
                            changeValue("buy", "percent")(newValue);
                        }
                    }}
                    valueLabelFormat={valuetext}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    step={0.01}
                    marks={marks}
                    min={0}
                    max={1}
                    disabled={loading || balance.quote <= 0}
                    sx={{
                        margin: "0 15px",
                        width: "calc(100% - 30px)",
                    }}
                />
                <OrderInput label="Total" symbol={symbol?.quote} value={buyTotal} onChange={changeValue("buy", "total")} disabled={loading} />
                <div className={styles["info"]}>
                    <div>
                        <span>Disponível</span>
                        <p>{formatCurrency(balance.quote, { symbol: symbol?.quote, position: "rt", maximumFractionDigits: 6 })}</p>
                    </div>
                    <div>
                        <span>Compra máx.</span>
                        <p>{formatCurrency(balance.quote / buyPrice, { symbol: symbol?.base, position: "rt", maximumFractionDigits: 6 })}</p>
                    </div>
                    <div>
                        <span>Taxa est.</span>
                        <p>{formatCurrency(0, { symbol: symbol?.base, position: "rt", maximumFractionDigits: 6 })}</p>
                    </div>
                </div>
                <Button variant="contained" color="success" disabled={loading || balance.quote <= 0} onClick={handleButton("BUY")}>
                    Comprar
                </Button>
            </div>
            <div>
                <OrderInput label="Preço" symbol={symbol?.quote} value={sellPrice} onChange={changeValue("sell", "price")} disabled={loading} />
                <OrderInput label="Quantia" symbol={symbol?.base} value={sellAmount} onChange={changeValue("sell", "amount")} disabled={loading} />
                <Slider
                    value={sellPercent}
                    onChange={(event, newValue) => {
                        if (typeof newValue === "number") {
                            changeValue("sell", "percent")(newValue);
                        }
                    }}
                    valueLabelFormat={valuetext}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    step={0.01}
                    marks={marks}
                    min={0}
                    max={1}
                    disabled={loading || balance.base <= 0}
                    sx={{
                        margin: "0 15px",
                        width: "calc(100% - 30px)",
                    }}
                />
                <OrderInput label="Total" symbol={symbol?.base} value={sellTotal} onChange={changeValue("sell", "total")} disabled={loading} />
                <div className={styles["info"]}>
                    <div>
                        <span>Disponível</span>
                        <p>{formatCurrency(balance.base, { symbol: symbol?.base, position: "rt", maximumFractionDigits: 6 })}</p>
                    </div>
                    <div>
                        <span>Venda máx.</span>
                        <p>{formatCurrency(balance.base / sellPrice, { symbol: symbol?.quote, position: "rt", maximumFractionDigits: 6 })}</p>
                    </div>
                    <div>
                        <span>Taxa est.</span>
                        <p>{formatCurrency(0, { symbol: symbol?.quote, position: "rt", maximumFractionDigits: 6 })}</p>
                    </div>
                </div>
                <Button variant="contained" color="error" disabled={loading || balance.base <= 0} onClick={handleButton("SELL")}>
                    Vender
                </Button>
            </div>
        </div>
    );
};

export const OrderOperation = forwardWidgets<WidgetsProps>(
    "OrderOperation",
    (props) => {
        const [currentTab, setCurrentTab] = WidgetConfig.useTabs(
            [
                { title: "Carteira Spot", id: "spot_wallet" },
                { title: "Cruzada", id: "cross" },
                { title: "Isolada", id: "isolated" },
                { title: "Grid", id: "grid" },
            ],
            "spot_wallet"
        );

        if (["cross", "isolated", "grid"].includes(currentTab)) {
            return <DevelopmentTrace message="Ferramenta em desenvolvimento!" />;
        }

        return <SpotWallet {...props} />;
    },
    { minW: 3, maxW: 5 }
);
