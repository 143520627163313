import { Routes } from "shared-components";
import { mdiAccountPlus, mdiChartWaterfall, mdiHome, mdiLoginVariant, mdiViewDashboard } from "@mdi/js";
import Icon from "@mdi/react";

import Page404 from "./Page404";
import Login from "./Login";
import Signup from "./Signup";
import Dashboard from "./Dashboard";
import Trade from "./Trade";

const routes = new Routes.Routes();

routes.route("/", {
    Component: Dashboard,
    title: "Dashboard",
    inMenu: true,
    icon: <Icon path={mdiViewDashboard} />,
    errorElement: <Page404 />,
    requiresAuthentication: true,
});

routes.route("/trade", {
    Component: Trade,
    title: "Trade",
    inMenu: true,
    icon: <Icon path={mdiChartWaterfall} />,
    requiresAuthentication: true,
});

routes.route("/login", {
    Component: Login,
    title: "Login",
    inMenu: false,
    icon: <Icon path={mdiLoginVariant} />,
});

routes.route("/register", {
    Component: Signup,
    title: "Cadastro",
    inMenu: false,
    icon: <Icon path={mdiAccountPlus} />,
});

routes.route("*", {
    Component: Page404,
    title: "404",
    inMenu: false,
    icon: <Icon path={mdiHome} />,
});

export default routes;
